/**
 * @function formatNumber
 *
 * @description returns a formatted number with points
 *
 * @param {number} num - number to format
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const formatNumber = (num: number | string) => {
  const removeChars = num.toString().replace(/[^0-9]*/g, '');
  return removeChars.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
 * @function formatFloat
 *
 * @description returns a formatted number with points
 *
 * @param {number} num - number to format
x *
 */
export const formatFloat = (num: number | string) => {
  const removeChars = num.toString().replace(/[^0-9,]*/g, '');
  return removeChars.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
