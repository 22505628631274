import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  title: {
    paddingRight: '0.7rem',
    paddingTop: '1.4rem',
    paddingBottom: '1.4rem',
    minHeight: '54px',
  },
  titleIconTypographyContainer: {
    width: '83%',
    marginLeft: '0.25rem',
    paddingRight: '0.7rem',
    paddingLeft: '0.7rem',
  },
  titleTypography: {
    color: '#000000',
    padding: 0,
    maxWidth: '78%',
    marginLeft: '0.5rem',
  },
  titleClearIcon: {
    cursor: 'pointer',
  },
});
