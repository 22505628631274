import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  root: {
    '&.MuiButton-outlined': {
      marginRight: '0.25rem',
      border: `1px solid ${('#ff0100')}`,
      color: '#ff0100',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        backgroundColor: '#ff0100',
        color: 'white',
      },
    },
    '&.MuiButton-contained': {
      backgroundColor: '#ff0100',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        backgroundColor: '#ff0100',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: '#ff0100',
        },
      },
    },
  },
  rootGrey: {
    '&.MuiButton-outlined': {
      marginRight: '0.25rem',
      border: `1px solid ${(' rgba(0, 0, 0, .6)')}`,
      color: '#555',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        border: `1px solid ${('#E4E4E4')}`,
        backgroundColor: '#E4E4E4',
        color: '#555',
      },
    },
    '&.MuiButton-contained': {
      backgroundColor: '#ff0100',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        backgroundColor: '#ff0100',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: '#ff0100',
        },
      },
    },
  },
  rootGreyTags: {
    '&.MuiButton-outlined': {
      maxHeight: '1.5rem',
      padding: 0,
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem',
      marginRight: '0.25rem',
      marginBottom: '0.7rem',
      border: `1px solid ${(' rgba(0, 0, 0, .6)')}`,
      color: '#555',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        border: `1px solid ${('#E4E4E4')}`,
        backgroundColor: '#E4E4E4',
        color: '#555',
      },
    },
  },
  secondary: {
    '&.MuiButton-outlined': {
      marginRight: '0.25rem',
      border: `0px solid ${('#555')}`,
      color: '#555',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        backgroundColor: '#ddd',
        border: `0 solid ${('#fff')}`,
      },
    },
  },
  terciary: {
    '&.MuiButton-outlined': {
      border: `1px solid ${('rgba(0, 0, 0, 0.26)')}`,
      color: 'rgba(0, 0, 0, 0.26)',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        backgroundColor: '#ff0100',
        color: 'white',
      },
    },
    '&.MuiButton-contained': {
      backgroundColor: 'white',
      border: `1px solid ${('#ff0100')}`,
      color: '#ff0100',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        backgroundColor: '#ff0100',
        color: 'white',
      },
    },
  },
});
