import { useEffect, useState } from 'react';
import {
  Avatar,
} from '@mui/material';

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PersonIcon from '@mui/icons-material/Person';

import { LIST_TYPES } from '../../../../../../../../config/index';
import photoAPI from '../../../../../../../../services/photoAPI';
import { ObjectType } from '../../../../../../../../types/objectType';
import { cardAvatarStyle } from './cardAvatarStyle';

interface CardAvatarProps {
  item: ObjectType;
  listType: string;
  sx: ObjectType;
  handlePhoto?: (id: string, fotoSrc: string) => void;
}

export const CardAvatar = (props: CardAvatarProps) => {
  const {
    item, listType, sx, handlePhoto,
  } = props;
  const [photo, setPhoto] = useState(item.photo);
  const { sx: classSX } = cardAvatarStyle;

  useEffect(() => {
    if (!photo && !item.photo) {
      if (listType === LIST_TYPES.workers && item.tieneFoto) {
        photoAPI.photoServiceWorkers(item.id, listType).then((base64String: string) => {
          if (handlePhoto) handlePhoto(item.id, base64String);
          setPhoto(base64String);
        });
      }

      if (listType === LIST_TYPES.civilWorks && item.foto) {
        photoAPI.photoServiceCivilWorks(encodeURIComponent(item.foto), listType)
          .then((base64String: string) => {
            if (handlePhoto) handlePhoto(item.id, base64String);
            setPhoto(base64String);
          });
      }
    }
  }, []);

  return (
    <Avatar
      sx={sx}
      src={photo}
    >
      {
        listType === LIST_TYPES.civilWorks
          ? < CorporateFareIcon sx={classSX.corporateFairIcon} />
          : < PersonIcon sx={classSX.personIcon} />
      }
    </ Avatar>);
};
