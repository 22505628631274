/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useEffect,
  useState,
} from 'react';

import {
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Checkbox,
  Select,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { FormControlWrapper } from '../../Wrappers/FormControlWrapper/FormControlWrapper';

import { ObjectType } from '../../../../../../types/objectType';

export const CustomSelectMultiple = (
  {
    filterType,
    selectOptions,
    selectedOptions,
    setSelectedOptions,
    formHelperTextEnabled,
    customStyle,
    getInfoToDispatch,
    debounceSelectedInfo,
    defaultValue,
    disabled,
  }: ObjectType,
) => {
  const getOptionsSelected = () => selectOptions
    .filter((option: ObjectType) => option.selected === true)
    .map((options: ObjectType) => options.name);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const selectOptionsToSet = getOptionsSelected();
    setSelectedOptions(['', ...selectOptionsToSet]);
  }, [selectOptions]);

  return (<FormControlWrapper
    formHelperTextEnabled={formHelperTextEnabled && true}
    customStyle={customStyle}
  >
    <Select
      labelId={filterType}
      id={filterType}
      multiple
      value={selectedOptions}
      disabled={disabled}
      onChange={(e: any) => {
        const { value } = e.target;
        const infoToDispatch = getInfoToDispatch(value, selectOptions);
        debounceSelectedInfo(infoToDispatch);
      }}
      IconComponent={ExpandMore}
      renderValue={() => {
        if (defaultValue) {
          return <Typography
            style={
              {
                padding: 0,
                color: 'rgba(0, 0, 0, 0.4)',
              }
            }
          >
            {defaultValue}
          </Typography>;
        }
        return null;
      }
      }
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      MenuProps={
        {
          style: {
            maxHeight: 400,
            maxWidth: 200,
          },
          variant: 'menu',
        }
      }
    >
      {
        selectOptions
          .map(
            (option: ObjectType) => <MenuItem
              key={option.name}
              value={option.name}
              dense={true}
              style={{ padding: '0' }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={option.selected}
                  sx={{ '&.Mui-checked': { color: 'red' } }}
                />
              </ListItemIcon>
              {option.color
                && <ListItemIcon
                  style={{ minWidth: '30px' }}
                >
                  <CircleIcon
                    style={
                      {
                        color: option.color,
                        marginRight: '10px',
                      }
                    }
                  />
                </ListItemIcon>}
              <ListItemText primary={<Tooltip
                enterDelay={1000}
                title={
                  <Typography
                    style={{ padding: '0.625rem' }}
                  >
                    {option.name}
                  </Typography>}
                followCursor>
                <Typography
                  style={{ paddingTop: '0.1rem' }}
                  noWrap
                >
                  {option.name}
                </Typography>
              </Tooltip>} />
            </MenuItem>,
          )
      }
    </ Select>
  </FormControlWrapper >);
};
