import { useContext } from 'react';
import {
  AppBar,
  Typography,
  Grid,
  Toolbar,
  IconButton,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as AccionaIcon } from '../../../assets/accionaIcon.svg';
import navbarStyle from './navbarStyle';
import { namespaces } from '../../../i18n/i18n.constants';
import { Version } from '../../Version/Version';
import { AuthContext } from '../../../services/authContextProvider';

export const Navbar = () => {
  const dispatch = useDispatch();
  const classes = navbarStyle();
  const { t } = useTranslation(namespaces.pages.navbar);
  const authContextProvider = useContext(AuthContext);

  return (
    <AppBar
      position="static"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar
        className={classes.toolbar}
        style={{ minHeight: '3rem' }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <IconButton disabled>
              <AccionaIcon className={classes.accionaIcon} />
              <Version />
            </IconButton>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Typography variant="body2" color="inherit" fontWeight="bold">
                <Button
                  variant="contained"
                  size='large'
                  disableElevation
                  onClick={() => dispatch(authContextProvider.logout())}
                  className={classes.root}
                >
                  {t('logout')}
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
