import { ObjectType } from '../../types/objectType';

// Constants
const initialData = {
  open: false,
  messageType: null,
  message: null
};

// Redux Types
const SETERRORALERT = 'SETERRORALERT';
const DELETEALERT = 'DELETEALERT';

// Redux Reducer
export default function errorMessageReducer(state = initialData, action: ObjectType) {
  switch (action.type) {
    case SETERRORALERT:
      return { ...state, ...action.payload };
    case DELETEALERT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// Redux Actions
/**
 * @function setMessageError 
 *
 * @description Set Message Error
 *
 */
export const setMessageError = (message: string) => async (dispatch: any, getState: any) => {
  return dispatch({
    type: SETERRORALERT,
    payload: {
      open: true,
      messageType: 'error',
      message: message,
    },
  });

};


/**
 * @function deleteAlert
 *
 * @description Delete Error Message
 *
 */
export const deleteAlert = () => async (dispatch: any, getState: any) => {
  return dispatch({
    type: DELETEALERT,
    payload: {
      open: false,
      messageType: null,
      message: null
    },
  });

};

