import { ObjectType } from '../types/objectType';
import FILTER_TYPES from './filtersConfig/filterTypes';

export const eventCategoryFilterNames: ObjectType = {
  workersDiploma: 'Titulación',
  workersDegreeLevel: 'Nivel de titulación',
  workersSupervisory: 'Supervisory',
  workersCertificate: 'Certificados',
  workersCostCenter: 'Centro de coste',
  workersCurrentPosition: 'Posición Actual',
  workersPositions: 'Posición Historica',
  workersExperienceDescription: 'Descripción de experiencia',
  workersDegreeYear: 'Año de titulación',
  workersFullName: 'Empleado',
  workersZone: 'Zona',
  workersSpecialities: 'Especialidades',
  workersComplementaryTraining: 'Formación complementaria',
  civilWorksBeginningDate: 'Fecha de inicio',
  civilWorksEndDate: 'Fecha de fin',
  civilWorksByName: 'Obra',
  civilWorksClient: 'Cliente',
  civilWorksType: 'Tipo',
  civilWorksSubtype: 'Subtipo',
  civilWorksMinimunAmount: 'Importe mínimo',
  workersExperience: 'Años de experiencia',
  civilWorksClassification: 'Clasificación',
  civilWorksCertificate: 'Certificado de Obra',
  civilWorksZone: 'Zona',
  workersActive: 'Relación con Acciona',
  civilWorksContructiveProcesses: 'Procesos Constructivos',
};

export const event = {
  ADD: 'add',
  DELETE: 'delete',
};

export const eventCategory: ObjectType = {
  cv: 'CV',
  project: 'Project',
};

export const type = (filterType: string) => {
  if (FILTER_TYPES[filterType].includes('workers')) return 'Personal';
  if (FILTER_TYPES[filterType].includes('civilWorks')) return 'Project';
  return undefined;
};
