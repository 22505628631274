import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    '& .MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        paddingRight: 0,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: 16,
    },
    // '& label.Mui-focused': {
    //   color: '#000000',
    // },
    // '& .MuiOutlinedInput-root': {
    //   '&.Mui-focused fieldset': {
    //     borderColor: '#000000',
    //     border: '2px solid black',
    //   },
    // },
  },
  textField: {
    // padding: '0.7rem',
  },
  iconOnFocus: {
    color: '#000000',
    marginRight: 0,
    paddingRight: 0,
  },
  iconOnBlur: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: 0,
    paddingRight: 0,
  },
  iconWrapper: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: '10px',
  },
});
