import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  Typography,
} from '@material-ui/core';

import { namespaces } from '../../../../../../../../i18n/i18n.constants';

import { ObjectType } from '../../../../../../../../types/objectType';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const AutocompleteList = ({ props, option, selected }: ObjectType) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const noResult = `${t('input.noResults')}`;
  return (
    <li
      key={uuidv4()}
      {...props}
      style={
        {
          paddingRight: '1rem',
        }
      }
    >
      {
        (option.name !== noResult)
          ? <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          : <Box style={{ width: '0.75rem' }}></Box>
      }
      <Tooltip
        enterDelay={1000}
        title={
          <Typography
            style={{ padding: '0.625rem' }}
          >
            {option.name}
          </Typography>
        }
        followCursor>
        <Typography
          style={{ paddingTop: '0.1rem' }}
          noWrap
        >
          {option.name}
        </Typography>
      </Tooltip>
    </li>);
};
