import { makeStyles } from '@material-ui/core';

export default makeStyles({
  container: {
    minWidth: '15em',
  },
  title: {
    color: '#666666',
    fontSize: '14px',
    fontfamily: 'Roboto',
    marginBottom: '5px',
  },
});
