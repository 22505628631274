import { DatePickerRange } from '../../Components/DatePickerRange/DatePickerRange';
import { AutocompleteMultipleChoice } from '../../Components/AutocompleteMultichoice/AutocompleteMultichoice';
import { NumberTextField } from '../../Components/NumberTextField/NumberTextField';

import { FilterListWrapper } from '../../Wrappers/FilterListWrapper/FilterListWrapper';

import FILTER_TYPES from '../../../../../../config/filtersConfig/filterTypes';
import { ClassificationGroups } from '../../Components/ClassificationGroups/ClassificationGroups';
import { SelectFilter } from '../../Components/SelectFilter/SelectFilter';

export const CivilWorksFiltersList = () => (
  <FilterListWrapper
    filterListTitle={FILTER_TYPES.civilWorksFilters}
  >
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.civilWorksByName} />
    <NumberTextField filterType={FILTER_TYPES.civilWorksMinimunAmount} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.civilWorksClient} />
    <DatePickerRange filterType={FILTER_TYPES.civilWorksBeginningDate} />
    <DatePickerRange filterType={FILTER_TYPES.civilWorksEndDate} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.civilWorksType} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.civilWorksSubtype} />
    <ClassificationGroups filterType={FILTER_TYPES.civilWorksClassification} />
    <SelectFilter filterType={FILTER_TYPES.civilWorksCertificate} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.civilWorksZone} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.civilWorksContructiveProcesses} />
  </FilterListWrapper>
);
