import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    '& .MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        paddingRight: 0,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: 16,
    },
    // '& label.Mui-focused': {
    //   color: '#000000',
    // },
    // '& .MuiOutlinedInput-root': {
    //   '&.Mui-focused fieldset': {
    //     borderColor: '#000000',
    //     border: '2px solid black',
    //   },
    // },
  },
  textField: {
    padding: '0.7rem',
  },
  icon: {
    color: '#000000',
  },
});
