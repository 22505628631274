import { useState } from 'react';
import { ModalHeader } from '../../ModalComponent/ModalHeader/ModalHeader';
import { HeaderInfo } from '../../ModalComponent/ModalHeader/HeaderInfo/HeaderInfo';

import { ObjectType } from '../../../../../../../../types/objectType';

import { CVInfo } from './CVInfo/CVInfo';
import { StatusChange } from './StatusChange/StatusChange';
import { WorkerDetailedInfoTabs } from './WorkerDetailedInfoTabs/WorkerDetailedInfoTabs';
import { LIST_TYPES } from '../../../../../../../../config';

export const WorkerDetailedInfo = (
  {
    modalDetailedInfo,
    handleClose,
    handleChangeStatus,
  }: ObjectType,
) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (opt: number) => {
    setSelectedTab(opt);
  };

  return (
    <>
      <ModalHeader photo={true}>
        <HeaderInfo
          modalDetailedInfo={modalDetailedInfo}
          handleClose={handleClose}
          listType={LIST_TYPES.workers}
        />
      </ModalHeader>

      <WorkerDetailedInfoTabs handleChange={handleChange} selectedTab={selectedTab}/>

      {selectedTab === 0 && <CVInfo modalDetailedInfo={modalDetailedInfo} />}
      {selectedTab === 1 && <StatusChange
        userId={modalDetailedInfo.id}
        changeHistory={modalDetailedInfo.historialCambios}
        handleChangeStatus={handleChangeStatus}
      />
      }
    </>);
};
