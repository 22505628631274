/* eslint-disable indent */
import {
  Fragment,
} from 'react';

import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import { DetailedInfoContainer } from '../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyInfo } from '../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';
import { ModalBodyTitle } from '../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { DetailedInfoGroup } from './DetailedInfoGroup/DetailedInfoGroup';
import { NoInfoBox } from '../../../ModalComponent/ModalBody/NoInfoBox/NoInfoBox';

import {
  DETAILED_INFO_MODAL_VARIABLES,
  AMOUNT_TYPES,
} from '../../../../../../../../../config';

import { namespaces } from '../../../../../../../../../i18n/i18n.constants';
import { ObjectType } from '../../../../../../../../../types/objectType';

export const CivilWorksGroups = (
  {
    groupType,
    modalDetailedInfo,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const componentInfo = (groupType === DETAILED_INFO_MODAL_VARIABLES.classification.title)
    ? DETAILED_INFO_MODAL_VARIABLES.classification
    : DETAILED_INFO_MODAL_VARIABLES.types;
  const {
    title,
    bbddKey,
  } = componentInfo;
  const {
    main,
    groupTitle1,
    groupTitle2,
    subGroupTitle1,
    subGroupTitle2,
    amountWithTaxes,
    amountWithoutTaxes,
  } = bbddKey;
  const TRADUCTION_BASE_PATH = `detailedInfoModal.civilWorksDetailedInfo.${title}.`;

  const getAmount = (group: ObjectType) => {
    if (amountWithTaxes && amountWithoutTaxes) {
      const amountTax = (amountWithTaxes && group[amountWithTaxes])
        ? group[amountWithTaxes]
        : null;
      const amountNoTax = (amountWithTaxes && group[amountWithTaxes])
        ? group[amountWithTaxes]
        : null;
      if (amountTax) {
        return { amount: amountTax, amountType: AMOUNT_TYPES.tax };
      }
      if (amountNoTax) {
        return { amount: amountNoTax, amountType: AMOUNT_TYPES.noTax };
      }
      return null;
    }
    return null;
  };

  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t(`${TRADUCTION_BASE_PATH}title`)} />
      <ModalBodyInfo >
        {
          modalDetailedInfo[main]
            && modalDetailedInfo[main].length > 0
            ? modalDetailedInfo[main].map((item: ObjectType, index: number) => {
              const { mainGroup, subGroup } = item;
              const mainGroupAmountToShow = getAmount(mainGroup);
              return (
                <Fragment
                  key={uuidv4()}
                >
                  < DetailedInfoGroup
                    mainGroupStyle={true}
                    firstElement={index === 0 && true}
                    code={
                      mainGroup[groupTitle1]
                        ? mainGroup[groupTitle1]
                        : subGroup[0][groupTitle1]
                    }
                    description={
                      mainGroup[groupTitle2]
                        ? mainGroup[groupTitle2]
                        : subGroup[0][groupTitle2]}
                    amount={mainGroupAmountToShow?.amount || null}
                    amountType={mainGroupAmountToShow?.amountType || null}
                  />
                  {
                    subGroup.map((subGroupItem: ObjectType) => {
                      const subgroupAmountToShow = getAmount(subGroupItem);
                      return (
                        <Fragment
                          key={uuidv4()}
                        >
                          <DetailedInfoGroup
                            code={subGroupItem[subGroupTitle1]}
                            description={subGroupItem[subGroupTitle2]}
                            amount={subgroupAmountToShow?.amount || null}
                          />
                        </Fragment>
                      );
                    })
                  }
                </Fragment>
              );
            })
            : <NoInfoBox
              value={t(`${TRADUCTION_BASE_PATH}noInfoText`)}
            />
        }
      </ModalBodyInfo>
    </DetailedInfoContainer >
  );
};
