import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';

import { getUrlFilterParamsToStore } from '../../../../../../helpers/urlHelpers';
import { updateListsByFilter, toggleIsLoading } from '../../../../../../redux/modules/civilWorkWorkerList';

import { Tags } from '../Components/Tags/Tags';
import { Title } from '../Components/Title/Title';

import { ObjectType } from '../../../../../../types/objectType';
import { COMPLEX_FILTER_TYPES, LIST_TYPES } from '../../../../../../config';

import filterCollapsableStyle from './filterCollapsableStyle';
import { event } from '../../../../../../config/analyticsConfig';

export const FilterCollapsableWrapper = (
  {
    children,
    filterType,
    tags,
    setTags,
    handleClickButton,
    buttonText,
    tagsDisabled,
    alwaysOpen,
    setAutocompleteTags,
    noDivider,
    tooltipMessage,
  }: ObjectType,
) => {
  const dispatch = useDispatch();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const classes = filterCollapsableStyle();

  const toggleIsOpenFilter = useCallback(() => {
    setIsOpenFilter((prevState) => !prevState);
  }, []);

  const changeUnselectedHandler = useCallback(
    (newTags: ObjectType[]) => {
      dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
      const filtersParsed = getUrlFilterParamsToStore(newTags, filterType, event.DELETE);
      const filterToUpdate = COMPLEX_FILTER_TYPES[filterType]
        ? filtersParsed
        : { [filterType]: filtersParsed };

      dispatch(updateListsByFilter(
        typeof filterToUpdate === 'string' ? {} : filterToUpdate,
        { [filterType]: newTags },
      ));

      setTags?.(newTags);
      setAutocompleteTags?.(newTags);
    },
    [dispatch, filterType],
  );

  const debounceUnSelectHandler = useCallback(
    debounce(changeUnselectedHandler, 1000),
    [],
  );

  return (
    <>
      {!alwaysOpen && (
        <Title
          filterType={filterType}
          tags={tags}
          isOpenFilter={alwaysOpen || isOpenFilter}
          toggleIsOpenFilter={toggleIsOpenFilter}
          tooltipMessage={tooltipMessage}
        />
      )}
      <Collapse in={alwaysOpen || isOpenFilter} timeout="auto" unmountOnExit>
        {children}
        {Array.isArray(tags) && !tagsDisabled && (
          <Tags
            tags={tags}
            debounceUnSelectHandler={debounceUnSelectHandler}
            filterType={filterType}
            handleClickButton={handleClickButton}
            buttonText={buttonText}
          />
        )}
      </Collapse>
      {!noDivider && <Divider className={classes.divider} />}
    </>
  );
};
