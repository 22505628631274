import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailedInfoContainer } from '../../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyTitle } from '../../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';

import { namespaces } from '../../../../../../../../../../i18n/i18n.constants';
import { StatusSelector } from './StatusSelector/StatusSelector';
import { StatusComment } from './StatusComment/StatusComment';
import { CustomButton } from '../../../../../../../../../Common/CustomButton/CustomButton';

import statusChangeSectionStyle from './statusChangeSectionStyle';
import { AuthContext } from '../../../../../../../../../../services/authContextProvider';

interface StatusChangeSectionProps {
  handleAddItem: (newStatus: string, newItem: any) => void;
}

export const StatusChangeSection = (props: StatusChangeSectionProps) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const styles = statusChangeSectionStyle();
  const { handleAddItem } = props;
  const [status, setStatus] = useState('gris');
  const [comments, setComments] = useState('');
  const authContext = useContext(AuthContext);

  const handleSaveChanges = () => {
    if (comments?.length > 0) {
      handleAddItem(
        status,
        {
          color: status,
          usuario: authContext.email,
          comentario: comments,
          fecha: new Date().toISOString(),
        },
      );
    }
  };

  const handleChangeStatus = (newStatus: string) => setStatus(newStatus);
  const handleChangeComments = (newComments: string) => setComments(newComments);

  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t('detailedInfoModal.workerDetailedInfo.statusChange.title')} />
      <div className={styles.body}>
        <div className={styles.selectorAndComment}>
          <StatusSelector handleChangeStatus={handleChangeStatus} />
          <StatusComment handleChangeComments={handleChangeComments} />
        </div>
        <div className={styles.button}>
          <CustomButton
            name={t('detailedInfoModal.workerDetailedInfo.statusChange.saveChanges')}
            customMessage={t('detailedInfoModal.workerDetailedInfo.statusChange.saveChanges')}
            handleClick={handleSaveChanges}
            style='terciary'
            disabled={comments?.length === 0 || status === 'gris'}
          />
        </div>
      </div>
    </ DetailedInfoContainer>
  );
};
