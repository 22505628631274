import {
  Modal,
  Grid,
} from '@mui/material';

import { LIST_TYPES } from '../../../../../../config';

import { WorkerDetailedInfo } from './DetailedInfo/WorkerDetailedInfo/WorkerDetailedInfo';
import { CivilWorksDetailedInfo } from './DetailedInfo/CivilWorksDetailedInfo/CivilWorksDetailedInfo';
import { ObjectType } from '../../../../../../types/objectType';
import { detailedInfoModalStyle } from './detailedInfoModalStyle';

export const DetailedInfoModal = (
  {
    open,
    listType,
    modalDetailedInfo,
    handleClose,
    handleChangeStatus,
  }: ObjectType,
) => {
  const { sx } = detailedInfoModalStyle;
  return (<Modal
    open={open}
    onClose={handleClose}
  >
    <Grid
      container
      sx={sx}
    >
      {
        (listType === LIST_TYPES.workers)
          ? (
            <WorkerDetailedInfo
              modalDetailedInfo={modalDetailedInfo}
              handleClose={handleClose}
              handleChangeStatus={handleChangeStatus}
            />)
          : <CivilWorksDetailedInfo
            listType={listType}
            modalDetailedInfo={modalDetailedInfo}
            handleClose={handleClose}
          />
      }
    </ Grid>
  </Modal>);
};
