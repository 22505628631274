import {
  Grid,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';

import { useTranslation } from 'react-i18next';

import { FilterWrapperTitleIcon } from '../FilterWrapperTitleIcon/FilterWrapperTitleIcon';

import { ObjectType } from '../../../../../../../types/objectType';

import filterTitleWrapperStyle from './filterWrapperTitleStyle';

import { namespaces } from '../../../../../../../i18n/i18n.constants';

export const FilterWrapperTitle = (
  {
    filterType,
    handleClose,
  }: ObjectType,
) => {
  const classes = filterTitleWrapperStyle();
  const { t } = useTranslation(namespaces.pages.resumesSearch);

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      className={classes.title}
    >
      <Grid
        container
        alignItems='center'
        className={classes.titleIconTypographyContainer}
      >
        <FilterWrapperTitleIcon
          filterType={filterType}
        />
        <Typography
          className={classes.titleTypography}
        >
          {t(`filters.${filterType}`)}
        </Typography>
      </Grid>
      <Grid
        item
      >
        <ClearIcon
          className={classes.titleClearIcon}
          fontSize='small'
          onClick={handleClose}
        />
      </Grid>
    </Grid>
  );
};
