import { checkAuthRequired } from './authenticationHelper';
import AzureAuthenticationContext from './azure/azure-authentication-context';
import {
  LOCALSTORAGE_KEYS,
} from '../../config';

export const authentication = (store: any) => (next: any) => (action: any) => {
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();

  const returnedAccountInfo = (user: any) => {
    // save user
    if (user) {
      localStorage.setItem(LOCALSTORAGE_KEYS.user, user);
      window.location.reload();
    }
  };
  const deleteAccountInfo = () => {
    localStorage.removeItem(LOCALSTORAGE_KEYS.user);
    window.location.reload();
  };

  if (action.type === 'LOGIN' && action.payload.isLoading) {
    const path = window.location.pathname;
    if (path && checkAuthRequired(path) && !localStorage.getItem(LOCALSTORAGE_KEYS.user)) {
      authenticationModule.login(returnedAccountInfo);
    }
  }
  if (action.type === 'LOGOUT' || (action.type === 'TOGGLE_IS_ERROR' && action.payload.invalidToken)) {
    authenticationModule.logout(deleteAccountInfo);
  }

  return next(action);
};

export default authentication;