export const languageDescriptionLeveStyle = {
  sx: {
    container: {
      width: '50%',
      minWidth: '50%',
      // border: '1px solid black',
    },
    item: {
      display: 'flex',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
    },
    language: {
      minWidth: '30%',
      width: '30%',
    },
    level: {
      minWidth: '70%',
      width: '70%',
    },
  },
};
