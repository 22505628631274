import { memo } from 'react';
import {
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Circle from '@mui/icons-material/Circle';

import { useTranslation } from 'react-i18next';

import { LIST_TYPES } from '../../../../../../../config';
import { ObjectType } from '../../../../../../../types/objectType';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import { reverseString, getEuropeanNumberFormat } from '../../../../../../../helpers/stringHelpers';
import { getOneDecimalPlaceNumber } from '../../../../../../../helpers/numberHelpers';

import { CardContentBox } from './CardContentBox/cardContentBox';
import { CardAvatar } from './CardAvatar/CardAvatar';
import { NoCardResult } from '../NoCardsResult/NoCardResult';
import { CustomButton } from '../../../../../../Common/CustomButton/CustomButton';

import { eventCategory } from '../../../../../../../config/analyticsConfig';
import { getColor, getStatus } from '../../../../../../utils/cvUtils';
import civilWorkWorkerCardsStyle from './civilWorkWorkerCardsStyle';

const CivilWorksWorkerCards = (
  {
    lastItemList,
    results,
    listType,
    handleOpen,
    handlePhoto,
  }: ObjectType,
) => {
  const styles = civilWorkWorkerCardsStyle();
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const resultsLength = results.length;
  const getExperienceParsed = (experience: number) => {
    if (!experience || typeof (experience) !== 'number') return null;
    const oneDecimalExperience = getOneDecimalPlaceNumber(experience).toLocaleString('de-DE');
    if (oneDecimalExperience === '1') {
      return `${oneDecimalExperience} ${t('card.worker.experienceDescriptionYear')}`;
    }
    return `${oneDecimalExperience} ${t('card.worker.experienceDescriptionYears')}`;
  };

  const getCivilWorksType = (tipos: Array<string>) => {
    if (tipos && Array.isArray(tipos)) {
      // eslint-disable-next-line no-confusing-arrow
      return tipos.map((tipo) => (tipo.length > 1 && tipo.includes('-'))
        ? tipo.split('-')[1].trim()
        : tipo).join(', ');
    }
    return null;
  };

  const getParsedCustomers = (customers: Array<string>) => {
    if (customers && Array.isArray(customers)) {
      return customers.join(', ');
    }
    return null;
  };

  return (
    <>
      {
        resultsLength === 0
          ? (
            <Grid container >
              <NoCardResult />
            </Grid>
          )
          : (
            <>
              {results.map((item: ObjectType, index: number) => {
                let refProps;
                const idiomas = item.idiomas && item.idiomas.map((idioma: ObjectType) => `${idioma.idioma}(${idioma.nivel})`);
                const importeParsed = item.importe ? `${getEuropeanNumberFormat(item.importe)}` : null;
                const fechaInicioFin = item.fechaFin
                  ? `${reverseString(item.fechaInicio)} - ${reverseString(item.fechaFin)}`
                  : reverseString(item.fechaInicio);
                const experiencia = getExperienceParsed(item.experiencia);
                if (results.length === index + 1) {
                  refProps = lastItemList;
                }

                return (
                  <div
                    key={item.id}
                    className={styles.cardContainer}
                    ref={refProps}
                  >
                    <div className={styles.cardHeader}>
                      <div className={styles.cardHeaderAvatar}>
                        <CardAvatar
                          item={item}
                          listType={listType}
                          sx={{
                            bgcolor: '#E0E0E0',
                            width: 66,
                            height: 66,
                            marginRight: '10px',
                          }}
                          handlePhoto={handlePhoto}
                        />
                      </div>
                      <div className={styles.cardHeaderInfo}>
                        <div className={listType === LIST_TYPES.workers
                          ? styles.nameAndDateWorker
                          : styles.nameAndDateWork}>
                          <div className={styles.cardHeaderInfoName}>
                            <Tooltip
                              enterDelay={1000}
                              title={
                                <Typography
                                  style={{ padding: '0.625rem' }}
                                >
                                  {item.nombre}
                                </Typography>
                              }
                              followCursor>
                              <span>{item.nombre}</span>
                            </Tooltip>
                          </div>
                          <div className={styles.cardHeaderInfoDate}>
                            {listType === LIST_TYPES.civilWorks
                              ? fechaInicioFin
                              : reverseString(item.fechaNacimiento)
                            }
                          </div>
                        </div>
                        {
                          listType === LIST_TYPES.workers
                            ? (
                              <div className={styles.status}>
                                <Circle
                                  style={{
                                    color: getColor(item.estado),
                                    fontSize: '15px',
                                    marginRight: '5px',
                                  }}
                                />
                                {t(`detailedInfoModal.workerDetailedInfo.statusChange.${getStatus(item.estado)}`)}
                              </div>
                            )
                            : null
                        }
                      </div>
                    </div>
                    <CardContent
                      sx={{ paddingBottom: 0 }}
                    >
                      {
                        listType === LIST_TYPES.civilWorks
                          ? (
                            <>
                              <CardContentBox title={t('card.civilWorks.amount')} description={importeParsed} />
                              <CardContentBox title={t('card.civilWorks.hireCode')} description={item.id} />
                              <CardContentBox
                                title={t('card.civilWorks.client')}
                                description={
                                  getParsedCustomers(item.clientes)
                                }
                              />
                              <CardContentBox
                                title={t('card.civilWorks.civilWorkType')}
                                description={getCivilWorksType(item.tipos)}
                              />
                            </>
                          )
                          : (
                            <>
                              <CardContentBox title={t('card.worker.experience')} description={experiencia} />
                              <CardContentBox title={t('card.worker.actualPosition')} description={item.posicion} />
                              <CardContentBox
                                title={t('card.worker.education')}
                                description={
                                  (
                                    item.titulaciones
                                    && item.titulaciones[0]
                                    && item.titulaciones[0].titulacion
                                  )
                                  && item.titulaciones[0].titulacion
                                } />
                              <CardContentBox title={t('card.worker.language')} description={idiomas.join(', ')} />
                            </>
                          )
                      }
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        direction='row'
                        justifyContent='end'
                      >
                        <Grid
                          item
                          style={{ margin: '0 15px 15px 0' }}
                        >
                          <CustomButton
                            name={t('button.showMore')}
                            size={'large'}
                            handleClick={() => handleOpen(
                              item.id,
                              listType === LIST_TYPES.civilWorks
                                ? eventCategory.project
                                : eventCategory.cv,
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardActions>
                  </div>
                );
              })}
            </>
          )
      }
    </>
  );
};

export default memo(CivilWorksWorkerCards);
