import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

const font: CSSProperties = {
  fontFamily: 'Roboto',
  color: '#111111',
  padding: '20px',
  textAlign: 'left',
};

export default makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    ...font,
    backgroundColor: '#F5F5F5',
    fontWeight: 700,
  },
  td: {
    ...font,
    verticalAlign: 'middle',
    fontWeight: 400,
    borderTop: '2px solid #F5F5F5',
  },
  statusTd: {
    display: 'flex',
    alignItems: 'center',
  },
  statusSpan: {
    marginLeft: '5px',
  },
});
