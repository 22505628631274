import { ObjectType } from '../../types/objectType';

/**
* Autocomplete Filter Types
*/
const FILTER_TYPES: ObjectType = {
  workersFilters: 'workersFilters',
  civilWorksFilters: 'civilWorksFilters',
  workersDiploma: 'workersDiploma',
  workersSupervisory: 'workersSupervisory',
  workersCertificate: 'workersCertificate',
  workersCostCenter: 'workersCostCenter',
  workersCurrentPosition: 'workersCurrentPosition',
  workersPositions: 'workersPositions',
  workersExperienceDescription: 'workersExperienceDescription',
  workersDegreeYear: 'workersDegreeYear',
  workersFullName: 'workersFullName',
  workersZone: 'workersZone',
  workersSpecialities: 'workersSpecialities',
  workersDegreeLevel: 'workersDegreeLevel',
  workersSpecialitiesExperience: 'workersSpecialitiesExperience',
  workersComplementaryTraining: 'workersComplementaryTraining',
  workersComplementaryTrainingHours: 'workersComplementaryTrainingHours',
  workersCvStatus: 'workersCvStatus',
  workersCvStatusReason: 'workersCvStatusReason',
  civilWorksBeginningDate: 'civilWorksBeginningDate',
  civilWorksEndDate: 'civilWorksEndDate',
  civilWorksByName: 'civilWorksByName',
  civilWorksClient: 'civilWorksClient',
  civilWorksType: 'civilWorksType',
  civilWorksSubtype: 'civilWorksSubtype',
  civilWorksMinimunAmount: 'civilWorksMinimunAmount',
  workersExperience: 'workersExperience',
  civilWorksClassification: 'civilWorksClassification',
  civilWorksCertificate: 'civilWorksCertificate',
  civilWorksZone: 'civilWorksZone',
  workersActive: 'workersActive',
  civilWorksContructiveProcesses: 'civilWorksContructiveProcesses',
};

export default FILTER_TYPES;
