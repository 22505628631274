import { } from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaAutosize } from '@mui/material';
import { namespaces } from '../../../../../../../../../../../i18n/i18n.constants';
import statusCommentStyle from './statusCommentStyle';

interface StatusCommentProps {
  handleChangeComments: (newComments: string) => void;
}

export const StatusComment = (props: StatusCommentProps) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const styles = statusCommentStyle();
  const { handleChangeComments } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t('detailedInfoModal.workerDetailedInfo.statusChange.comments')} <span className={styles.span}>*</span>
      </div>
      <TextareaAutosize
        className={styles.textArea}
        maxLength={150}
        style={{
          height: '130px',
          resize: 'none',
        }}
        onChange={(event) => handleChangeComments(event.target.value)}
      />
      <div className={styles.maxLength}>
        {t('detailedInfoModal.workerDetailedInfo.statusChange.maxLength')}
      </div>
    </div>
  );
};
