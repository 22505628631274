export const workerHeaderStyle = {
  sx: {
    rigthItemCivilWorks: {
      display: 'flex',
      width: '70%',
      maxWidth: '70%',
      paddingBottom: '20px',
    },
    rigthItem: {
      display: 'flex',
      width: '55%',
      maxWidth: '55%',
      paddingBottom: '20px',
    },
    rigthItemBox: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    rigthItemBoxTitle: {
      fontWeight: 600,
    },
    avatar: {
      bgcolor: '#E0E0E0',
      width: '83px',
      height: '83px',
      marginRight: '20px',
    },
    leftItemCivilWorks: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      alignItems: 'end',
      width: '30%',
    },
    leftItem: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      alignItems: 'end',
      width: '45%',
    },
    leftItemBox: {
      display: 'flex',
      height: '2rem',
      marginTop: '0.25rem',
    },
    leftItemTypography: {
      fontSize: '12px',
      marginTop: '0.2rem',
    },
    lefItemCancelIcon: {
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    errorMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '1.5rem',
      maxHeight: '1.5rem',
      marginTop: '0.5rem',
    },
    errorMessageTypography: {
      fontSize: '1.25rem',
      color: '#ff0100',
    },
    errorMessageIcon: {
      paddingTop: '0.2rem',
      color: '#ff0100',
    },
  },
};
