import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  container: {
    position: 'relative',
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
});
