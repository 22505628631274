/* eslint-disable no-unneeded-ternary */
import {
  FormControl,
  FormHelperText,
  Grid,
} from '@mui/material';
import { IProps } from '../../../../../../types/iProps';

export const FormControlWrapper = (
  {
    children,
    formHelperTextEnabled,
    customStyle,
    error,
  }: IProps,
) => {
  const isError = (error && error.length > 0)
    ? true
    : false;
  const basicFormControlWrapperStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    paddingBottom: '0.75rem',
    ...customStyle,
  };
  const formHelperTextEnabledStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    ...customStyle,
  };
  return (
    <FormControl
      style={
        !formHelperTextEnabled
          ? basicFormControlWrapperStyle
          : formHelperTextEnabledStyle
      }
      fullWidth
      error={isError}
    >
      {children}
      {formHelperTextEnabled && <Grid style={{ minHeight: '1.5rem', maxHeight: '1rem' }}>
        <FormHelperText style={{ padding: 0, marginTop: 0 }}>{error}</FormHelperText>
      </Grid>
      }
    </FormControl>);
};
