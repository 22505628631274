import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { ObjectType } from '../../../../../../../types/objectType';
import { CustomSelectMultiple } from '../../CustomSelectMultiple/CustomSelectMultiple';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import { LIST_TYPES, SELECT_OPTIONS_BY_FILTER } from '../../../../../../../config';
import { getUrlFilterParamsToStore } from '../../../../../../../helpers/urlHelpers';
import { toggleIsLoading, updateListsByFilter } from '../../../../../../../redux/modules/civilWorkWorkerList';
import { event } from '../../../../../../../config/analyticsConfig';
import { Tags } from '../../../Wrappers/Components/Tags/Tags';
import { getTranslatedOptions } from '../../../../../../../helpers/stringHelpers';

interface SelectMultipleProps {
  filterType: string;
  disabled?: boolean;
  setOnlyNeedsRevision?: (value: boolean) => void;
  handleSetTags?: (tags: ObjectType[]) => void;
}

export const SelectMultiple = (props: SelectMultipleProps) => {
  const {
    filterType, disabled, setOnlyNeedsRevision, handleSetTags,
  } = props;
  const dispatch = useDispatch();
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const [tags, setTags] = useState<Array<ObjectType>>([]);
  const { t } = useTranslation(namespaces.pages.resumesSearch);

  const [selectOptions, setSelectOptions] = useState<ObjectType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Array<string | undefined>>([]);

  const getSelectParsedOptions = (
    options: ObjectType,
    optionsFromTags: ObjectType,
  ) => options?.map((option: ObjectType) => {
    let selected = false;
    if (optionsFromTags && Array.isArray(optionsFromTags)) {
      optionsFromTags.forEach((optionFromTag: ObjectType) => {
        if (optionFromTag.name === option.name) {
          selected = true;
        }
      });
    }
    return ({ ...option, selected });
  });
  const getOptionsFromTags = async () => {
    const optionsFromTags = initialTags[filterType];
    const selectOpt = getTranslatedOptions(
      SELECT_OPTIONS_BY_FILTER[filterType].values,
      t,
      filterType,
    );
    const options = getSelectParsedOptions(selectOpt, optionsFromTags);
    return setSelectOptions(options);
  };

  const checkOnlyNeedsRevision = (filterSelected: ObjectType[]) => {
    if (setOnlyNeedsRevision) {
      if (filterSelected.length === 1
        && filterSelected[0].selectValue === 'naranja') setOnlyNeedsRevision(true);
      else setOnlyNeedsRevision(false);
    }
  };

  const getInfoToDispatch = (
    optionValues: Array<string | undefined>,
    selectOptionsToChange: ObjectType,
  ) => {
    const updatedFilter = selectOptionsToChange.map(
      (option: ObjectType) => (optionValues.includes(option.name)
        ? { ...option, selected: true }
        : { ...option, selected: false }),
    );
    const filterSelected = updatedFilter.filter(
      (option: ObjectType) => option.selected === true && ({ ...option }),
    );
    setSelectedOptions(optionValues);
    checkOnlyNeedsRevision(filterSelected);
    setTags(filterSelected);
    if (handleSetTags) handleSetTags(filterSelected);
    const filtersParsed = getUrlFilterParamsToStore(filterSelected, filterType, event.ADD);
    const filterToUpdate = {
      [filterType]: filtersParsed,
    };
    setSelectOptions(updatedFilter);
    return { filterToUpdate, filterSelected };
  };

  const dispatchSelected = (filterInfoToDispatch: ObjectType) => {
    const { filterToUpdate, filterSelected } = filterInfoToDispatch;
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    dispatch(updateListsByFilter(filterToUpdate, { [filterType]: filterSelected }));
  };

  useEffect(() => {
    const tagsToSet = initialTags[filterType] || [];
    if (handleSetTags) handleSetTags(tagsToSet);
    setTags(tagsToSet);
    getOptionsFromTags();
  }, [initialTags]);

  const debounceSelectedInfo = useCallback(
    debounce(dispatchSelected, 700),
    [],
  );

  const changeUnselectedHandler = (newTags: ObjectType[]) => {
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    checkOnlyNeedsRevision(newTags);
    const filtersParsed = getUrlFilterParamsToStore(newTags, filterType, event.DELETE);
    const filterToUpdate = { [filterType]: filtersParsed };
    dispatch(updateListsByFilter(
      typeof (filterToUpdate) === 'string'
        ? {}
        : filterToUpdate,
      { [filterType]: newTags },
    ));
    setTags(newTags);
  };

  const debounceUnSelectHandler = useCallback(
    debounce(changeUnselectedHandler, 1000),
    [],
  );

  return (<>
    <CustomSelectMultiple
      filterType={filterType}
      customStyle={{ paddingTop: '0.75rem' }}
      selectOptions={selectOptions}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      getInfoToDispatch={getInfoToDispatch}
      debounceSelectedInfo={debounceSelectedInfo}
      defaultValue={t(`filtersPlaceHolder.${filterType}`)}
      required={false}
      disabled={disabled}
    />{Array.isArray(tags) && <Tags
      tags={tags}
      debounceUnSelectHandler={debounceUnSelectHandler}
      filterType={filterType}
    />}
  </>
  );
};
