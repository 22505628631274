import {
  Grid,
  Typography,
  // Button,
} from '@mui/material';

export const NoCardResult = () => (
  <Grid
    container
    style={{ height: '82vh' }}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Grid
      container
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography sx={{ variant: 'body1' }} color="text.secondary" gutterBottom>
        Búsqueda sin resultados
      </Typography>
      {/* <Button variant="outlined" size="large" className={classes.root}>
          Limpiar filtros
        </Button> */}
    </Grid>
  </Grid>
);
