import { useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import ReactGA4 from 'react-ga4';

import { AuthContext } from '../services/authContextProvider';
import { primaryTheme } from '../themes/primatrytheme';
import { Navbar } from './Components/Navbar/Navbar';
import { ResumesSearch } from './Components/ResumesSearch/ResumesSearch';

import { SESSION_START } from './utils/constants';
import SESSION_ID from './utils/sessionID';
import { LoadingComponent } from './Common/LoadingComponent/LoadingComponent';

export const App = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
  }, []);

  ReactGA4.event('filter', {
    event_name: SESSION_START,
    ga_session_id: SESSION_ID,
  });

  if (!authContext.isAuthenticated) return <LoadingComponent />;

  return (
    <ThemeProvider theme={primaryTheme}>
      <Navbar />
      <ResumesSearch />
    </ThemeProvider >
  );
};
