export const cardAvatarStyle = {
  sx: {
    corporateFairIcon: {
      fontSize: '2.5rem',
    },
    personIcon: {
      fontSize: '3rem',
    },
  },
};
