import {
    PublicClientApplication,
    PopupRequest,
    SilentRequest,
} from "@azure/msal-browser";

import { MSAL_CONFIG } from "./azure-authentication-config";


export class AzureAuthenticationContext {
    private myMSALObj: PublicClientApplication = new PublicClientApplication(
        MSAL_CONFIG
    );
    private loginRequest?: PopupRequest;

    public isAuthenticationConfigured = false;

    constructor() {
        // @ts-ignore
        this.account = null;
        this.setRequestObjects();
        // console.log('auth', JSON.stringify(MSAL_CONFIG?.auth));
        if (MSAL_CONFIG?.auth?.clientId) {
            this.isAuthenticationConfigured = true;
        }
    }

    private setRequestObjects(): void {
        this.loginRequest = {
            scopes: ['user.Read'],
            prompt: 'select_account'
        };
    }

    login(setUser: any): void {
        this.myMSALObj.handleRedirectPromise().then((res) => {
            if (!(res)) {
                this.myMSALObj.loginRedirect(this.loginRequest).then((auth) => {
                    // console.log(auth);
                }).catch(e => {
                    // console.log(e);
                });
            } else {
                const silentParam: SilentRequest = {
                    scopes: ['user.Read'],
                    account: res.account || undefined
                };
                this.myMSALObj.acquireTokenSilent(silentParam).then((response) => {
                    this.verifyUser(response.accessToken).then(res => {
                        setUser(res.response);
                    });
                });
            }
        });
    }

    logout(deleteUser: any): void {
        deleteUser();
        /*  this.myMSALObj.logoutRedirect({
             postLogoutRedirectUri: '/',
         }); */
    }

    verifyUser(accessToken: string) {
        return fetch(`${process.env.REACT_APP_EMERGYA_SERVER_API_HOST}/v1/auth?token=${accessToken}`)
            .then(res => res.json())
            .catch(error => console.log(error));
    }
}

export default AzureAuthenticationContext;
