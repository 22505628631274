export const Version = () => (
  <div
    style={
      {
        position: 'relative',
        color: 'white',
        fontFamily: 'Roboto',
        top: '1rem',
        right: '3.2rem',
        fontSize: '0.7rem',
      }
    }
  >version {process.env.REACT_APP_VERSION}
  </div>
);
