import {
  Grid,
  CircularProgress,
} from '@mui/material';
// import Modal from '@mui/material/Modal';

export const LoadingComponent = () => (
  <Grid
    style={
      {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        marginTop: '0.3rem',
        width: '100%',
        height: '100%',
        zIndex: 1,
      }
    }
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Grid
      container
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress style={{ color: '#ff0100' }} />
    </Grid>
  </Grid>
);
