import {
  Box,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { ObjectType } from '../../../../../../../../types/objectType';
import cardContentBoxStyle from './cardContentBoxStyle';

export const CardContentBox = ({ title, description }: ObjectType) => {
  const parsedDescription = (description && typeof (description) === 'number')
    ? description.toLocaleString('de-DE')
    : description;
  const styles = cardContentBoxStyle();

  return (<Box
    sx={{ display: 'flex', padding: 0 }}
  >
    <div className={styles.title}>
      {title}:
    </div>
    {
      description
      && <Tooltip
        enterDelay={1000}
        title={
          <Typography
            style={{ padding: '0.625rem' }}
          >
            {parsedDescription}
          </Typography>
        }
        followCursor>
        <div className={styles.description}>
          {description}
        </div>
      </Tooltip>
    }
  </Box>);
};
