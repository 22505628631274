import { makeStyles } from '@material-ui/core';

export default makeStyles({
  infoMessage: {
    padding: '0 0.75rem',
    margin: '0 0 0.75rem',
    color: '#666666',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
});
