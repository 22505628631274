import { ObjectType } from '../types/objectType';

const basicMinimunTime = 3;
/**
* Autocomplete Filter Types
*/
const AUTOCOMPLETE_MIN_CHARACTER_SEARCH: ObjectType = {
  workersDiploma: basicMinimunTime,
  workersSupervisory: basicMinimunTime,
  workersCertificate: basicMinimunTime,
  workersCostCenter: basicMinimunTime,
  workersCurrentPosition: basicMinimunTime,
  workersPositions: basicMinimunTime,
  workersFullName: basicMinimunTime,
  workersZone: 1,
  civilWorksByName: basicMinimunTime,
  civilWorksClient: basicMinimunTime,
  civilWorksZone: basicMinimunTime,
  civilWorksType: 1,
  civilWorksSubtype: 1,
};

export default AUTOCOMPLETE_MIN_CHARACTER_SEARCH;
