import {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../../../../../../../helpers/regexHelpers';

import { DetailedInfoContainer } from '../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyInfo } from '../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';
import { ModalBodyTitle } from '../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { NoInfoBox } from '../../../ModalComponent/ModalBody/NoInfoBox/NoInfoBox';

import { namespaces } from '../../../../../../../../../i18n/i18n.constants';
import { ObjectType } from '../../../../../../../../../types/objectType';

import { civilWorksBasicEnumerationStyle } from './civilWorksBasicEnumerationStyle';

export const CivilWorksBasicEnumeration = (
  {
    modalDetailedInfo,
    enumerationType,
    descriptionWithVolumen,
  }: ObjectType,
) => {
  const [enumerationParsedInfo, setEnumerationParsedInfo] = useState([]);
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const {
    title,
    bbddKey,
  } = enumerationType;
  const {
    main,
    code,
    description,
    descriptionCode,
    amount,
  } = bbddKey;
  const TRADUCTION_BASE_PATH = `detailedInfoModal.civilWorksDetailedInfo.${title}.`;

  const getDescriptionObject = (item: ObjectType) => {
    const finalObject = { ...item };
    if (title === 'units' && finalObject[description]) {
      const descriptionArray = finalObject[description].split(' ');
      const [parsedDescriptionCode, ...parsedDescription] = descriptionArray;
      finalObject[descriptionCode] = parsedDescriptionCode;
      finalObject[description] = parsedDescription.join(' ');
      return finalObject;
    }
    if (title === 'magnitudes' && finalObject[descriptionCode]) {
      const descriptionArray = finalObject[descriptionCode].split(' ');
      const parsedDescriptionCode = descriptionArray[descriptionArray.length - 1];
      finalObject[descriptionCode] = parsedDescriptionCode;
      return finalObject;
    }
    return finalObject;
  };

  useEffect(() => {
    const enumerationInfo = modalDetailedInfo[main];
    if (enumerationInfo.length > 0) {
      const sortedByCode = enumerationInfo.sort(
        (a: ObjectType, b: ObjectType) => b[code].toString().length - a[code].toString().length,
      );
      const maxLengthCode = sortedByCode[0][code].toString().length;
      const parsedInfo = enumerationInfo.map((item: ObjectType) => {
        const stringCode = item[code].toString();
        const stringCodeLength = stringCode.length;
        const parsedItem = { ...item };
        const cerosToAdd = maxLengthCode - stringCodeLength;
        parsedItem[code] = '0'.repeat(cerosToAdd) + stringCode;
        return { ...parsedItem };
      });
      setEnumerationParsedInfo(parsedInfo);
    }
  }, [modalDetailedInfo]);
  const { sx } = civilWorksBasicEnumerationStyle;
  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t(`${TRADUCTION_BASE_PATH}title`)} />
      <ModalBodyInfo >
        {
          enumerationParsedInfo.length > 0
            ? enumerationParsedInfo.map((item: ObjectType, index: number) => {
              const descriptionObject = getDescriptionObject(item);
              const arrayLength = enumerationParsedInfo.length - 1;
              return (<Box
                sx={
                  (arrayLength !== index)
                    ? sx.detailContainer
                    : sx.detailContainerLastItem
                }
                key={uuidv4()}
              >
                <Box
                  sx={
                    descriptionWithVolumen
                      ? sx.codeDescriptionWithVolume
                      : sx.codeDescription
                  }
                >
                  <Typography
                    variant='subtitle2'
                    p={0}
                    mb={0}
                  >
                    {descriptionObject[code]}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    p={0}
                    ml={descriptionWithVolumen ? 2 : 3}
                    mb={0}
                  >
                    {descriptionObject[description]}
                  </Typography>
                </Box>
                {
                  descriptionWithVolumen
                  && (<>
                    <Box sx={sx.magnitudeCode}>
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        p={0}
                        mb={0}
                      >
                        {descriptionObject[descriptionCode]}
                      </Typography>
                    </Box>
                    <Box sx={sx.amount}>
                      <Typography
                        variant='subtitle2'
                        p={0}
                        ml={2}
                        mb={0}
                      >
                        {
                          descriptionObject[amount]
                            ? formatNumber(descriptionObject[amount])
                            : null
                        }
                      </Typography>
                    </Box>
                  </>)
                }
              </Box>);
            })
            : <NoInfoBox
              value={t(`${TRADUCTION_BASE_PATH}noInfoText`)}
            />
        }
      </ModalBodyInfo>
    </DetailedInfoContainer >
  );
};
