import { makeStyles } from '@material-ui/styles';

const font = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  letterSpacing: '0.5px',
};

const title = {
  fontWeight: 400,
  color: '#666666',
  marginBottom: '7px',
};

const description = {
  fontWeight: 600,
};

export default makeStyles({
  title: {
    ...font,
    ...title,
    minWidth: '40%',
    width: '40%',
  },
  description: {
    ...font,
    ...description,
    minWidth: '50%',
    maxWidth: '50%',
  },
  titleOneColumn: {
    ...font,
    ...title,
    minWidth: '20%',
    width: '20%',
  },
  descriptionOneColumn: {
    ...font,
    ...description,
    minWidth: '80%',
    maxWidth: '80%',
  },
});
