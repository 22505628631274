import { makeStyles } from '@material-ui/styles';

export default makeStyles({

  container: {
    display: 'flex',
    alignItems: 'stretch',
    minWidth: '100vw',
    height: '93vh',
    minHeight: '93vh',
    maxHeight: '93hv',
    backgroundColor: '#f5f5f5',
  },
  lists: {
    minWidth: '60vw',
    maxWidth: '60vw',
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
  filter: {
    minWidth: '20vw',
    maxWidth: '20vw',
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
  header: {
    fontSize: '1rem',
  },
});
