export const getColor = (estado: string) => {
  switch (estado) {
    case 'rojo':
      return '#ed5958';
    case 'gris':
      return 'gray';
    case 'naranja':
      return '#fe982e';
    case 'verde':
      return '#53ad5b';
    default:
      return 'transparent';
  }
};

export const getStatus = (estado: string) => {
  switch (estado) {
    case 'rojo':
      return 'invalid';
    case 'gris':
      return 'noData';
    case 'naranja':
      return 'needsRevision';
    case 'verde':
      return 'verified';
    default:
      return '';
  }
};
