import { ObjectType } from '../../types/objectType';

/**
* Param Autocomplete Filters
*/
const URL_KEY_PARAMS: ObjectType = {
  workersDiploma: 'p_titulacion=',
  workersSupervisory: 'p_supervisory=',
  workersCertificate: 'p_certificado=',
  workersCostCenter: 'p_centro=',
  workersCurrentPosition: 'p_posicion=',
  workersPositions: 'p_posicionesAnteriores=',
  workersFullName: 'p_empleadoNombre=',
  workersZone: 'p_zona=',
  workersSpecialities: 'p_experienciasEmpleado=',
  workersComplementaryTraining: 'p_formacion=',
  workersDegreeLevel: 'p_titulacionNivel=',
  civilWorksByName: 'o_obraNombre=',
  civilWorksClient: 'o_cliente=',
  civilWorksType: 'o_tipo=',
  civilWorksSubtype: 'o_subtipo=',
  civilWorksClassification: 'o_clasificacion=',
  civilWorksClassificationGroup: 'o_clasificacionGrupo=',
  civilWorksClassificationSubgroup: 'o_clasificacionSubgrupo=',
  civilWorksCertificate: 'o_tieneCertificados',
  civilWorksZone: 'o_zonaCode=',
  civilWorksContructiveProcesses: 'o_procesosCode=',
  workersCvStatus: 'p_semaforo_estado=',
  workersCvStatusReason: 'p_semaforo_motivo=',
  limitCivilWorks: '?o_limit={limitCivilWorks}',
  limitWorkers: '&p_limit={limitWorkers}',
  pageWorkers: '&p_page={pageWorkers}',
  pageCivilWorks: '&o_page={pageCivilWorks}',
  orderWorkers: '&p_order={orderWorkers}',
  orderCivilWorks: '&o_order={orderCivilWorks}',
  workersExperienceDescription: '&p_descripcionExperiencia=',
  workersSpecialitiesExperience: '&p_rangoExperienciasEmpleado=',
  civilWorksBeginningDate: '&o_fechaInicio=',
  civilWorksEndDate: '&o_fechaFin=',
  workersDegreeYear: '&p_fechaTitulacion=',
  civilWorksMinimunAmount: '&o_importe=',
  workersExperience: '&p_experiencia=',
  civilWorksClassificationGruop: '&o_clasificacionGrupo=',
  workersActive: '&p_activo=',
};

export default URL_KEY_PARAMS;
