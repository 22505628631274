import {
  Box,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import { DetailedInfoContainer } from '../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyInfo } from '../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';
import { ModalBodyTitle } from '../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { NoInfoBox } from '../../../ModalComponent/ModalBody/NoInfoBox/NoInfoBox';

import { DETAILED_INFO_MODAL_VARIABLES } from '../../../../../../../../../config';

import { namespaces } from '../../../../../../../../../i18n/i18n.constants';
import { ObjectType } from '../../../../../../../../../types/objectType';

import { civilWorksCertificatesStyle } from './civilWorksCertificatesStyle';

export const CivilWorksCertificates = (
  {
    modalDetailedInfo,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const { sx } = civilWorksCertificatesStyle;
  const {
    title,
    bbddKey,
  } = DETAILED_INFO_MODAL_VARIABLES.civilWorkscertificates;
  const {
    main,
    code,
    type,
    description,
    language,
  } = bbddKey;
  const TRADUCTION_BASE_PATH = `detailedInfoModal.civilWorksDetailedInfo.${title}.`;
  const enumerationInfo = modalDetailedInfo[main];
  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t(`${TRADUCTION_BASE_PATH}title`)} />
      <ModalBodyInfo>
        {
          enumerationInfo.length > 0
            ? enumerationInfo.map(
              (
                item: ObjectType,
                index: number,
              ) => (
                <Box
                  key={uuidv4()}
                  sx={
                    enumerationInfo.length === (index + 1)
                      ? sx.detailContainerLastItem
                      : sx.detailContainer
                  }
                >
                  <Box sx={sx.code}>
                    <Typography
                      variant='subtitle2'
                      p={0}
                      mb={0}
                    >
                      {item[code]}
                    </Typography>
                  </Box>
                  <Box sx={sx.type}>
                    <Typography
                      variant='subtitle2'
                      p={0}
                      mb={0}
                    >
                      {item[type]}
                    </Typography>
                  </Box>
                  <Box sx={sx.description}>
                    <Typography
                      variant='subtitle2'
                      p={0}
                    >
                      {item[description]}
                    </Typography>
                  </Box>
                  <Box sx={sx.language}>
                    <Typography
                      variant='body2'
                      color="text.secondary"
                      p={0}
                    >
                      {item[language]}
                    </Typography>
                  </Box>
                </Box>
              ),
            )
            : <NoInfoBox
              value={t(`${TRADUCTION_BASE_PATH}noInfoText`)}
            />

        }
      </ModalBodyInfo>
    </DetailedInfoContainer >
  );
};
