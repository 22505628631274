import { ObjectType } from '../../types/objectType';

/**
* Autocomplete Filter Types
*/
const COMPLEX_FILTER_TYPES: ObjectType = {
  civilWorksClassification: {
    main: {
      name: 'civilWorksClassificationGroup',
      queryParams: 'idGrupo:|importe:',
    },
    subgroup: {
      name: 'civilWorksClassificationSubgroup',
      queryParams: 'idSubgrupo:|importe:',
    },
  },
};

export default COMPLEX_FILTER_TYPES;
