const basicContainer = {
  minWidth: '100%',
};

const inlineTypo = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  fontFamily: 'Roboto',
  color: '#333333',
  padding: '10px 0',
};

export const experiencieDescriptionSx = {
  sx: {
    container: {
      ...basicContainer,
      borderBottom: '1px solid #E0E0E0',
      padding: '15px 0',
      marginBottom: '20px',
    },
    inlineContainer: {
      ...basicContainer,
      display: 'flex',
      marginBottom: '1rem',
    },
    inlineContainerLastElement: {
      ...basicContainer,
      display: 'flex',
    },
    containerLastElement: {
      minWidth: '100%',
      padding: '15px 0',
      paddingBottom: '20px',
    },
    inlineTypographyContainer: {
      ...inlineTypo,
      borderBottom: '1px solid #E0E0E0',
    },
    inlineTypographyContainerLast: {
      ...inlineTypo,
    },
    subtitle2: {
      fontSize: '16px',
      margin: 0,
    },
    aditionalDescription: {
      display: 'flex',
      marginTop: '0.25rem',
      marginBottom: '0.5rem',
    },
  },
};
