/**
 * Api Params Url filters
 */
const URL_FILTERS_PARAMS = {
  limitCivilWorks: '?o_limit={limitCivilWorks}',
  limitWorkers: '&p_limit={limitWorkers}',
  pageWorkers: '&p_page={pageWorkers}',
  pageCivilWorks: '&o_page={pageCivilWorks}',
  orderWorkers: '&p_order={orderWorkers}',
  orderCivilWorks: '&o_order={orderCivilWorks}',
  workersDiploma: '&p_titulacion={workersDiploma}',
  workersSupervisory: '&p_supervisory={workersSupervisory}',
  workersCertificate: '&p_certificado={workersCertificate}',
  workersCostCenter: '&p_centro={workersCostCenter}',
  workersCurrentPosition: '&p_posicion={workersCurrentPosition}',
  workersPositions: '&p_posicionesAnteriores={workersPositions}',
  workersExperienceDescription: '&p_descripcionExperiencia={workersExperienceDescription}',
  workersSpecialities: '&p_experienciasEmpleado={workersSpecialities}',
  workersComplementaryTraining: '&p_formacion={workersComplementaryTraining}',
  workersDegreeLevel: '&p_titulacionNivel={workersDegreeLevel}',
  workersSpecialitiesExperience: '&p_rangoExperienciasEmpleado={workersSpecialitiesExperience}',
  workersCvStatus: '&p_semaforo_estado={workersCvStatus}',
  workersCvStatusReason: '&p_semaforo_motivo={workersCvStatusReason}',
  civilWorksBeginningDate: '{civilWorksBeginningDate}',
  civilWorksEndDate: '{civilWorksEndDate}',
  civilWorksByName: '&o_obraNombre={civilWorksByName}',
  workersDegreeYear: '{workersDegreeYear}',
  civilWorksClient: '&o_cliente={civilWorksClient}',
  civilWorksType: '&o_tipo={civilWorksType}',
  civilWorksSubtype: '&o_subtipo={civilWorksSubtype}',
  workersFullName: '&p_empleadoNombre={workersFullName}',
  civilWorksMinimunAmount: '&o_importeMin={civilWorksMinimunAmount}',
  workersExperience: '&p_experienciaMin={workersExperience}',
  civilWorksClassificationGruop: '&o_clasificacionGrupo={civilWorksClassificationGroup}',
  civilWorksClassificationSubgroup: '&o_clasificacionSubgrupo={civilWorksClassificationSubgroup}',
  civilWorksCertificate: '&o_tieneCertificados={civilWorksCertificate}',
  workersZone: '&p_zona={workersZone}',
  civilWorksZone: '&o_zonaCode={civilWorksZone}',
  workersActive: '&p_activo={workersActive}',
  civilWorksContructiveProcesses: '&o_procesosCode={civilWorksContructiveProcesses}',
};

export default URL_FILTERS_PARAMS;
