import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListItemIcon, ListItemText, MenuItem, Select,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { namespaces } from '../../../../../../../../../../../i18n/i18n.constants';
import statusSelectorStyle from './statusSelectorStyle';

interface StatusSelectorProps {
  handleChangeStatus: (newStatus: string) => void;
}

export const StatusSelector = (props: StatusSelectorProps) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const styles = statusSelectorStyle();
  const [selected, setSelected] = useState('none');
  const { handleChangeStatus } = props;

  const item = (value: string, text: string, color: string) => (
    <MenuItem key={value} value={value}>
      <ListItemIcon>
        <CircleIcon style={{ color }} />
      </ListItemIcon>
      <ListItemText primary={text} />
    </MenuItem>
  );

  const items = [
    { value: 'rojo', text: t('detailedInfoModal.workerDetailedInfo.statusChange.invalid'), color: '#ed5958' },
    { value: 'naranja', text: t('detailedInfoModal.workerDetailedInfo.statusChange.needsRevision'), color: '#fe982e' },
    { value: 'verde', text: t('detailedInfoModal.workerDetailedInfo.statusChange.verified'), color: '#53ad5b' },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t('detailedInfoModal.workerDetailedInfo.statusChange.status')}
      </div>
      <Select
        sx={{
          width: '100%',
          color: 'grey',
        }}
        value={selected}
        onChange={(event) => {
          setSelected(event.target.value);
          handleChangeStatus(event.target.value);
        }}
        renderValue={() => {
          if (selected === 'none') {
            return t('detailedInfoModal.workerDetailedInfo.statusChange.placeholder');
          }
          const selectedItem = items.find((option) => option.value === selected);
          return selectedItem ? selectedItem.text : '';
        }}
      >
        {items.map(({ value, text, color }) => item(value, text, color))}
      </Select>
    </div>
  );
};
