import { ObjectType } from '../../types/objectType';
import FILTER_TYPES from './filterTypes';

/**
* Autocomplete Filter Types
*/
const WORKERS_FILTERS: string[] = Object.keys(FILTER_TYPES)
  .filter((key) => key.startsWith('workers'));

export default WORKERS_FILTERS;
