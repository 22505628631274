import {
  Box,
  Typography,
} from '@mui/material';

import detailBoxesStyle from '../detailBoxesStyle';

interface DetailBoxOneColumnProps {
  title: string;
  value: string | null;
}
export const DetailBoxOneColumn = (props: DetailBoxOneColumnProps) => {
  const { title, value } = props;
  const styles = detailBoxesStyle();
  return (<Box
    sx={{
      display: 'flex',
      width: '100%',
      paddingBottom: '0.25rem',
      marginTop: '5px',
    }}
  >
    <div className={styles.titleOneColumn}>
      {title}
    </div>
    <div className={styles.descriptionOneColumn}>
      {value}
    </div>
  </Box>);
};
