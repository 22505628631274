export const SESSION_START = 'session_start';

export const ALL_SPECIALITIES = [
  'specialities.aggregates',
  'specialities.anchors',
  'specialities.supportsAndJoints',
  'specialities.highways',
  'specialities.boxCulverts',
  'specialities.catenary',
  'specialities.hydroelectricPlants',
  'specialities.foundationsAndGroundTreatment',
  'specialities.slopedDike',
  'specialities.designTechnicalOffice',
  'specialities.segments',
  'specialities.dredging',
  'specialities.drainage',
  'specialities.metalStructures',
  'specialities.largeBridgesLongSpan',
  'specialities.concreteSlabs',
  'specialities.looseMaterials',
  'specialities.heavyAuxiliaryEquipmentCarts',
  'specialities.heavyAuxiliaryEquipmentShoring',
  'specialities.heavyAuxiliaryEquipmentSlidingFormwork',
  'specialities.heavyAuxiliaryEquipmentLaunchers',
  'specialities.heavyAuxiliaryEquipmentHeavyLifting',
  'specialities.heavyAuxiliaryEquipmentClimbingSystems',
  'specialities.austrianTunnelingMethodNATM',
  'specialities.earthworks',
  'specialities.retainingWallsAndPiles',
  'specialities.pavements',
  'specialities.drillingAndBlastingDB',
  'specialities.concretePlants',
  'specialities.postTensioningAndStays',
  'specialities.bridges',
  'specialities.roadHeaders',
  'specialities.railwaySystems',
  'specialities.tunnelBoringMachineTBM',
  'specialities.ballastTrack',
  'specialities.slabTrack',
  'specialities.viaducts',
  'specialities.precastBeamsAndSegments',
];
