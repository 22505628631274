import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    '& .MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        borderColor: '#ff0100',
        border: '2px solid',
      },
    },
  },
  noStyle: {},
});
