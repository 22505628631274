import {
  Box,
} from '@mui/material';
import detailBoxesStyle from '../detailBoxesStyle';

interface DetailBoxProps {
  title: string;
  value: string | null;
}

export const DetailBox = (props: DetailBoxProps) => {
  const { title, value } = props;
  const styles = detailBoxesStyle();
  return (<Box
    sx={{
      display: 'flex',
      paddingBottom: '0.25rem',
      paddingTop: '0.25rem',
    }}
  >
    <div className={styles.title}>
      {title}
    </div>
    <div className={styles.description}>
      {value}
    </div>
  </Box>);
};
