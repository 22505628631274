import {
  Box,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { namespaces } from '../../../../../../../../../../../i18n/i18n.constants';
import { ObjectType } from '../../../../../../../../../../../types/objectType';

import { languageDescriptionLeveStyle } from './languageDescriptionLevelStyle';

export const LanguageDescriptionLevel = (
  {
    languageInfo,
    translation,
    language,
    level,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const TRADUCTION_BASE_PATH = `detailedInfoModal.workerDetailedInfo.${translation}.`;
  const { sx } = languageDescriptionLeveStyle;

  const getLanguageLevel = () => {
    const lglevel = languageInfo[level];
    if (lglevel) {
      return t(`${TRADUCTION_BASE_PATH}level.${String(lglevel)}`);
    }
    return t(`${TRADUCTION_BASE_PATH}noInfoText.level`);
  };
  const laguageLevel = getLanguageLevel();
  return (
    <Box
      sx={sx.container}
    >
      <Box
        sx={sx.item}
      >
        <Typography
          variant='body2'
          color="text.secondary"
          gutterBottom
          style={sx.language}
        >
          {languageInfo[language]}
        </Typography>
        <Typography
          variant='body2'
          gutterBottom
          style={sx.level}
        >
          {laguageLevel}
        </Typography>
      </Box>
    </Box>
  );
};
