import { makeStyles } from '@material-ui/styles';

const nameAndDate = {
  display: 'flex',
  flexFlow: 'column',
  margin: 'auto auto auto 0',
};

export default makeStyles({
  cardContainer: {
    borderRadius: 12,
    marginRight: '0.2rem',
    width: '97%',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 3px 8px 0px rgba(0, 0, 0, 0.3)',
    margin: '5px 0 1rem 5px',
  },
  cardHeader: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    padding: '15px',
    height: 'auto',
  },
  cardHeaderAvatar: {
    width: 'fit-content',
  },
  cardHeaderInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'space-between',
  },
  nameAndDateWorker: {
    ...nameAndDate,
    width: '65%',
  },
  nameAndDateWork: {
    ...nameAndDate,
    width: '95%',
  },
  cardHeaderInfoName: {
    color: '#000000',
    fontWeight: 500,
    fontSize: '20px',
    fontFamily: 'Roboto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardHeaderInfoDate: {
    color: '#505050',
    fontSize: '15px',
  },
  status: {
    marginTop: '5px',
    whiteSpace: 'nowrap',
    paddingTop: '10px',
  },

  '@media (max-width: 1900px)': {
    nameAndDateWorker: {
      width: '100%',
    },
    status: {
      width: '200px',
      paddingTop: 0,
    },
    cardHeaderInfoName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});
