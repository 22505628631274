/**
 * List Types
 */
const LIST_TYPES = {
  civilWorks: 'civilWorks',
  workers: 'workers',
  civilWorksWorkers: 'civilWorksWorkers',
};

export default LIST_TYPES;
