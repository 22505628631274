import {
  Grid,
} from '@mui/material';

import { ObjectType } from '../../../../../../../../types/objectType';

export const ModalHeader = (
  {
    children,
    photo,
  }: ObjectType,
) => (<Grid
  container
  sx={
    {
      paddingBottom: photo ? null : '1rem',
      borderBottom: '1.5px solid',
      borderColor: 'rgba(0, 0, 0, 0.15)',
    }
  }
>
  {children}
</Grid>);
