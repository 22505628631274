import {
    LOCALSTORAGE_KEYS,
} from '../../config';

export default function authHeader(): HeadersInit {
    const user = localStorage.getItem(LOCALSTORAGE_KEYS.user);
    const requestHeaders: HeadersInit = new Headers();
    if (user) {
        requestHeaders.set('x-access-token', user);
        requestHeaders.set('Content-Type', 'application/json');
    }
    return requestHeaders;
}