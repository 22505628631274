import React,
{
  useRef,
  useState,
  useEffect,
  useCallback,
  memo,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Grid, LinearProgress } from '@mui/material';

import { ObjectType } from '../../../../../types/objectType';

import {
  getNextListPage,
  toggleIsLoading,
  toggleIsFilterUpdated,
} from '../../../../../redux/modules/civilWorkWorkerList';

import CivilWorksWorkerCards from './InfiniteScrollListsCards/CivilWorksWorkerCards/CivilWorksWorkerCards';
import { DetailedInfoModal } from './DetailedInfoModal/DetailedInfoModal';
import { InfiniteScrollListFilter } from './InfiniteScrollListFilter/InfiniteScrollListFilter';

import infiniteScrollListStyle from './infiniteScrollListStyle';
import { sendShowDetailsEventAnalytics, sendStatusChangeEventAnalytics } from '../../../../../helpers/analyticsHelpers';
import { StatusChangeObject } from '../../../../../types/statusChangeObject';
import worksWorkersAPI from '../../../../../services/worksWorkersAPI';
import { LoadingComponent } from '../../../../Common/LoadingComponent/LoadingComponent';

const InfiniteScrollList: React.FC<ObjectType> = ({ listType }) => {
  const classes = infiniteScrollListStyle();
  const lists = useSelector((store: ObjectType) => store.civilWorksPersonLists[listType]);
  const [open, setOpen] = useState(false);
  const [modalDetailedInfo, setModalDetailedInfo] = useState({});
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);

  const { isFilterUpdated } = lists;

  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    moreResults,
    totalResults,
    results,
  } = lists;

  const observer = useRef<IntersectionObserver>();
  const scrollToTop = useRef<null | HTMLDivElement>(null);

  const scrollToRef = () => {
    if (scrollToTop.current?.offsetTop) {
      scrollToTop.current?.scrollIntoView({ block: 'end' });
    }
  };

  const lastItemList = useCallback(
    (node) => {
      if (isLoading || isFilterUpdated) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && moreResults) {
          dispatch(toggleIsLoading(listType));
          dispatch(getNextListPage(listType));
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, moreResults, isFilterUpdated],
  );

  const handleOpen = (id: string, eventCategory: string) => {
    const detailedInfo = results.find((item: ObjectType) => item.id === id);
    let specialities = '';
    detailedInfo.experiencesEmployee?.forEach((speciality: any) => {
      specialities += `${speciality.experiencia} (${speciality.nivel_experiencia}), `;
    });
    detailedInfo.especialidades = specialities.replace(/, $/, '');
    setModalDetailedInfo(detailedInfo);
    setOpen(true);
    sendShowDetailsEventAnalytics(eventCategory, id);
  };

  const handleClose = () => {
    setModalDetailedInfo({});
    setOpen(false);
  };

  const handlePhoto = (id: string, fotoSrc: string) => {
    const detailedInfo = results.find((item: ObjectType) => item.id === id);
    detailedInfo.photo = fotoSrc;
  };

  const handleChangeStatus = (id: string, newStatus: string, newItem: StatusChangeObject) => {
    setStatusChangeLoading(true);
    const detailedInfo = results.find((item: ObjectType) => item.id === id);
    const oldStatus = detailedInfo.estado;
    worksWorkersAPI.updateStatus(id, newItem)
      .then(() => {
        const newHistory = [newItem, ...detailedInfo.historialCambios];
        detailedInfo.estado = newStatus;
        detailedInfo.historialCambios = newHistory;
        setModalDetailedInfo((prevHistory) => ({
          ...prevHistory,
          estado: newStatus,
          historialCambios: newHistory,
        }));
        sendStatusChangeEventAnalytics(id, newStatus, oldStatus);
        setStatusChangeLoading(false);
      }).catch(() => {
        console.error('Error updating status');
        setStatusChangeLoading(false);
      });
  };

  useEffect(() => {
    if (isFilterUpdated) {
      scrollToRef();
      dispatch(toggleIsFilterUpdated(listType));
    }
  }, [lists, isFilterUpdated]);

  const renderComponent = () => (statusChangeLoading
    ? <LoadingComponent />
    : (
      <Grid
        item
        xs={12}
        ref={scrollToTop}
        style={
          {
            minHeight: '84vh',
            maxHeight: '84vh',
          }
        }
      >
        <DetailedInfoModal
          open={open}
          listType={listType}
          modalDetailedInfo={modalDetailedInfo}
          handleClose={handleClose}
          handleChangeStatus={handleChangeStatus}
        />
        <CivilWorksWorkerCards
          results={results}
          lastItemList={lastItemList}
          listType={listType}
          handleOpen={handleOpen}
          handlePhoto={handlePhoto}
        />
        {isLoading && <LinearProgress color="inherit" />}
      </Grid>
    ));

  return (
    <Grid
      item
      p={1}
      xs={6}
      style={{ height: '100%' }}
    >
      <InfiniteScrollListFilter
        totalResults={totalResults}
        listType={listType}
      />
      <Grid
        container
        justifyContent="center"
        className={classes.infiniteScroll}
      >
        {
          isError
            ? <p>Error</p>
            : renderComponent()
        }
      </Grid>
    </Grid>
  );
};

export default memo(InfiniteScrollList);
