/**
 * initParamsFilters
 */
const INIT_FILTERS = {
  limitCivilWorks: 10,
  pageCivilWorks: 1,
  pageWorkers: 1,
  limitWorkers: 10,
  orderCivilWorks: 'importe',
  orderWorkers: 'experiencia',
  workersActive: 'true',
};

export default INIT_FILTERS;
