import {
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import errorComponentStyle from './ErrorComponentStyle';

import { namespaces } from '../../../../../../i18n/i18n.constants';

export const ErrorComponent = () => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const classes = errorComponentStyle();
  return (
    <Grid
      container
      style={{ height: '90vh' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography sx={{ variant: 'body1' }} color="text.secondary" gutterBottom>
          {t('errorMessages.title', { ns: namespaces.common })}
        </Typography>
        <Button
          variant="outlined"
          size="large"
          disableElevation
          className={classes.root}>
          {t('errorMessages.button', { ns: namespaces.common })}
        </Button>
      </Grid>
    </Grid>
  );
};
