import { useTranslation } from 'react-i18next';
import workerDetailedInfoTabsStyle from './workerDetailedInfoTabsStyle';
import { namespaces } from '../../../../../../../../../i18n/i18n.constants';

interface WorkerDetailedInfoTabsProps {
  selectedTab: number;
  handleChange: (opt: number) => void;
}

export const WorkerDetailedInfoTabs = (props: WorkerDetailedInfoTabsProps) => {
  const styles = workerDetailedInfoTabsStyle();
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const { selectedTab, handleChange } = props;
  const tabClassName = (index: number) => (selectedTab === index ? styles.selected : styles.tab);

  return (
    <div className={styles.tabsContainer}>
      <div
        onClick={() => handleChange(0)}
        className={tabClassName(0)}
      >{t('detailedInfoModal.cvData')}</div>
      <div
        onClick={() => handleChange(1)}
        className={tabClassName(1)}
      >{t('detailedInfoModal.statusChange')}</div>
    </div>);
};
