import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import civilWorksPersonListsReducer from './modules/civilWorkWorkerList';
import errorMessageReducer from './modules/errorMessages';
import authentication from './authentication/authenticationMiddleware';

const rootReducer = combineReducers({
  civilWorksPersonLists: civilWorksPersonListsReducer,
  errorMessages: errorMessageReducer,
});

export default function generateStore() {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, authentication)));
  return store;
}
