import React,
{
  useState,
} from 'react';

import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import {
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  InputLabel,
  InputAdornment,
} from '@mui/material';

import { FormControlWrapper } from '../../Wrappers/FormControlWrapper/FormControlWrapper';

import { ObjectType } from '../../../../../../types/objectType';
import { formatNumber } from '../../../../../../helpers/regexHelpers';

import numberInputStyle from './numberInputStyle';

export const NumberInput = (
  {
    filterType,
    textFieldValue,
    setTextFieldValue,
    placeHolderValue,
    errorMessage,
    setErrorMessage,
  }: ObjectType,
) => {
  const classes = numberInputStyle();
  const textFieldValueLength = textFieldValue ? textFieldValue.length : 0;
  const [key, setKey] = useState<string | undefined>(undefined);
  // const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [onFocusValue, setOnFocusValue] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const element = e.target;
    const targetSplitted = e.target.value.split('');
    let caret = element.selectionStart || 0;
    const europeanNumberFormat = formatNumber(e.target.value);
    if (
      (caret && key !== 'Backspace' && key !== 'Delete')
      && (
        europeanNumberFormat && europeanNumberFormat[caret]
        && (
          targetSplitted[caret - 1] === europeanNumberFormat[caret]
        )
      )
    ) {
      caret += 1;
    }
    setTextFieldValue(europeanNumberFormat);
    window.requestAnimationFrame(() => {
      element.setSelectionRange(caret, caret);
    });
  };

  const handleOnBlur = () => {
    setOnFocusValue(false);
  };

  const handleOnFocus = () => {
    setOnFocusValue(true);
  };

  return (
    <FormControlWrapper
      formHelperTextEnabled={true}
      error={errorMessage}
    >
      {(!onFocusValue && !textFieldValue)
        && <InputLabel
          htmlFor="searchValue"
          style={{ paddingLeft: '0.75rem' }}
        >
          <Typography
            style={{ color: 'black' }}
          >
            {placeHolderValue}
            <span style={{ color: 'red ' }}>
              *
            </span>
          </Typography>
        </InputLabel>}
      <TextField
        onChange={handleChange}
        value={textFieldValue}
        id={`CustomTextField${filterType}`}
        InputProps={{
          endAdornment: (textFieldValueLength > 0)
            && (
              <InputAdornment position="end">
                <div
                  onClick={() => setTextFieldValue([])}
                  className={classes.iconWrapper}>
                  {textFieldValueLength > 0 && <ClearIcon className={classes[onFocusValue ? 'iconOnFocus' : 'iconOnBlur']} />}
                </div>
              </InputAdornment>
            ),
        }}
        onKeyDown={(e) => setKey(e.key)}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        InputLabelProps={{ shrink: false }}
        className={classes.root}
        variant='outlined'
        autoComplete='off'
      />
    </FormControlWrapper >
  );
};
