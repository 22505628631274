import { useTranslation } from 'react-i18next';
import { AutocompleteMultipleChoice } from '../../Components/AutocompleteMultichoice/AutocompleteMultichoice';
import { TextSearchable } from '../../Components/TextSearchable/TextSearchable';
import { DatePickerRange } from '../../Components/DatePickerRange/DatePickerRange';
import { NumberTextField } from '../../Components/NumberTextField/NumberTextField';
import { SelectMultipleFilter } from '../../Components/SelectMultipleFilter/SelectMultipleFilter';
import { SelectFilter } from '../../Components/SelectFilter/SelectFilter';

import { FilterListWrapper } from '../../Wrappers/FilterListWrapper/FilterListWrapper';

import FILTER_TYPES from '../../../../../../config/filtersConfig/filterTypes';
import { AutocompleteAndSelect } from '../../Components/AutocompleteAndSelect/AutocompleteAndSelect';
import { AutocompleteAndTextField } from '../../Components/AutocompleteAndTextField/AutocompleteAndTextField';
import { namespaces } from '../../../../../../i18n/i18n.constants';
import { DoubleSelectMultiple } from '../../Components/DoubleSelectMultiple/DoubleSelectMultiple';

export const WorkersFiltersList = () => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  return <FilterListWrapper
    filterListTitle={FILTER_TYPES.workersFilters}
    personColumn
  >
    <DoubleSelectMultiple
      firstSelectorFilter={FILTER_TYPES.workersCvStatus}
      secondSelectorFilter={FILTER_TYPES.workersCvStatusReason}
      tooltipMessage={t('tooltip.or')}
    />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.workersFullName} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.workersCurrentPosition} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.workersPositions} />
    <NumberTextField filterType={FILTER_TYPES.workersExperience} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.workersCostCenter} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.workersDiploma} />
    <AutocompleteMultipleChoice
      filterType={FILTER_TYPES.workersDegreeLevel}
      tooltipMessage={t('tooltip.or')}
    />
    <DatePickerRange filterType={FILTER_TYPES.workersDegreeYear} />
    <AutocompleteAndTextField
      filterType={FILTER_TYPES.workersComplementaryTraining}
      combinedFilterType={FILTER_TYPES.workersComplementaryTrainingHours}
      infoMessage={t('common.workersComplementaryTrainingInfoMessage')}
      tooltipMessage={t('tooltip.and')}
    />
    <AutocompleteAndSelect
      filterType={FILTER_TYPES.workersSpecialities}
      combinedFilterType={FILTER_TYPES.workersSpecialitiesExperience}
      infoMessage={t('common.workersSpecialitiesExperienceInfoMessage')}
      tooltipMessage={t('tooltip.and')}
    />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.workersSupervisory} />
    <TextSearchable filterType={FILTER_TYPES.workersExperienceDescription} />
    <AutocompleteMultipleChoice filterType={FILTER_TYPES.workersCertificate} />
    <SelectMultipleFilter filterType={FILTER_TYPES.workersZone} />
    <SelectFilter filterType={FILTER_TYPES.workersActive} />
  </FilterListWrapper>;
};
