import { Typography } from '@mui/material';
import { ObjectType } from '../../../../../../../../../types/objectType';

export const NoInfoBox = (
  {
    value,
  }: ObjectType,
) => (<Typography
  variant='h6'
  gutterBottom
  style={
    {
      fontSize: '1rem',
      marginBottom: '0.75rem',
    }
  }
>
  {value}
</Typography>
);
