import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { ObjectType } from '../../../../../../../../types/objectType';
import { namespaces } from '../../../../../../../../i18n/i18n.constants';

import autocompleteTextFieldStyle from './autocompleteTextFieldStyle';

interface AutocompleteTextFieldProps {
  filterType: string;
  params: ObjectType;
  debounceInputHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  textFieldValue: string;
  setTextFieldValue: (value: string) => void;
  setOptions: (options: ObjectType[]) => void;
  required: boolean;
}

export const AutocompleteTextField = (
  props: AutocompleteTextFieldProps,
) => {
  const {
    filterType,
    params,
    debounceInputHandler,
    textFieldValue,
    setTextFieldValue,
    setOptions,
    required,
  } = props;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const classes = autocompleteTextFieldStyle();
  const textFieldValueLength = textFieldValue ? textFieldValue.length : 0;
  return (
    <TextField
      {...params}
      onChange={
        (e: React.ChangeEvent<HTMLInputElement>) => {
          debounceInputHandler(e);
          setTextFieldValue(e.target.value);
        }
      }
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <div onClick={() => {
              setTextFieldValue('');
              setOptions([]);
            }} className={classes.iconWrapper}>
              {textFieldValueLength > 0 && <ClearIcon className={classes.icon} />}
            </div>
          </InputAdornment>
        ),
      }}
      onBlur={() => {
        setTextFieldValue('');
        setOptions([]);
      }}
      InputLabelProps={{ shrink: false }}
      className={classes.root}
      label={textFieldValue?.length > 0 ? '' : `${t(`filtersPlaceHolder.${filterType}`)}`}
      required={required}
      sx={{
        '& .MuiFormLabel-root': {
          color: '#717171',
          '&.Mui-focused': {
            color: '#717171',
          },
          '& .MuiInputLabel-asterisk': {
            color: 'red',
          },
        },
      }}
      variant='outlined'
      autoComplete='off'
    />
  );
};
