import { v4 as uuidv4 } from 'uuid';

import { ReactElement, useEffect, useState } from 'react';

import Circle from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import PaymentsIcon from '@mui/icons-material/Payments';
import GroupIcon from '@mui/icons-material/Group';
import Chip from '@mui/material/Chip';
import DescriptionIcon from '@mui/icons-material/Description';
import TodayIcon from '@mui/icons-material/Today';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ConstructionIcon from '@mui/icons-material/Construction';
import HandymanIcon from '@mui/icons-material/Handyman';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RoomIcon from '@mui/icons-material/Room';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { withStyles } from '@material-ui/core';

import { namespaces } from '../../../../../../../i18n/i18n.constants';

import { CustomButton } from '../../../../../../Common/CustomButton/CustomButton';

import { ObjectType } from '../../../../../../../types/objectType';
import { FILTER_TYPES } from '../../../../../../../config/index';

const BasicStyledChip = withStyles(() => ({
  root: {
    maxWidth: '15vw !important',
  },
  label: {
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Chip);

const DatesStyledChips = withStyles(() => ({
  root: {
    maxWidth: '15vw !important',
  },
  label: {
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Chip);

export const Tags = (
  {
    tags,
    debounceUnSelectHandler,
    filterType,
    handleClickButton,
    buttonText,
    combinedFilterValue,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const [componentTags, setComponentTags] = useState<any>(tags);
  useEffect(() => {
    setComponentTags(tags);
  }, [tags]);
  let Icon: ReactElement;
  switch (filterType) {
    case FILTER_TYPES.workersDiploma:
    case FILTER_TYPES.workersDegreeYear:
      Icon = <SchoolIcon />;
      break;
    case FILTER_TYPES.workersCertificate:
      Icon = <WorkIcon />;
      break;
    case FILTER_TYPES.workersCostCenter:
      Icon = <PaymentsIcon />;
      break;
    case FILTER_TYPES.workersSupervisory:
      Icon = <GroupIcon />;
      break;
    case FILTER_TYPES.workersCurrentPosition:
    case FILTER_TYPES.workersPositions:
    case FILTER_TYPES.workersExperience:
      Icon = <WorkIcon />;
      break;
    case FILTER_TYPES.workersExperienceDescription:
      Icon = <DescriptionIcon />;
      break;
    case FILTER_TYPES.civilWorksBeginningDate:
    case FILTER_TYPES.civilWorksEndDate:
      Icon = <TodayIcon />;
      break;
    case FILTER_TYPES.civilWorksClient:
      Icon = <BusinessCenterIcon />;
      break;
    case FILTER_TYPES.civilWorksType:
      Icon = <ConstructionIcon />;
      break;
    case FILTER_TYPES.civilWorksSubtype:
    case FILTER_TYPES.civilWorksContructiveProcesses:
      Icon = <HandymanIcon />;
      break;
    case FILTER_TYPES.civilWorksByName:
      Icon = <CorporateFareIcon />;
      break;
    case FILTER_TYPES.civilWorksMinimunAmount:
      Icon = <MonetizationOnIcon />;
      break;
    case FILTER_TYPES.workersFullName:
      Icon = <PersonIcon />;
      break;
    case FILTER_TYPES.civilWorksClassification:
      Icon = <AssignmentIcon />;
      break;
    case FILTER_TYPES.workersZone:
      Icon = <RoomIcon />;
      break;
    case FILTER_TYPES.civilWorksZone:
      Icon = <RoomIcon />;
      break;
    default:
      break;
  }

  const buttonDialogOpen = <CustomButton
    size={'small'}
    name={t(`button.${buttonText}`)}
    style={'rootGreyTags'}
    handleClick={handleClickButton}
  />;

  const generateTag = (index: number, tag: ObjectType) => {
    const onDelete = () => {
      const newComponentTags = componentTags.filter(
        (componentTag: ObjectType) => componentTag.name !== componentTags[index].name,
      );
      setComponentTags(newComponentTags);
      debounceUnSelectHandler(newComponentTags, combinedFilterValue);
    };
    return <Box mb={1} key={uuidv4()}>
      {([
        FILTER_TYPES.civilWorksBeginningDate,
        FILTER_TYPES.civilWorksEndDate,
        FILTER_TYPES.workersDegreeYear,
      ].includes(filterType))
        ? <DatesStyledChips
          size='small'
          icon={Icon}
          key={index}
          label={tag.filterNameParsed || tag.name}
          onDelete={onDelete}
        />
        : <BasicStyledChip
          size='small'
          icon={tag.color
            ? <Circle style={{ color: tag.color }} />
            : Icon}
          key={index}
          label={tag.filterNameParsed || tag.name}
          onDelete={onDelete}
        />
      }
    </ Box >;
  };

  let tagsByStyleChipsType = componentTags.map((tag: ObjectType, index: number) => {
    if (tag.name && tag.name.length > 0) return generateTag(index, tag);
    return null;
  });

  tagsByStyleChipsType = handleClickButton
    ? [...tagsByStyleChipsType, buttonDialogOpen]
    : tagsByStyleChipsType;

  return (
    < Box
      sx={{
        ml: 1.6,
        mt: buttonText ? 1.5 : 'inherit',
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(:last-child)': { mr: 1 },
        '& > *': { mr: 1 },
      }}
    >
      {tagsByStyleChipsType}
    </Box >);
};
