import react, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { CustomTextField } from '../NumberTextField/CustomTextField/CustomTextField';
import { CombinedFilterCollapsableWrapper } from '../../Wrappers/CombinedFilterCollapsableWrapper/combinedFilterCollapsableWrapper';
import { CustomAutocomplete } from '../AutocompleteMultichoice/CustomAutocomplete/CustomAutocomplete';
import autocompleteAndTextFieldStyle from './autocompleteAndTextFieldStyle';
import { ObjectType } from '../../../../../../types/objectType';
import { LIST_TYPES } from '../../../../../../config';
import { toggleIsLoading, updateListsByFilter } from '../../../../../../redux/modules/civilWorkWorkerList';
import { getUrlCombinedFilterParamsToStore } from '../../../../../../helpers/urlHelpers';
import { Tags } from '../../Wrappers/Components/Tags/Tags';
import { event } from '../../../../../../config/analyticsConfig';

interface AutocompleteAndTextFieldProps {
  filterType: string;
  combinedFilterType: string;
  infoMessage: string;
  tooltipMessage?: string;
}

export const AutocompleteAndTextField = (props: AutocompleteAndTextFieldProps) => {
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const {
    filterType, combinedFilterType, infoMessage, tooltipMessage,
  } = props;
  const dispatch = useDispatch();
  const [tags, setTags] = useState<Array<ObjectType>>([]);
  const [textFieldTag, setTextFieldTag] = useState<Array<ObjectType>>([]);
  const [combinedFilterValue, setCombinedFilterValue] = useState<any>('');
  const classes = autocompleteAndTextFieldStyle();
  const clearValue = combinedFilterValue.replace(/[.]*/g, '');

  const getFiltersToUpdate = (newFilters: ObjectType[]) => {
    const filtersFromOptions = newFilters
      .filter((filter: ObjectType) => (filter && filter.name) && filter);
    setTags(filtersFromOptions);
    const filtersToStore = {
      filters: filtersFromOptions,
      combinedFilterValue: clearValue || '0',
    };
    const filtersParsed = getUrlCombinedFilterParamsToStore(
      filtersToStore,
      filterType,
      event.ADD,
    );
    const filterToUpdate = {
      [filterType]: filtersParsed,
    };
    return { filterToUpdate, filtersFromOptions };
  };

  const handleChangeSelected = (
    e: SyntheticEvent<Element, Event>,
    newFilters: ObjectType[],
  ) => getFiltersToUpdate(newFilters);

  const dispatchSelected = (filterInfoToDispatch: ObjectType) => {
    const { filterToUpdate, filtersFromOptions } = filterInfoToDispatch;
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    dispatch(updateListsByFilter(filterToUpdate, { [filterType]: filtersFromOptions }));
  };

  const debounceSelectedInfo = useCallback(
    debounce(dispatchSelected, 600),
    [],
  );

  useEffect(() => {
    const filterInfoToDispatch = getFiltersToUpdate(tags);
    debounceSelectedInfo(filterInfoToDispatch);
  }, [combinedFilterValue]);

  const handleChangeTextFieldValue = (newValue: string) => {
    setTextFieldTag([{ name: newValue }]);
    setCombinedFilterValue(newValue);
  };

  const handleClearTextFieldValue = () => {
    setTextFieldTag([]);
    setCombinedFilterValue('');
  };

  useEffect(() => {
    const tagsToUpdate = initialTags[filterType];
    setTags(tagsToUpdate || []);
    if (tagsToUpdate?.length === 0) {
      setTextFieldTag(tagsToUpdate);
      setCombinedFilterValue('');
    }
  }, [initialTags]);

  const firstChildComponent = <CustomAutocomplete
    filterType={filterType}
    tags={tags}
    handleChangeSelected={handleChangeSelected}
    debounceSelectedInfo={debounceSelectedInfo}
    required
  />;

  const secondChildComponent = <>
    <CustomTextField
      filterType={combinedFilterType}
      textFieldValue={combinedFilterValue}
      setTextFieldValue={handleChangeTextFieldValue}
      debounceTextUnselected={handleClearTextFieldValue}
      disabled={tags.length === 0}
      typeImput='float'
    />
    {Array.isArray(textFieldTag) && <Tags
      tags={textFieldTag}
      debounceUnSelectHandler={handleClearTextFieldValue}
      filterType={combinedFilterType}
    />}
    {
      infoMessage && Array.isArray(textFieldTag) && textFieldTag.length > 0 && <p
        className={classes.infoMessage}
      > {infoMessage} </p>
    }</>;

  return (
    <CombinedFilterCollapsableWrapper
      filterType={filterType}
      tags={tags}
      firstChild={firstChildComponent}
      secondChild={secondChildComponent}
      setTags={setTags}
      combinedFilterValue={combinedFilterValue || '0'}
      tooltipMessage={tooltipMessage}
    />
  );
};
