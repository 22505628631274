import { makeStyles } from '@material-ui/styles';

const font = {
  fontFamily: 'Roboto',
  color: '#333333',
  marginBottom: '5px',
};

export default makeStyles({
  date: {
    ...font,
    fontSize: '12px',
    fontWeight: 400,
  },
  degree: {
    ...font,
    fontSize: '16px',
    fontWeight: 600,
  },
  aditionalDescriptionTitle: {
    fontFamily: 'Roboto',
    color: '#FF0000',
    fontSize: '12px',
    fontWeight: 400,
    textDecoration: 'underline',
    width: '100%',
    marginBottom: '5px',
  },
  aditionalDescriptionSubtitle: {
    fontFamily: 'Roboto',
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: 600,
    color: '#333333',
    whiteSpace: 'nowrap',
  },
  body: {
    fontFamily: 'Roboto',
    color: '#333333',
    fontSize: '14px',
    fontWeight: 400,
  },
  inlineSubtitle1: {
    width: '15%',
    fontSize: '12px',
    fontWeight: 400,
  },
  inlineSubtitle2: {
    width: '60%',
    fontSize: '16px',
    fontWeight: 600,
  },
  inlineBody: {
    width: '25%',
    fontSize: '14px',
    fontWeight: 400,
  },
});
