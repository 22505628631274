import { Grid } from '@material-ui/core';

import { CivilWorksWorkerList } from './CivilWorksWorkerList/CivilWorksWorkerList';
import { WorkersFiltersList } from './FiltersMenu/ResultsLists/WorkersFiltersList/WorkersFiltersList';
import { CivilWorksFiltersList } from './FiltersMenu/ResultsLists/CivilWorksFiltersList/CivilWorksFiltersList';

import resumesSearchStyles from './resumesSearchStyle';
import { AlertMessages } from './AlertMessages/AlertMessages';

export const ResumesSearch = () => {
  const classes = resumesSearchStyles();

  return (
    <Grid
      container
      spacing={1}
      className={classes.container}
      alignItems="center"
      justifyContent='center'
    >
      <Grid
        item
        className={classes.filter}
      >
        <WorkersFiltersList />
      </Grid>
      <Grid className={classes.lists}>
        <CivilWorksWorkerList />
      </Grid>
      <Grid item className={classes.filter} >
        <CivilWorksFiltersList />
      </Grid>
      <AlertMessages />
    </Grid >
  );
};
