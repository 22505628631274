/* eslint-disable no-unneeded-ternary */
import {
  Button,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ObjectType } from '../../../types/objectType';
import { CustomButtonProps } from '../../../types/customButtomProps';
import { truncateString } from '../../../helpers/stringHelpers';

import customButtonStyle from './customButtonStyle';

export const CustomButton = (
  {
    name,
    size,
    handleClick,
    disabled,
    customMessage,
    style,
    icon,
  }: CustomButtonProps,
) => {
  const minWidthAllowed = useMediaQuery('(min-width:1385px)');
  const classes: ObjectType = customButtonStyle();
  const textToShow = !disabled ? name : customMessage;
  return (
      <>
      { style !== 'terciary' ? (
      <Button
        disabled={disabled}
        disableElevation
        variant='outlined'
        size={size}
        className={style ? classes[style] : classes.root}
        onClick={handleClick}
        startIcon={icon}
      >
        {minWidthAllowed ? textToShow : truncateString(textToShow || '', 14)}
      </Button>
      ) : (
      <Button
        disabled={disabled}
        disableElevation
        variant={ disabled ? 'outlined' : 'contained'}
        size={size}
        className={style ? classes[style] : classes.root}
        onClick={handleClick}
        startIcon={icon}
      >
        {minWidthAllowed ? textToShow : truncateString(textToShow || '', 14)}
      </Button>
      )}
      </>
  );
};
