const padding = 0;

export const civilWorksDetailedInfoStyle = {
  sx: {
    date: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      marginBottom: '2rem',
      padding,
    },
    basicDateContainer: {
      display: 'flex',
      width: '40%',
    },
    adresColumn1: {
      width: '50%',
      padding,
    },
    adressColumn2: {
      width: '50%',
      marginBottom: '1.5rem',
      padding,
    },
    description: {
      width: '100%',
      padding,
    },
  },
};
