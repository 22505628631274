import React,
{
  useState,
  useCallback,
  KeyboardEvent,
} from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@material-ui/core';
import {
  InputAdornment,
  FormGroup,
} from '@mui/material';

import { ObjectType } from '../../../../../../../types/objectType';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import { getUrlFilterParamsToStore } from '../../../../../../../helpers/urlHelpers';
import { formatNumber, formatFloat } from '../../../../../../../helpers/regexHelpers';
import { getNumbersFromString } from '../../../../../../../helpers/stringHelpers';
import {
  updateListsByFilter,
  toggleIsLoading,

} from '../../../../../../../redux/modules/civilWorkWorkerList';

import { LIST_TYPES } from '../../../../../../../config';

import customTextFieldStyle from './customTextFieldStyle';
import { event } from '../../../../../../../config/analyticsConfig';

export const CustomTextField = (
  {
    filterType,
    tags,
    textFieldValue,
    setTextFieldValue,
    debounceTextUnselected,
    isMultiplePopOverInput,
    disabled,
    typeImput,
  }: ObjectType,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const classes = customTextFieldStyle();
  const textFieldValueLength = textFieldValue ? textFieldValue.length : 0;
  const [key, setKey] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [onFocusValue, setOnFocusValue] = useState(false);

  const textSelectedHandler = (
    e: KeyboardEvent<HTMLDivElement>,
    newValue: ObjectType,
  ) => {
    if (
      e.key === 'Enter'
      && !isMultiplePopOverInput
      && tags
    ) {
      if (newValue.length === 0) return setErrorMessage(t('input.errorEmptyInput'));
      dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
      const newTag = { name: newValue };
      const filtersParsed = getUrlFilterParamsToStore(
        [...tags, { name: newValue }],
        filterType,
        event.ADD,
      );
      const filterToUpdate = {
        [filterType]: getNumbersFromString(
          typeof (filtersParsed) === 'string'
            ? filtersParsed
            : '',
        ),
      };
      return dispatch(
        updateListsByFilter(
          filterToUpdate,
          { [filterType]: [newTag] },
        ),
      );
    }
    return null;
  };

  const debounceTextSelected = useCallback(
    debounce(textSelectedHandler, 100),
    [],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const element = e.target;
    const targetSplitted = e.target.value.split('');
    let caret = element.selectionStart || 0;
    let europeanNumberFormat = formatNumber(e.target.value);
    if (typeImput === 'float') {
      europeanNumberFormat = formatFloat(e.target.value);
    }
    if (
      (caret && key !== 'Backspace' && key !== 'Delete')
      && (
        europeanNumberFormat && europeanNumberFormat[caret]
        && (
          targetSplitted[caret - 1] === europeanNumberFormat[caret]
        )
      )
    ) {
      caret += 1;
    }
    setTextFieldValue(europeanNumberFormat);
    window.requestAnimationFrame(() => {
      element.setSelectionRange(caret, caret);
    });
  };

  const handleOnBlur = () => {
    setOnFocusValue(false);
  };

  const handleOnFocus = () => {
    setOnFocusValue(true);
  };

  return (
    <FormGroup
      className={classes.textField}
    >
      <TextField
        onChange={handleChange}
        value={textFieldValue}
        id={`CustomTextField${filterType}`}
        disabled={disabled}
        InputProps={{
          endAdornment: (textFieldValueLength > 0)
            && (
              <InputAdornment position="end">
                <div
                  onClick={() => debounceTextUnselected(textFieldValue, tags)}
                  className={classes.iconWrapper}>
                  {textFieldValueLength > 0 && <ClearIcon className={classes.icon} />}
                </div>
              </InputAdornment>
            ),
        }}
        onKeyDown={(e) => setKey(e.key)}
        onKeyPress={(e) => {
          debounceTextSelected(e, textFieldValue);
        }}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        InputLabelProps={{ shrink: false }}
        className={classes.root}
        variant='outlined'
        autoComplete='off'
        placeholder={t(`filtersPlaceHolder.${filterType}`)}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </FormGroup >
  );
};
