import { useTranslation } from 'react-i18next';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ClearIcon from '@mui/icons-material/Clear';
import specialitiesPopupStyle from './specialitiesPopupStyle';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import { ALL_SPECIALITIES } from '../../../../../../utils/constants';

interface SpecialitiesPopupProps {
  handleCloseSpecialities: () => void;
}

export const SpecialitiesPopup = (props: SpecialitiesPopupProps) => {
  const { handleCloseSpecialities } = props;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const styles = specialitiesPopupStyle();

  return (
    <div
      className={styles.overlay}
      onClick={() => handleCloseSpecialities()}
    >
      <div
        className={styles.specialitiesPopup}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.titleAndCloseButton}>
          <div className={styles.title}>
            <span className={styles.span}>
              <WorkspacePremiumIcon />
            </span>{t('specialities.title')}
          </div>
          <ClearIcon
            sx={{
              color: '#666666',
              cursor: 'pointer',
              marginTop: '2px',
              ':hover': {
                color: '#333333',
              },
              ':active': {
                color: '#111111',
              },
            }}
            onClick={() => handleCloseSpecialities()}
          />
        </div>
        <div className={styles.specialitiesList}>
          {ALL_SPECIALITIES.map((speciality) => <div className={styles.speciality}>
            {t(speciality)}
          </div>)}
        </div>
      </div>
    </div>
  );
};
