import { ModalBody } from '../../../ModalComponent/ModalBody/ModalBody';
import { StatusChangeSection } from './StatusChangeSection/StatusChangeSection';
import { ChangeHistory } from './ChangeHistory/ChangeHistory';
import { StatusChangeObject } from '../../../../../../../../../types/statusChangeObject';

interface StatusChangeProps {
  userId: string,
  changeHistory: StatusChangeObject[],
  handleChangeStatus: (id: string, newStatus: string, newItem: StatusChangeObject) => void,
}

export const StatusChange = (props: StatusChangeProps) => {
  const { userId, changeHistory, handleChangeStatus } = props;

  const handleAddItem = (newStatus: string, newItem: StatusChangeObject) => {
    handleChangeStatus(userId, newStatus, newItem);
  };

  return <ModalBody>
    <StatusChangeSection handleAddItem={handleAddItem} />
    <ChangeHistory items={changeHistory} />
  </ModalBody>;
};
