import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import './i18n/i18n';

import './index.css';

import { App } from './App/App';

import generateStore from './redux/store';
import { AuthContextProvider, initAuthContextProvider } from './services/authContextProvider';

const store = generateStore();

initAuthContextProvider().then(() => {
  ReactDOM.render(
    <React.Suspense fallback={<>Loading...</>}>
      <Provider store={store}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </Provider>
    </React.Suspense >,
    document.getElementById('root'),
  );
});
