/**
 * @function getOneDecimalPlaceNumber
 *
 * @description returns number to correct one decimal ux format
 *
 * @param {number} number
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
const getOneDecimalPlaceNumber = (number: number) => Math.floor(number * 10) / 10;

export {
  getOneDecimalPlaceNumber,
};
