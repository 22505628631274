export const namespaces = {
  pages: {
    hello: 'pages.hello',
    resumesSearch: 'pages.resume',
    navbar: 'pages.navbar',
  },
  common: 'common',
};

export const languages = {
  es: 'es',
  en: 'en',
};
