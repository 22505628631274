export const civilWorksBasicEnumerationStyle = {
  sx: {
    detailContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.75rem',
    },
    detailContainerLastItem: {
      display: 'flex',
      width: '100%',
    },
    codeDescription: {
      display: 'flex',
      width: '100%',
      padding: 0,
    },
    codeDescriptionWithVolume: {
      display: 'flex',
      width: '50%',
      maxWidth: '50%',
      padding: 0,
    },
    magnitudeCode: {
      display: 'flex',
      justifyContent: 'center',
      width: '5%',
      padding: 0,
    },
    amount: {
      width: '20%',
      padding: 0,
    },
  },
};
