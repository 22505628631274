import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    width: '100%',
    zIndex: 1,
    '& .MuiAutocomplete-listbox': {
      backgroundColor: 'white',
      fontSize: 16,
      '& li': {
        padding: 0,
      },
    },
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: '#000000',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#000000',
        border: '2px solid black',
      },
    },
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      color: 'black',
    },
  },
});
