const pathToAuthMap = [
    {
        url: "/",
        requireAuth: true,
    }
];

const checkAuthRequired = (path: string) => {
    for (const element of pathToAuthMap) {
        if (element.url == path) {
            return element.requireAuth;
        }
    }
    return false; // default value
};

export {
    checkAuthRequired
}