import {
  Grid,
} from '@mui/material';

import { ObjectType } from '../../../../../../../../../types/objectType';

const style = {
  width: '100%',
};

export const DetailedInfoContainer = ({ children }: ObjectType) => (
  <Grid
    item
    sx={style}
  >
    {children}
  </ Grid>
);
