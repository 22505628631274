/**
 * Translation & BBDD keys Variables from Detailed Info Modal
 */
const DETAILED_INFO_MODAL_VARIABLES = {
  education: {
    translation: 'education',
    bbddKey: {
      main: 'titulaciones',
      receptionDate: 'fechaRecepcion',
      startDate: 'fechaInicio',
      endDate: 'fechaFin',
      subTitle1: 'titulacion',
      subTitle2: 'grado',
      description: 'centro',
    },
  },
  workExperience: {
    translation: 'workExperience',
    bbddKey: {
      main: 'experiencias',
      startDate: 'fechaInicio',
      endDate: 'fechaFin',
      subTitle1: 'posicion',
      subTitle2: 'empresa',
      description: 'comentario',
      aditionalDescription: 'obra',
      aditionalDescriptionAlternative: 'supervisory',
      aditionalDescriptionTitle: 'centro',
      aditionalDescriptionSubtitle: 'importe',
    },
  },
  certificates: {
    translation: 'certificates',
    inline: true,
    bbddKey: {
      main: 'certificaciones',
      startDate: 'fechaObtencion',
      subTitle1: 'certificacion',
      description: 'certificador',
    },
  },
  formations: {
    translation: 'formations',
    inline: true,
    bbddKey: {
      main: 'formaciones',
      startDate: 'fecha_finalizacion',
      subTitle1: 'nombre_curso',
      subTitle1Duration: 'duracion_curso',
      description: 'tipo_curso',
    },
  },
  courses: {
    translation: 'courses',
    inline: true,
    bbddKey: {
      main: 'cursos',
      startDate: 'fechaFin',
      subTitle1: 'curso',
      subTitle1Description: 'duracion',
      description: 'detalle',
    },
  },
  languages: {
    translation: 'languages',
    bbddKey: {
      main: 'idiomas',
      language: 'idioma',
      level: 'nivel',
    },
  },
  civilWorksDetails: {
    civilWorksDetails: 'civilWork',
    bbddKey: {
      startDate: 'fechaInicio',
      endDate: 'fechaFin',
      address: 'direccion',
      addressCountry: 'pais',
      addressProvince: 'provincia',
      addressStreetType: 'siglaVia',
      adressStreetName: 'nomVia',
      adressStreetNumber: 'numVia',
      zone: 'zona',
      zoneCode: 'centro',
      zoneCenter: 'codigo',
      description: 'descripcionFIO',
    },
  },
  civilWorkCertificates: {
    civilWorksDetails: 'civilWork',
    bbddKey: {
      code: '',
      codeName: '',
      codeDescription: '',
      language: '',
    },
  },
  classification: {
    title: 'classification',
    bbddKey: {
      main: 'clasificacionGrupos',
      groupTitle1: 'codGrupo',
      groupTitle2: 'nomGrupo',
      amountWithTaxes: 'importeConImpuestos',
      amountWithoutTaxes: 'importeSinImpuestos',
      subGroupTitle1: 'codSubgrupo',
      subGroupTitle2: 'nomSubgrupo',
    },
  },
  types: {
    title: 'types',
    bbddKey: {
      main: 'tipoSubtipoGrupos',
      groupTitle1: 'codTipoDeObra',
      groupTitle2: 'nomTipoDeObra',
      amountWithTaxes: null,
      amountWithoutTaxes: null,
      subGroupTitle1: 'codSubtipoDeObra',
      subGroupTitle2: 'nomSubtipoDeObra',
    },
  },
  process: {
    title: 'process',
    bbddKey: {
      main: 'procesos',
      code: 'codProceso',
      description: 'nomProceso',
    },
  },
  units: {
    title: 'units',
    bbddKey: {
      main: 'unidades',
      code: 'codigo',
      description: 'nombre',
      descriptionCode: 'nomUnidad',
      amount: 'volumen',
    },
  },
  magnitudes: {
    title: 'magnitudes',
    bbddKey: {
      main: 'magnitudes',
      code: 'magnitud',
      description: 'nombre',
      descriptionCode: 'nomMagnitud',
      amount: 'volumen',
    },
  },
  civilWorkscertificates: {
    title: 'certificates',
    bbddKey: {
      main: 'certificados',
      code: 'numCertificado',
      type: 'nomTipo',
      description: 'nomCertificado',
      language: 'idioma',
    },
  },
};

export default DETAILED_INFO_MODAL_VARIABLES;
