export const personDetailsStyle = {
  sx: {
    personDetailsInfoContainer: {
      display: 'flex',
      paddingLeft: '12px',
    },
    personDetailsInfoColumn: {
      width: '50%',
    },
  },
};
