import {
  Modal,
  Grid,
} from '@mui/material';

import { FilterWrapperTitle } from '../FilterWrappersComponents/FilterWrapperTitle/FilterWrapperTitle';

import { ObjectType } from '../../../../../../types/objectType';
import { filterDialogWrapperStyle } from './filterDialogWrapperStyle';

export const FilterDialogWrapper = (
  {
    children,
    filterType,
    open,
    handleClose,
    customStyle,
  }: ObjectType,
) => {
  const { sx } = filterDialogWrapperStyle;
  const style = { ...sx, ...customStyle };
  return (<Modal
    open={open}
    onClose={handleClose}
  >
    <Grid
      container
      sx={style}
    >
      <FilterWrapperTitle
        filterType={filterType}
        handleClose={handleClose}
      />
      {children}
    </ Grid>
  </Modal>);
};
