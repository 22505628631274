import {
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ObjectType } from '../../../../../../../../../../types/objectType';
import { getFormattedDate } from '../../../../../../../../../../helpers/stringHelpers';
import { getOneDecimalPlaceNumber } from '../../../../../../../../../../helpers/numberHelpers';

import { DetailedInfoContainer } from '../../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyTitle } from '../../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { ModalBodyInfo } from '../../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';
import { DetailBox } from '../../../../ModalComponent/ModalBody/DetailBoxes/DetailBox/DetailBox';
import { DetailBoxOneColumn } from '../../../../ModalComponent/ModalBody/DetailBoxes/DetailBoxOneColumn/DetailBoxOneColumn';
import { namespaces } from '../../../../../../../../../../i18n/i18n.constants';

import { personDetailsStyle } from './personDetailsStyle';

export const PersonalDetails = (
  {
    modalDetailedInfo,
  }: ObjectType,
) => {
  const TRADUCTION_BASE_PATH = 'detailedInfoModal.workerDetailedInfo.personalDetails.';
  const { sx } = personDetailsStyle;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const {
    experiencia,
    experienciaAcciona,
    fechaEntradaAcciona,
    fechaSalidaAcciona,
    centro,
    fechaNacimiento,
    paisNacimiento,
    nacionalidad,
    localizacion,
    supervisory,
    especialidades,
  } = modalDetailedInfo;

  const getExperienceText = (experience: number) => {
    if (!experience || typeof (experience) !== 'number') return null;
    const oneDecimalExperience = getOneDecimalPlaceNumber(experience).toLocaleString('de-DE');
    if (oneDecimalExperience === '1') {
      return `${oneDecimalExperience} ${t(`${TRADUCTION_BASE_PATH}experienceDescriptionYear`)}`;
    }
    return `${oneDecimalExperience} ${t(`${TRADUCTION_BASE_PATH}experienceDescriptionYears`)}`;
  };

  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t(`${TRADUCTION_BASE_PATH}title`)} />
      <ModalBodyInfo>
        <Box
          sx={sx.personDetailsInfoColumn}
        >
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}workExperience`)} value={getExperienceText(experiencia)} />
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}accionaWorkExperience`)} value={getExperienceText(experienciaAcciona)} />
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}accionaStartDate`)} value={getFormattedDate(fechaEntradaAcciona)} />
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}accionaEndDate`)} value={getFormattedDate(fechaSalidaAcciona)} />
        </Box>
        <Box
          sx={sx.personDetailsInfoColumn}
        >
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}birthDate`)} value={getFormattedDate(fechaNacimiento)} />
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}birthCountry`)} value={paisNacimiento} />
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}nationality`)} value={nacionalidad} />
          <DetailBox title={t(`${TRADUCTION_BASE_PATH}location`)} value={localizacion} />
        </Box>
        <DetailBoxOneColumn title={t(`${TRADUCTION_BASE_PATH}workerCostCenter`)} value={centro} />
        <DetailBoxOneColumn title={t(`${TRADUCTION_BASE_PATH}supervisory`)} value={supervisory} />
        <DetailBoxOneColumn title={t(`${TRADUCTION_BASE_PATH}specialities`)} value={especialidades} />
      </ModalBodyInfo>
    </ DetailedInfoContainer>
  );
};
