import { CombinedFilter } from '../../types/filterConfig';

interface CombinedFilterObject {
  [key: string]: CombinedFilter;
}

const COMBINED_FILTERS: CombinedFilterObject = {
  workersSpecialities: {
    name: 'workersSpecialities',
    queryParams: 'especialidad:|nivel:',
  },
  workersComplementaryTraining: {
    name: 'workersComplementaryTraining',
    queryParams: 'curso:|duracion:',
  },
};

export default COMBINED_FILTERS;
