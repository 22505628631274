import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Box,
} from '@mui/material';

import ExportIcon from '@mui/icons-material/FileDownload';

import { useTranslation } from 'react-i18next';

import { namespaces } from '../../../../../../i18n/i18n.constants';
import { ObjectType } from '../../../../../../types/objectType';

import { infiniteScrollListFilterStyle } from './infiniteScrollListFilterStyle';

import { CustomButton } from '../../../../../Common/CustomButton/CustomButton';

import downloadAPI from '../../../../../../services/downloadAPI';
import { setMessageError } from '../../../../../../redux/modules/errorMessages';
import { sendDownloadEventAnalytics } from '../../../../../../helpers/analyticsHelpers';

export const InfiniteScrollListFilter = (
  {
    totalResults,
    listType,
  }: ObjectType,
) => {
  const [isLoadingExcel, setIsloadingExcel] = useState(false);
  const { filters } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const dispatch = useDispatch();

  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const TRANSLATION_PATCH = 'infiniteScrollList.infiniteScrollListFilter.';
  const { sx } = infiniteScrollListFilterStyle;

  const toggleLoading = (): void => setIsloadingExcel((prevState: boolean) => !prevState);

  const handleDownload = async () => {
    toggleLoading();
    const result = await downloadAPI.downloadExcelService(listType, filters, 'xlsx');
    if (
      result
      && result.statusCode
      && result.statusCode !== 200
    ) {
      dispatch(setMessageError(t(`${TRANSLATION_PATCH}button.loadingDownloadError`)));
    }
    toggleLoading();
  };

  const getResultsText = () => {
    if (totalResults && totalResults === 1) {
      return `${totalResults} ${t(`${TRANSLATION_PATCH}result`)}`;
    }
    if (totalResults && totalResults > 1) {
      return `${totalResults} ${t(`${TRANSLATION_PATCH}results`)}`;
    }
    return null;
  };

  return (
    <Grid
      container
      sx={sx.container}
    >
      <Box>
        <Typography
          ml={1}
          variant='body2'
          color='text.secondary'
          style={{ marginTop: '0.2rem' }}
        >
          {getResultsText()}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            paddingTop: '0.4rem',
          }}
        >
          {
            totalResults && totalResults >= 1
              ? <CustomButton
                name={t(`${TRANSLATION_PATCH}button.exportExcel`)}
                handleClick={() => {
                  handleDownload();
                  if (listType === 'civilWorks') sendDownloadEventAnalytics('Proyect export', '');
                  if (listType === 'workers') sendDownloadEventAnalytics('CVs export', '');
                }}
                size={'small'}
                customMessage={t(`${TRANSLATION_PATCH}button.loadingDownload`)}
                style={'secondary'}
                disabled={isLoadingExcel}
                icon={<ExportIcon />
                }
              /> : ''
          }
        </Box>
      </Box>
    </Grid>);
};
