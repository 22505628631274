import {} from 'react';
import { ModalBody } from '../../../ModalComponent/ModalBody/ModalBody';
import { PersonalDetails } from './PersonalDetails/PersonalDetails';
import { EducationWorkExperience } from './EducationWorkExperience/EducationWorkExperience';
import { LanguagesComponent } from './LanguagesComponent/LanguagesComponent';

import { DETAILED_INFO_MODAL_VARIABLES } from '../../../../../../../../../config';
import { ObjectType } from '../../../../../../../../../types/objectType';

export const CVInfo = (
  {
    modalDetailedInfo,
  }: ObjectType,
) => (
  <ModalBody>
    <PersonalDetails modalDetailedInfo={modalDetailedInfo} />
    <EducationWorkExperience
      modalDetailedInfo={modalDetailedInfo}
      type={DETAILED_INFO_MODAL_VARIABLES.education}
    />
    <EducationWorkExperience
      modalDetailedInfo={modalDetailedInfo}
      type={DETAILED_INFO_MODAL_VARIABLES.workExperience}
    />
    <EducationWorkExperience
      modalDetailedInfo={modalDetailedInfo}
      type={DETAILED_INFO_MODAL_VARIABLES.certificates}
    />
    <EducationWorkExperience
      modalDetailedInfo={modalDetailedInfo}
      type={DETAILED_INFO_MODAL_VARIABLES.formations}
    />
    <EducationWorkExperience
      modalDetailedInfo={modalDetailedInfo}
      type={DETAILED_INFO_MODAL_VARIABLES.courses}
    />
    <LanguagesComponent
      modalDetailedInfo={modalDetailedInfo}
      type={DETAILED_INFO_MODAL_VARIABLES.languages}
    />
  </ModalBody>);
