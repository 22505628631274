import {
  Alert,
  AlertTitle,
  Snackbar,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAlert } from '../../../../redux/modules/errorMessages';
import { ObjectType } from '../../../../types/objectType';

export const AlertMessages = () => {
  const { open, message, messageType } = useSelector((store: ObjectType) => store.errorMessages);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(deleteAlert());
  };

  return (
    <Stack spacing={2} sx={{
      position: 'fixed', zIndex: 9999,
    }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={messageType || 'error'}
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: '2.5rem',
            width: '25%',
          }}>
          <AlertTitle>Error</AlertTitle>
          {message || ''}
        </Alert>
      </Snackbar>
    </Stack >
  );
};
