export const detailedInfoModalStyle = {
  sx: {
    height: '80vh',
    maxHeigth: '80vh',
    maxWidth: '60vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: 4,
    margin: '5em auto',
  },
};
