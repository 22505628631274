import React, {
  useState,
  useCallback,
} from 'react';

import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';

import { CustomPopper } from './CustomPopper/CustomPopper';
import { CustomNoElevationPaper } from './CustomPaper/CustomNoElevationPaper';
import { CustomElevationPaper } from './CustomPaper/CustomElevationPaper';
import { AutocompleteList } from './AutocompleteList/AutocompleteList';
import { AutocompleteTextField } from './AutocompleteTextField/AutocompleteTextField';

import { toggleIsError } from '../../../../../../../redux/modules/civilWorkWorkerList';

import { ObjectType } from '../../../../../../../types/objectType';
import { AUTOCOMPLETE_MIN_CHARACTER_SEARCH } from '../../../../../../../config/index';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import workWorkersAPI from '../../../../../../../services/worksWorkersAPI';

export const CustomAutocomplete = (
  {
    filterType,
    tags,
    handleChangeSelected,
    debounceSelectedInfo,
    required,
  }: ObjectType,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const NO_RESULTS = [{ name: `${t('input.noResults')}` }];
  const minCharaters = AUTOCOMPLETE_MIN_CHARACTER_SEARCH[filterType] || 3;
  const [options, setOptions] = useState<Array<ObjectType>>([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const inputRequest = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= minCharaters) {
      try {
        const response = await workWorkersAPI.autocompleteServiceWorksWorkers(
          filterType,
          e.target.value,
        );
        const data = await response.json();
        if (data.status === 200) {
          const { results } = data;
          return setOptions(results && results.length > 0 ? results : NO_RESULTS);
        }
        dispatch(toggleIsError(data.invalidToken));
      } catch (error) {
        dispatch(toggleIsError());
      }
    }
    return setOptions([]);
  };

  const debounceInputHandler = useCallback(
    debounce(inputRequest, 500),
    [],
  );

  return (
    <Autocomplete
      size="medium"
      multiple
      freeSolo={true}
      open={openModal}
      renderTags={() => null}
      disableCloseOnSelect
      disableClearable
      data-testid={t(`filters.${filterType}`)}
      id={t(`filters.${filterType}`)}
      inputValue={textFieldValue}
      options={options}
      getOptionLabel={(option) => (option as { name: string }).name}
      isOptionEqualToValue={(option, valueToEqual) => option.name === valueToEqual.name}
      filterOptions={(option) => option}
      PopperComponent={CustomPopper}
      getOptionDisabled={
        (option: ObjectType) => ((option.name === t('input.noResults')))
      }
      style={
        {
          padding: '0.75rem',
        }
      }
      value={tags}
      onChange={(e, newFilter) => {
        const filterInfoToDispatch = handleChangeSelected(e, newFilter);
        debounceSelectedInfo(filterInfoToDispatch);
      }}
      onBlur={() => setOpenModal(false)}
      onFocus={() => setOpenModal(true)}
      PaperComponent={(options.length > 0) ? CustomElevationPaper : CustomNoElevationPaper}
      renderOption={(props, option, { selected }) => (
        <AutocompleteList
          {...props}
          props={props}
          option={option}
          selected={selected}
        />
      )}
      renderInput={(params) => (
        <AutocompleteTextField
          filterType={filterType}
          params={params}
          debounceInputHandler={debounceInputHandler}
          textFieldValue={textFieldValue}
          setTextFieldValue={setTextFieldValue}
          setOptions={setOptions}
          required={required}
        />
      )}
    />
  );
};
