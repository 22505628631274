import {
  Grid,
} from '@mui/material';
import { ObjectType } from '../../../../../../../../types/objectType';
import modalBodyStyle from './modalBodyStyle';

export const ModalBody = ({ children }: ObjectType) => {
  const classes = modalBodyStyle();
  return (<Grid
    container
    style={
      {
        minHeight: 'calc(80vh - 250px)',
        maxHeight: 'calc(80vh - 250px)',
      }
    }
    className={classes.infiniteScroll}
  >
    {children}
  </Grid>);
};
