import { ObjectType } from '../../types/objectType';

const SELECT_OPTIONS_BY_FILTER: ObjectType = {
  civilWorksCertificate: {
    type: 'static',
    values: [
      { translateValue: 'all', value: '', noFilterValue: true },
      { translateValue: 'certificate', selectValue: 'true' },
      { translateValue: 'noCertificate', selectValue: 'false' },
    ],
  },
  workersZone: {
    type: 'autocomplete',
    values: [],
  },
  workersActive: {
    type: 'static',
    values: [
      { translateValue: 'all', value: '', noFilterValue: true },
      { translateValue: 'active', selectValue: 'true' },
      { translateValue: 'noActive', selectValue: 'false' },
    ],
  },
  workersSpecialitiesExperience: {
    type: 'static',
    values: [
      { translateValue: 'zero', selectValue: '0' },
      { translateValue: 'three', selectValue: '3' },
      { translateValue: 'five', selectValue: '5' },
      { translateValue: 'ten', selectValue: '10' },
    ],
  },
  workersCvStatus: {
    type: 'static',
    values: [
      { translateValue: 'verified', selectValue: 'verde', color: '#53ad5b' },
      { translateValue: 'needsRevision', selectValue: 'naranja', color: '#fe982e' },
      { translateValue: 'invalid', selectValue: 'rojo', color: '#ed5958' },
      { translateValue: 'noData', selectValue: 'gris', color: '#B1B1B1' },
    ],
  },
  workersCvStatusReason: {
    type: 'static',
    values: [
      { translateValue: 'centerChange', selectValue: 'cambio_centro', selected: false },
      { translateValue: 'supervisoryChange', selectValue: 'cambio_supervisory', selected: false },
      { translateValue: 'positionChange', selectValue: 'cambio_positions', selected: false },
      { translateValue: 'twoYearsNoUpdate', selectValue: 'cambio_ano_actualizacion', selected: false },
      { translateValue: 'jobhistoryDelete', selectValue: 'cambio_jobhistory_delete', selected: false },
      { translateValue: 'jobhistoryCode', selectValue: 'cambio_jobhistory_codobra', selected: false },
      { translateValue: 'jobhistoryNoCode', selectValue: 'cambio_jobhistory_sincodobra', selected: false },
    ],
  },
};

export default SELECT_OPTIONS_BY_FILTER;
