import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  titleContainer: {
    paddingRight: '0.3rem',
    paddingLeft: '0.3rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(33, 33, 33, 0.05)',
    },
  },
  title: {
    minHeight: '54px',
  },
  titleBadgeTypographyContainer: {
    width: '88%',
    marginLeft: '0.25rem',
  },
  titleTypography: {
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    padding: 0,
    maxWidth: '85%',
    marginLeft: '0.5rem',
  },
  typographyWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
