/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/react-in-jsx-scope */
import Keycloak from 'keycloak-js';
import { createContext, useEffect, useState } from 'react';

/**
 * KeycloakInitOptions configures the Keycloak client.
 */
const keycloakInitOptions = {
  // Configure that Keycloak will check if a user is already authenticated
  // 7(when opening the app or reloading the page). If not authenticated
  // the user will be send to the login form. If already authenticated
  // the webapp will open.
  // onLoad: 'login-required',
  redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI,
};

// Create the Keycloak client instance
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID as string,
});
let isAuthenticatedResponse = false;
/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
interface AuthContextValues {
  /**
   * Whether or not a user is currently authenticated
   */
  isAuthenticated: boolean;
  /**
   * The name of the authenticated user
   */
  username: string;
  /**
   * The email of the authenticated user
   */
  email: string;
  /**
   * Function to initiate the logout
   */
  logout: () => void;
}

/**
 * Default values for the {@link AuthContext}
 */
const AuthContextData: AuthContextValues = {
  isAuthenticated: false,
  username: '',
  email: '',
  logout: () => { },
};

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext<AuthContextValues>(
  AuthContextData,
);

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthContextProvider = (props: AuthContextProviderProps) => {
  // Create the local state in which we will keep track if a user is authenticated
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  // Local state that will contain the users name once it is loaded
  const [username, setUsername] = useState<any>(localStorage.getItem('username'));
  // Local state that will contain the users email once it is loaded
  const [email, setEmail] = useState<any>(localStorage.getItem('email'));

  // Effect used to initialize the Keycloak client. It has no dependencies
  // so it is only rendered when the app is (re-)loaded.
  useEffect(() => {
    const tokenJupiter = localStorage.getItem('tokenJupiter');
    if (!tokenJupiter) {
      keycloak.login({
        scope: 'groups',
      });
    } else {
      setAuthenticated(true);
    }
  }, []);

  // This effect loads the users profile in order to extract the username
  useEffect(() => {
    /**
     * Load the profile for of the user from Keycloak
     */
    async function loadProfile() {
      try {
        const profile: any = await keycloak.loadUserProfile();
        let userName = '';
        const eMail = profile.email || '';
        if (profile.firstName) {
          userName = profile.lastName ? `${profile.firstName} ${profile.lastName}` : profile.firstName;
        } else if (profile.username) {
          userName = profile.username;
        }
        setUsername(userName);
        setEmail(eMail);
        if (!localStorage.getItem('username')) {
          localStorage.setItem('username', userName);
        }
        if (!localStorage.getItem('email')) {
          localStorage.setItem('email', eMail);
        }
        if (!keycloak.tokenParsed) window.location.href = '/error-permisos';
        return window.location.href;
      } catch {
        console.log('error trying to load the users profile');
        return null;
      }
    }

    // Only load the profile if a user is authenticated
    if (isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated]);

  /**
   * Initiate the logout
   */
  const logout = async () => {
    localStorage.removeItem('tokenJupiter');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    keycloak.logout(); // {redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI});
    isAuthenticatedResponse = false;
  };

  // Setup the context provider
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        username,
        email,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const refreshToken = async () => {
  // if the refresh token is not available because the page refreshed entirely
  if (!keycloak.refreshToken && localStorage.getItem('refreshToken')) {
    keycloak.refreshToken = localStorage.getItem('refreshToken') as string;
  }
  // update the token always with -1
  const refreshed = await keycloak.updateToken(-1);
  if (refreshed) {
    localStorage.setItem('tokenJupiter', (keycloak.token as string));
    localStorage.setItem('refreshToken', (keycloak.refreshToken as string));
  }
  return keycloak.token;
};

const initAuthContextProvider = async () => {
  try {
    await keycloak.init(
      keycloakInitOptions,
    );
    if (keycloak.token && keycloak.isTokenExpired()) {
      await refreshToken();
    } else if (keycloak.token) {
      localStorage.setItem('tokenJupiter', (keycloak.token as string));
      localStorage.setItem('refreshToken', (keycloak.refreshToken as string));
    }
    isAuthenticatedResponse = true;
  } catch (err) {
    console.error(err);
    isAuthenticatedResponse = false;
  }
};

export { AuthContextProvider, initAuthContextProvider, refreshToken };
