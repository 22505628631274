import { ObjectType } from '../types/objectType';

/**
 * Api Params Url filters
 */
const URL_FILTERS_PARAMS_KEYS: ObjectType = {
  workersDiploma: '&p_titulacion=',
  civilWorksBeginningDate: {
    min: '&o_fechaInicioMin=',
    max: '&o_fechaInicioMax=',
  },
  civilWorksEndDate: {
    min: '&o_fechaFinMin=',
    max: '&o_fechaFinMax=',
  },
  workersDegreeYear: {
    min: '&p_fechaTitulacionMin=',
    max: '&p_fechaTitulacionMax=',
  },
};

export default URL_FILTERS_PARAMS_KEYS;
