import { makeStyles } from '@material-ui/styles';
import { red } from '@mui/material/colors';

export default makeStyles({
  root: {
    '&.MuiButton-outlined': {
      marginRight: '0.25rem',
      border: `2px solid ${('#ff0100')}`,
      color: '#ff0100',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        border: `2px solid ${red[700]}`,
      },
    },
    '&.MuiButton-contained': {
      backgroundColor: '#ff0100',
      textTransform: 'none',
      borderRadius: 50,
      '&:hover': {
        backgroundColor: red[700],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: '#ff0100',
        },
      },
    },
  },
});
