import { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { useTranslation } from 'react-i18next';

import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import Circle from '@mui/icons-material/Circle';
import { CustomButton } from '../../../../../../../../Common/CustomButton/CustomButton';
import { CardAvatar } from '../../../../InfiniteScrollListsCards/CivilWorksWorkerCards/CardAvatar/CardAvatar';

import { ObjectType } from '../../../../../../../../../types/objectType';
import { namespaces } from '../../../../../../../../../i18n/i18n.constants';
import {
  LIST_TYPES,
  DOWNLOAD_FILE_TYPES,
} from '../../../../../../../../../config';

import {
  getEuropeanNumberFormat,
  getFormattedDate,
} from '../../../../../../../../../helpers/stringHelpers';

import { workerHeaderStyle } from './headerInfoStyle';

import downloadAPI from '../../../../../../../../../services/downloadAPI';
import { setMessageError } from '../../../../../../../../../redux/modules/errorMessages';
import { sendDownloadEventAnalytics } from '../../../../../../../../../helpers/analyticsHelpers';
import { getColor, getStatus } from '../../../../../../../../utils/cvUtils';

export const HeaderInfo = (
  {
    listType,
    modalDetailedInfo,
    handleClose,
  }: ObjectType,
) => {
  const [isLoadingWorkerVersion, setIsloadingWorkerVersion] = useState(false);
  const [isLoadingDoc, setIsloadingDoc] = useState(false);
  const [isLoadingPDF, setIsloadingPDF] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const { sx } = workerHeaderStyle;
  const {
    nombre,
    clientes,
    importe,
    posicion,
    ultimaModificacion,
    id,
  } = modalDetailedInfo;
  let parsedAmount;
  if (listType === LIST_TYPES.civilWorks) {
    parsedAmount = getEuropeanNumberFormat(importe) || null;
  }

  const toggleLoading = (type: string): void => {
    const param = (prevState: boolean) => !prevState;
    if (DOWNLOAD_FILE_TYPES.docxEmp === type) {
      return setIsloadingWorkerVersion(param);
    }
    if (DOWNLOAD_FILE_TYPES.docx === type) {
      return setIsloadingDoc(param);
    }
    return setIsloadingPDF(param);
  };

  const handleDownload = async (
    type: string,
  ) => {
    toggleLoading(type);
    const result = await downloadAPI.downloadService(id, nombre, type);
    if (
      result
      && result?.statusCode !== 200
    ) {
      dispatch(setMessageError(t(`button.loadingDownloadError.${type}`)));
    }
    toggleLoading(type);
  };

  return (
    <>
      <Grid
        item
        sx={
          listType === LIST_TYPES.civilWorks
            ? sx.rigthItemCivilWorks
            : sx.rigthItem
        }
      >
        {
          <CardAvatar item={modalDetailedInfo} listType={listType} sx={sx.avatar} />
        }
        <Box
          sx={sx.rigthItemBox}
        >
          <Tooltip
            enterDelay={1000}
            title={
              <Typography
                style={{ padding: '0.625rem' }}
              >
                {nombre}
              </Typography>
            }
            followCursor>
            <Typography
              variant="h5"
              sx={sx.rigthItemBoxTitle}
              style={{
                fontWeight: 600,
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {nombre}
            </Typography>
          </Tooltip>
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {posicion || clientes}
          </Typography>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            marginTop: '5px',
          }}>
            {
              listType === LIST_TYPES.workers && <>
                <Circle
                  style={{
                    color: getColor(modalDetailedInfo.estado),
                    fontSize: '15px',
                    marginRight: '5px',
                  }} /><span>
                  {t(`detailedInfoModal.workerDetailedInfo.statusChange.${getStatus(modalDetailedInfo.estado)}`)}
                </span>
              </>
            }
          </div>
          {
            (listType === LIST_TYPES.civilWorks)
            && <Typography
              variant="body1"
              style={
                {
                  fontSize: '14px',
                }
              }
            >
              {parsedAmount}
            </Typography>
          }
        </Box>
      </Grid>
      <Grid
        item
        sx={
          listType === LIST_TYPES.civilWorks
            ? sx.leftItemCivilWorks
            : sx.leftItem
        }
      >
        <Box
          sx={sx.leftItemBox}
        >
          {
            ultimaModificacion
            && (<Typography
              variant="body2"
              color="text.secondary"
              sx={sx.leftItemTypography}
              mb={1}
              mr={1}
            >
              {t('detailedInfoModal.lastModification')} {getFormattedDate(ultimaModificacion)}
            </Typography>)
          }
          <CancelIcon
            fontSize='small'
            sx={sx.lefItemCancelIcon}
            onClick={() => handleClose()}
          />
        </Box>
        {
          (listType !== LIST_TYPES.civilWorks)
          && <>
            <Box>
              <Box>
                <CustomButton
                  disabled={isLoadingWorkerVersion}
                  size={'small'}
                  customMessage={t('button.loadingDownloadResumeWorkerVersion')}
                  name={t('button.downloadResumeWorkerVersion')}
                  handleClick={() => {
                    handleDownload(DOWNLOAD_FILE_TYPES.docxEmp);
                    sendDownloadEventAnalytics('CV', id);
                  }}
                />
                <CustomButton
                  disabled={isLoadingDoc}
                  size={'small'}
                  customMessage={t('button.loadingDownloadDocResume')}
                  name={t('button.downloadDocResume')}
                  handleClick={() => {
                    handleDownload(DOWNLOAD_FILE_TYPES.docx);
                    sendDownloadEventAnalytics('CV', id);
                  }}
                />
                <CustomButton
                  disabled={isLoadingPDF}
                  size={'small'}
                  customMessage={t('button.loadingDownloadPDFResume')}
                  name={t('button.downloadPDFResume')}
                  handleClick={() => {
                    handleDownload(DOWNLOAD_FILE_TYPES.pdf);
                    sendDownloadEventAnalytics('CV', id);
                  }}
                />
              </Box>
            </Box>
          </>
        }
      </Grid>
    </>);
};
