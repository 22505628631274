import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import debounce from 'lodash.debounce';
import { useSelector, useDispatch } from 'react-redux';

import { CustomTextField } from './CustomTextField/CustomTextField';
import { FilterCollapsableWrapper } from '../../Wrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';

import { ObjectType } from '../../../../../../types/objectType';
import { StringsObjectType } from '../../../../../../types/stringObjectType';
import { getUrlFilterParamsToStore } from '../../../../../../helpers/urlHelpers';
import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';

import { LIST_TYPES } from '../../../../../../config';
import { event } from '../../../../../../config/analyticsConfig';

export const NumberTextField = ({ filterType }: StringsObjectType) => {
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const dispatch = useDispatch();
  const [tags, setTags] = useState<Array<ObjectType>>([]);
  const [textFieldValue, setTextFieldValue] = useState('');

  useEffect(() => {
    const tagsToSet = initialTags[filterType];
    if (Array.isArray(tagsToSet)) {
      setTags(tagsToSet);
      setTextFieldValue(tagsToSet.length > 0 ? tagsToSet[0].name : '');
    }
  }, [initialTags]);

  const handleTextUnselected = (name: string, tagsToUpdate: ObjectType[]) => {
    if (tagsToUpdate.length > 0) {
      dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
      const tagsFiltered = tags.filter((tag: ObjectType) => tag.name !== name);
      const filtersParsed = getUrlFilterParamsToStore(tagsFiltered, filterType, event.DELETE);
      const filterToUpdate = {
        [filterType]: filtersParsed,
      };
      dispatch(updateListsByFilter(filterToUpdate, { [filterType]: tags }));
      setTags(tagsFiltered);
    }
    setTextFieldValue('');
  };

  const debounceTextUnselected = useCallback(
    debounce(handleTextUnselected, 100),
    [],
  );

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        tags={tags}
        setTags={setTags}
      >
        <CustomTextField
          filterType={filterType}
          tags={tags}
          textFieldValue={textFieldValue}
          setTextFieldValue={setTextFieldValue}
          debounceTextUnselected={debounceTextUnselected}
        />
      </FilterCollapsableWrapper>
    </>
  );
};
