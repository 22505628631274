export const infiniteScrollListFilterStyle = {
  sx: {
    container: {
      display: 'flex',
      height: '8%',
      minHeight: '8%',
      maxHeight: '8%',
      alignItems: 'center',
      padding: '0.25rem',
      justifyContent: 'space-between',
    },
    errorMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '1.5rem',
      maxHeight: '1.5rem',
    },
    errorMessageTypography: {
      fontSize: '1.25rem',
      color: '#ff0100',
    },
    errorMessageIcon: {
      color: '#ff0100',
    },
    leftItemBox: {
      display: 'flex',
      height: '2.6rem',
      minHeight: '54px',
      maxHeight: '54px',
      marginTop: '0.2rem',
    },
  },
};
