import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import PaymentsIcon from '@mui/icons-material/Payments';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ConstructionIcon from '@mui/icons-material/Construction';
import HandymanIcon from '@mui/icons-material/Handyman';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import RoomIcon from '@mui/icons-material/Room';
import StarIcon from '@mui/icons-material/Star';
import TrafficIcon from '@mui/icons-material/Traffic';

import { FILTER_TYPES as FT } from '../../../../../../../../config/index';
import { StringsObjectType } from '../../../../../../../../types/stringObjectType';

import titleIconStyle from './titleIconStyle';

export const TitleIcon = ({ filterType }: StringsObjectType) => {
  const classes = titleIconStyle();

  const getIconComponent = (type: string) => {
    switch (type) {
      case FT.workersCvStatus:
        return TrafficIcon;
      case FT.workersFullName:
        return PersonIcon;
      case FT.workersDiploma:
      case FT.workersDegreeYear:
      case FT.workersDegreeLevel:
      case FT.workersComplementaryTraining:
        return SchoolIcon;
      case FT.workersCertificate:
      case FT.workersPositions:
      case FT.workersCurrentPosition:
      case FT.workersExperience:
        return WorkIcon;
      case FT.workersCostCenter:
        return PaymentsIcon;
      case FT.workersSupervisory:
        return GroupIcon;
      case FT.workersExperienceDescription:
        return DescriptionIcon;
      case FT.workersZone:
      case FT.civilWorksZone:
        return RoomIcon;
      case FT.civilWorksBeginningDate:
        return TodayIcon;
      case FT.civilWorksEndDate:
        return EventIcon;
      case FT.civilWorksByName:
        return CorporateFareIcon;
      case FT.civilWorksClient:
        return BusinessCenterIcon;
      case FT.civilWorksType:
        return ConstructionIcon;
      case FT.civilWorksSubtype:
      case FT.civilWorksContructiveProcesses:
        return HandymanIcon;
      case FT.civilWorksMinimunAmount:
        return MonetizationOnIcon;
      case FT.civilWorksClassification:
        return AssignmentIcon;
      case FT.civilWorksCertificate:
      case FT.workersSpecialities:
        return WorkspacePremiumIcon;
      case FT.workersActive:
        return StarIcon;
      default:
        return null;
    }
  };

  const IconComponent = getIconComponent(filterType);

  return IconComponent ? <IconComponent className={classes.titleIcon} /> : null;
};
