import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  infiniteScrollListContainer: {
    heigth: '90%',
  },
  infiniteScroll: {
    overflow: 'scroll',
    overflowX: 'hidden',
    maxWidth: '33vw',
    minHeight: '93%',
    maxHeight: '93%',
    '&::-webkit-scrollbar': {
      marginLeft: '0.25rem',
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ff0100',
      borderRadius: '30px',
    },
  },
});
