/* eslint-disable no-nested-ternary */
import { useEffect, memo } from 'react';

import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getLists, toggleIsLoading } from '../../../../redux/modules/civilWorkWorkerList';

import { ObjectType } from '../../../../types/objectType';
import { LIST_TYPES } from '../../../../config';

import InfiniteScrollList from './InfiniteScrollList/InfiniteScrollList';
import { LoadingComponent } from '../../../Common/LoadingComponent/LoadingComponent';
import { ErrorComponent } from './InfiniteScrollList/ErrorComponent/ErrorComponent';

import civilWorksWorkerListStyle from './CivilWorksWorkerListStyle';

export const CivilWorksWorkerList = () => {
  const civilWorksPersonLists = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const dispatch = useDispatch();
  const classes = civilWorksWorkerListStyle();

  const {
    isLoading,
    isUpdateLoading,
    isError,
    civilWorks,
    workers,
  } = civilWorksPersonLists;

  useEffect(() => {
    const fetchList = () => {
      if (
        civilWorks.results.length !== 0
        || workers.results.length !== 0
      ) {
        return;
      }
      dispatch(toggleIsLoading());
      dispatch(getLists());
    };
    fetchList();
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      // spacing={1}
      className={classes.container}
    >
      {
        isError
          ? <ErrorComponent />
          : (
            <>
              {
                isLoading
                  ? <LoadingComponent />
                  : (
                    <>
                      {
                        isUpdateLoading && <LoadingComponent />
                      }
                      <InfiniteScrollList listType={LIST_TYPES.workers} />
                      <InfiniteScrollList listType={LIST_TYPES.civilWorks} />
                    </>
                  )
              }
            </>
          )
      }
    </Grid >
  );
};

export default memo(CivilWorksWorkerList);
