import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { ObjectType } from '../../../../../../../../../../types/objectType';

import { DetailedInfoContainer } from '../../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyTitle } from '../../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { ModalBodyInfo } from '../../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';
import { ExperienceDescription } from './ExperienceDescription/ExperienceDescription';
import { NoInfoBox } from '../../../../ModalComponent/ModalBody/NoInfoBox/NoInfoBox';

import { namespaces } from '../../../../../../../../../../i18n/i18n.constants';

interface EducationWorkExperienceProps {
  modalDetailedInfo: ObjectType;
  type: ObjectType;
}

export const EducationWorkExperience = (props: EducationWorkExperienceProps) => {
  const { modalDetailedInfo, type } = props;
  const {
    translation,
    inline,
    bbddKey,
  } = type;
  const experienceInfo = modalDetailedInfo[bbddKey.main];
  const TRADUCTION_BASE_PATH = `detailedInfoModal.workerDetailedInfo.${translation}.`;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t(`${TRADUCTION_BASE_PATH}title`)} />
      <ModalBodyInfo>
        {
          (experienceInfo.length > 0)
            ? experienceInfo.map((experience: ObjectType, index: number) => (
              <ExperienceDescription
                key={uuidv4()}
                inline={inline}
                experience={experience}
                translation={translation}
                bbddKey={bbddKey}
                lastElement={
                  (experienceInfo.length === index + 1)
                  && true
                }
              />
            ))
            : <NoInfoBox
              value={t(`${TRADUCTION_BASE_PATH}noInfoText.main`)}
            />
        }
      </ModalBodyInfo>
    </ DetailedInfoContainer>
  );
};
