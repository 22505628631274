/**
 * API endpoints
 */
const ENDPOINTS = {
  v1: {
    filter: '/v1/filter',
    autocomplete: '/v1/autocomplete',
    employee: '/v1/employee',
  },
};

export default ENDPOINTS;
