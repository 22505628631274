import { useTranslation } from 'react-i18next';

import Circle from '@mui/icons-material/Circle';
import { DetailedInfoContainer } from '../../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyTitle } from '../../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { ModalBodyInfo } from '../../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';

import { namespaces } from '../../../../../../../../../../i18n/i18n.constants';
import changeHistoryStyle from './changeHistoryStyle';
import { getColor, getStatus } from '../../../../../../../../../utils/cvUtils';
import { formatDateFromTimestamp } from '../../../../../../../../../../helpers/stringHelpers';

interface ChangeHistoryProps {
  items: any[];
}

export const ChangeHistory = (props: ChangeHistoryProps) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const styles = changeHistoryStyle();
  const { items } = props;

  const tableHeaders = {
    thStatus: 'color',
    thUser: 'usuario',
    thComments: 'comentario',
    thDate: 'fecha',
  };

  const customStyle = (th: string) => {
    if (th === 'thStatus') return { width: '12em' };
    if (th === 'thUser') return { width: '10em' };
    if (th === 'thDate') return { width: '12em' };
    return {};
  };

  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t('detailedInfoModal.workerDetailedInfo.changeHistory.title')} />
      <ModalBodyInfo>
        <table className={styles.table}>
          <tr>
            {
              Object.keys(tableHeaders).map((th) => (
                <th className={styles.th} style={customStyle(th)}>
                  {t(`detailedInfoModal.workerDetailedInfo.changeHistory.${th}`)}
                </th>
              ))
            }
          </tr>
          {
            items.sort(
              (a: any, b: any) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime(),
            ).map((item) => (
              <tr>
                <td className={styles.td}>
                  <div className={styles.statusTd}>
                    <Circle style={{ color: getColor(item[tableHeaders.thStatus]), width: '20px' }} />
                    <span className={styles.statusSpan}>
                      {t(`detailedInfoModal.workerDetailedInfo.statusChange.${getStatus(item[tableHeaders.thStatus])}`)}
                    </span>
                  </div>
                </td>
                <td className={styles.td}>{item[tableHeaders.thUser]}</td>
                <td className={styles.td}>{item[tableHeaders.thComments]}</td>
                <td className={styles.td}>{
                  formatDateFromTimestamp(item[tableHeaders.thDate])
                }</td>
              </tr>
            ))
          }
        </table>
      </ModalBodyInfo>
    </ DetailedInfoContainer>
  );
};
