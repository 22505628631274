import authHeader from '../redux/authentication/auth-header';
import {
  URL_FILTERS_PARAMS,
} from '../config';
import { ObjectType } from '../types/objectType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const download = (blob: any, name: string) => {
  //  Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  //  Append to html page
  document.body.appendChild(link);
  //  Force download
  link.click();
  //  Clean up and remove the link
  link.parentNode?.removeChild(link);
  return { statusCode: 200 };
};

const downloadService = async (
  id: string,
  nombre: string,
  type = 'pdf',
) => {
  const result = await fetch(
    `${process.env.REACT_APP_EMERGYA_SERVER_API_HOST}/v1/download/${type}/${id}`,
    {
      method: 'GET',
      body: null,
      headers: authHeader(),
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.blob();
      }
      throw new Error(`Error downloading ${type}`);
    })
    .then((blob) => download(blob, `cv-${nombre}.${type === 'docxEmp' ? 'docx' : type}`))
    .catch((e) => e);
  return result;
};

const downloadExcelService = async (
  nombre: string,
  filters: ObjectType,
  type = 'xlsb',
) => {
  const filtersFromReduxStorage = { ...filters };
  // get all results
  filtersFromReduxStorage.limitWorkers = 'all';
  filtersFromReduxStorage.limitCivilWorks = 'all';
  let paramsToAdd = '';
  const urlFilterParams = Object.values(URL_FILTERS_PARAMS);
  Object.keys(filtersFromReduxStorage).forEach((key) => {
    if (
      filtersFromReduxStorage[key]
      && (
        ((typeof (filtersFromReduxStorage[key]) === 'string') && filtersFromReduxStorage[key].length > 0)
        || (typeof (filtersFromReduxStorage[key]) === 'number')
      )
    ) {
      urlFilterParams.forEach((filterParam) => {
        if (filterParam.includes(`{${key}}`)) {
          const textToAdd = filterParam.replace(`{${key}}`, filtersFromReduxStorage[key]);
          paramsToAdd += textToAdd;
        }
      });
    }
  });
  const result = await fetch(
    `${process.env.REACT_APP_EMERGYA_SERVER_API_HOST}/v1/download/excel/${nombre}${paramsToAdd}`,
    {
      method: 'GET',
      body: null,
      headers: authHeader(),
    },
  )
    .then(async (response) => {
      if (response.ok) {
        return response.blob().then((blob) => {
          download(blob, `${nombre}.${type}`);
        }).catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
          return { statusCode: 500, e };
        });
      }
      return response.json()
        .then(
          (jsonError) => ({ statusCode: 500, jsonError }),
        )
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
          return { statusCode: 500, e };
        });
    })
    // .then((blob) => {
    //   console.log(blob);
    //   download(blob, `${nombre}.${type}`);
    // })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      return { statusCode: 500, e };
    });
  return result;
};

export default {
  downloadService,
  downloadExcelService,
};
