import ReactGA4 from 'react-ga4';
import { URL_KEY_PARAMS } from '../config';
import SESSION_ID from '../App/utils/sessionID';
import { eventCategoryFilterNames, type } from '../config/analyticsConfig';

export const sendFilterEventAnalytics = (
  filterType: string,
  eventLabel: string,
  eventValue?: string,
) => {
  ReactGA4.event('filter', {
    event_name: filterType,
    event_value: eventValue,
    param_key: URL_KEY_PARAMS[filterType].slice(2, -1),
    event_label: eventLabel,
    ga_session_id: SESSION_ID,
    type: type(filterType),
    event_category: eventCategoryFilterNames[filterType],
  });
};

export const sendShowDetailsEventAnalytics = (eventCategory: string, code: string) => {
  ReactGA4.event('click_details', {
    ga_session_id: SESSION_ID,
    event_category: eventCategory,
    code,
  });
};

export const sendDownloadEventAnalytics = (eventCategory: string, code: string) => {
  ReactGA4.event('download', {
    ga_session_id: SESSION_ID,
    event_category: eventCategory,
    code,
  });
};

export const sendStatusChangeEventAnalytics = (
  code: string,
  newState: string,
  oldState: string,
) => {
  const getState = (state: string) => {
    if (state === 'verde') return 'verified';
    if (state === 'naranja') return 'needsReview';
    if (state === 'rojo') return 'invalid';
    return 'noData';
  };
  ReactGA4.event('cv_state_change', {
    ga_session_id: SESSION_ID,
    code,
    currentState: getState(newState),
    previousState: getState(oldState),
  });
};
