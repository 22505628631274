import {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { FilterCollapsableWrapper } from '../../Wrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { ObjectType } from '../../../../../../types/objectType';
import { SelectMultiple } from './SelectMultiple/SelectMultiple';

interface DoubleSelectMultipleProps {
  firstSelectorFilter: string;
  secondSelectorFilter: string;
  tooltipMessage?: string;
}

export const DoubleSelectMultiple = (props: DoubleSelectMultipleProps) => {
  const { firstSelectorFilter, secondSelectorFilter, tooltipMessage } = props;
  const [tags, setTags] = useState<Array<ObjectType>>([]);
  const [onlyNeedsRevision, setOnlyNeedsRevision] = useState<boolean>(false);
  const disabled = !onlyNeedsRevision || tags.length === 0;
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);

  useEffect(() => {
    const tagsToSet = initialTags[firstSelectorFilter] || [];
    setTags(tagsToSet);
  }, [initialTags]);

  return (
    <>
      <FilterCollapsableWrapper
        filterType={firstSelectorFilter}
        tags={tags}
        setTags={setTags}
        tagsDisabled
        tooltipMessage={tooltipMessage}
      >
        <SelectMultiple
          filterType={firstSelectorFilter}
          setOnlyNeedsRevision={setOnlyNeedsRevision}
          handleSetTags={setTags}
        />
        <SelectMultiple
          filterType={secondSelectorFilter}
          disabled={disabled}
        />
      </FilterCollapsableWrapper>
    </>
  );
};
