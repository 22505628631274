import { makeStyles } from '@material-ui/styles';

const font = {
  fontfamily: 'Roboto',
  fontWeight: 400,
  fontSize: '14px',
  marginBottom: '7px',
};

export default makeStyles({
  title: {
    ...font,
    color: '#888888',
    whiteSpace: 'nowrap',
  },
  description: {
    ...font,
    color: '#000000',
    marginLeft: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '80%',
  },
});
