import React, {
  useState,
  useCallback,
  KeyboardEvent,
} from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import {
  InputAdornment,
  FormGroup,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  IconButton,
} from '@material-ui/core';

import { ObjectType } from '../../../../../../../types/objectType';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import { getUrlFilterParamsToStore } from '../../../../../../../helpers/urlHelpers';

import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../../redux/modules/civilWorkWorkerList';

import {
  LIST_TYPES,
} from '../../../../../../../config';

import searchableTextFieldStyle from './searchableTextFieldStyle';
import { event } from '../../../../../../../config/analyticsConfig';

export const SearchableTextField = (
  {
    filterType,
    textFieldValue,
    setTextFieldValue,
    debounceTextUnselected,
  }: ObjectType,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const classes = searchableTextFieldStyle();
  const textFieldValueLength = textFieldValue ? textFieldValue.length : 0;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [onFocusValue, setOnFocusValue] = useState(false);

  const textSelectedHandler = (
    e: KeyboardEvent<HTMLDivElement>,
    newValue: ObjectType,
  ) => {
    if (e.key === 'Enter') {
      if (newValue.length === 0) return setErrorMessage(t('input.errorEmptyInput'));
      dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
      const newTag = { name: newValue };
      const filtersParsed = getUrlFilterParamsToStore(
        [{ name: newValue }],
        filterType,
        event.ADD,
      );
      const filterToUpdate = {
        [filterType]: filtersParsed,
      };
      return dispatch(
        updateListsByFilter(
          filterToUpdate,
          { [filterType]: [newTag] },
        ),
      );
    }
    return null;
  };

  const debounceTextSelected = useCallback(
    debounce(textSelectedHandler, 100),
    [],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    setTextFieldValue(e.target.value);
  };

  const handleOnBlur = () => {
    setOnFocusValue(false);
  };

  const handleOnFocus = () => {
    setOnFocusValue(true);
  };

  return (
    <FormGroup
      className={classes.textField}
    >
      <TextField
        id={`SearchableTextField${filterType}`}
        onChange={handleChange}
        value={textFieldValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div onClick={() => debounceTextUnselected(textFieldValue)}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  marginRight: '10px',
                }}>
                {textFieldValueLength > 0 && <ClearIcon className={classes.icon} />}
              </div>
            </InputAdornment>
          ),
        }}
        onKeyPress={(e) => {
          debounceTextSelected(e, textFieldValue);
        }}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        InputLabelProps={{ shrink: false }}
        className={classes.root}
        variant='outlined'
        autoComplete='off'
        placeholder={t(`filtersPlaceHolder.${filterType}`)}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </FormGroup >
  );
};
