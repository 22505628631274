import { makeStyles } from '@material-ui/core';

export default makeStyles({
  body: {
    width: '75%',
  },
  selectorAndComment: {
    display: 'flex',
    flexFlow: 'row',
  },
  button: {
    textAlign: 'right',
  },
});
