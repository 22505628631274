import Popper from '@material-ui/core/Popper';
import customPopperStyles from './customPopperStyle';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomPopper = (props: any) => {
  const classes = customPopperStyles();
  return (
    <Popper
      {...props}
      className={classes.root}
      placement='bottom'
    />);
};
