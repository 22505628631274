import { makeStyles } from '@material-ui/core';

export default makeStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  specialitiesPopup: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
    borderRadius: '2px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    width: '23%',
    maxHeight: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
  },
  titleAndCloseButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 400,
    color: '#333333',
    marginBottom: '20px',
  },
  span: {
    marginRight: '5px',
  },
  specialitiesList: {
    overflowY: 'auto',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      marginLeft: '0.25rem',
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ff0100',
      borderRadius: '30px',
    },
  },
  speciality: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    color: '#666666',
    marginBottom: '20px',
  },
});
