import {
  useState,
  useCallback,
} from 'react';

import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';

import Collapse from '@mui/material/Collapse';
import {
  Divider,
} from '@material-ui/core';

import { getUrlCombinedFilterParamsToStore } from '../../../../../../helpers/urlHelpers';
import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';

import { Tags } from '../Components/Tags/Tags';
import { Title } from '../Components/Title/Title';

import { ObjectType } from '../../../../../../types/objectType';

import {
  COMPLEX_FILTER_TYPES,
  LIST_TYPES,
} from '../../../../../../config';

import filterCollapsableStyle from './combinedFilterCollapsableStyle';
import { event } from '../../../../../../config/analyticsConfig';

interface CombinedFilterCollapsableWrapperProps {
  firstChild: JSX.Element,
  secondChild: JSX.Element,
  filterType: string,
  tags: ObjectType[],
  setTags: any,
  buttonText?: string,
  tagsDisabled?: boolean,
  alwaysOpen?: boolean,
  setAutocompleteTags?: any,
  noDivider?: boolean,
  combinedFilterValue: any,
  tooltipMessage?: string,
}

export const CombinedFilterCollapsableWrapper = (
  props: CombinedFilterCollapsableWrapperProps,
) => {
  const {
    firstChild,
    secondChild,
    filterType,
    tags,
    setTags,
    buttonText,
    tagsDisabled,
    alwaysOpen,
    noDivider,
    combinedFilterValue,
    tooltipMessage,
  } = props;
  const dispatch = useDispatch();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const classes = filterCollapsableStyle();
  const toggleIsOpenFilter = () => {
    setIsOpenFilter((prevState) => !prevState);
  };

  const changeUnselectedHandler = (newTags: ObjectType[], combined: any) => {
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    const selectValue = combined?.selectValue;
    const filtersToStore = {
      filters: newTags,
      combinedFilterValue: selectValue || combined,
    };
    const filtersParsed = getUrlCombinedFilterParamsToStore(
      filtersToStore,
      filterType,
      event.DELETE,
    );
    const filterToUpdate = COMPLEX_FILTER_TYPES[filterType]
      ? filtersParsed
      : ({ [filterType]: filtersParsed });
    dispatch(updateListsByFilter(
      typeof (filterToUpdate) === 'string'
        ? {}
        : filterToUpdate,
      { [filterType]: newTags },
    ));
    setTags(newTags);
  };

  const debounceUnSelectHandler = useCallback(
    debounce(changeUnselectedHandler, 1000),
    [],
  );

  return (
    <>
      {!alwaysOpen && <Title
        filterType={filterType}
        tags={tags}
        isOpenFilter={alwaysOpen || isOpenFilter}
        toggleIsOpenFilter={toggleIsOpenFilter}
        tooltipMessage={tooltipMessage}
      />}
      <Collapse
        in={alwaysOpen || isOpenFilter}
        timeout="auto"
        unmountOnExit
      >
        {firstChild}
        {Array.isArray(tags) && <Tags
          tagsDisabled={tagsDisabled}
          tags={tags}
          debounceUnSelectHandler={debounceUnSelectHandler}
          filterType={filterType}
          buttonText={buttonText}
          combinedFilterValue={combinedFilterValue}
        />}
        {secondChild}
      </Collapse>
      {noDivider ? null : <Divider className={classes.divider} />}
    </>);
};
