export const detailBoxShowMoreStyle = {
  sx: {
    container: {
      display: 'flex',
      paddingBottom: '0.25rem',
      paddingTop: '0.25rem',
    },
    title: {
      minWidth: '20%',
      width: '20%',
    },
    descriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '80%',
      maxWidth: '80%',
    },
    showMoreContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    showMore: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#ff0100',
    },
  },
};
