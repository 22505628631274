import { v4 as uuidv4 } from 'uuid';

import {
  useState,
} from 'react';

import {
  Typography,
} from '@material-ui/core';
import {
  Grid,
  Select,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { FormControlWrapper } from '../../../Wrappers/FormControlWrapper/FormControlWrapper';

import { ObjectType } from '../../../../../../../types/objectType';

export const CustomSelect = (
  {
    selectValue,
    selectOptions,
    handleSelectChange,
    disableSelectOptionEmpty,
    disabled,
    defaultValue,
    required,
    errorMessage,
    formHelperTextEnabled,
    customStyle,
  }: ObjectType,
) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FormControlWrapper
      formHelperTextEnabled={formHelperTextEnabled && true}
      customStyle={customStyle}
      error={errorMessage}
    >
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={selectValue || 'none'}
        onChange={handleSelectChange}
        disabled={disabled || (disableSelectOptionEmpty && selectOptions.length === 0)}
        IconComponent={ExpandMore}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        defaultValue="none"
        MenuProps={{
          style: {
            maxHeight: 200,
            maxWidth: 300,
          },
        }}
      >
        {
          !isOpen && defaultValue
          && <MenuItem
            key={uuidv4()}
            value="none"
            disabled
          >
            <Grid style={{ display: 'flex' }}>
              <Typography>
                {defaultValue}
              </Typography>
              {required
                && <Typography style={{ color: 'red' }}>
                  *
                </Typography>
              }
            </Grid>
          </MenuItem>
        }
        {
          selectOptions
            .map(
              (option: ObjectType) => <MenuItem
                key={uuidv4()}
                value={option.name}
              >
                <Tooltip
                  enterDelay={1000}
                  title={
                    <Typography
                      style={{ padding: '0.625rem' }}
                    >
                      {option.name}
                    </Typography>}
                  followCursor>
                  <Typography noWrap>
                    {option.name}
                  </Typography>
                </Tooltip>

              </MenuItem>,
            )
        }
      </ Select>
    </FormControlWrapper>
  );
};
