import authHeader from '../redux/authentication/auth-header';

const photoServiceWorkers = async (id: string, listType?: string): Promise<string> => {
  const response = await fetch(
    `${process.env.REACT_APP_EMERGYA_SERVER_API_HOST}/v1/photo/${listType}/${id}`,
    {
      method: 'GET',
      body: null,
      headers: authHeader(),
    },
  );
  return response.text();
};

const photoServiceCivilWorks = async (url: string, listType?: string): Promise<string> => {
  const response = await fetch(
    `${process.env.REACT_APP_EMERGYA_SERVER_API_HOST}/v1/photo/${listType}?fotoUrl=${url}`,
    {
      method: 'GET',
      body: null,
      headers: authHeader(),
    },
  );
  return response.text();
};

export default {
  photoServiceWorkers,
  photoServiceCivilWorks,
};
