import { makeStyles } from '@material-ui/styles';
import { red } from '@mui/material/colors';

export default makeStyles(
  {
    root: {
      '&.MuiButton-contained': {
        textTransform: 'none',
        borderRadius: 50,
        '&:hover': {
          backgroundColor: '#B70000',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: red[700],
          },
        },
      },
    },
    appBar: {
      height: '7vh',
      minHeight: '7vh',
      maxHeight: '7vh',
    },
    toolbar: {
      backgroundColor: '#ff0100',
      padding: '0.5rem 1rem !important',
    },
    accionaIcon: {
      width: '4vw',
    },
    logoutBtn: {
      '& span': {
        color: '#fff',
        fontSize: '1rem',
      },
      '&:hover': {
        backgroundColor: 'inherit !important',
      },
    },
  },
);
