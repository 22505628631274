import { ObjectType } from '../types/objectType';
import {
  URL_KEY_PARAMS,
  URL_FILTERS_PARAMS_KEYS,
  COMPLEX_FILTER_TYPES,
} from '../config';
import { reverseString } from './stringHelpers';
import COMBINED_FILTERS from '../config/filtersConfig/combinedFilters';
import { CombinedFilter, FilterConfig } from '../types/filterConfig';
import { sendFilterEventAnalytics } from './analyticsHelpers';
import { event } from '../config/analyticsConfig';

/**
 * @function getBegginingDateTagParsed
 *
 * @description gets Beggining Date Tags Parsed
 *
 * @param {ObjectType} filters
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getBegginingDateTagParsed = (filters: ObjectType) => {
  let { startDate, endDate } = filters;
  startDate = startDate
    ? `Desde ${reverseString(startDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }))
    }`
    : null;
  endDate = endDate
    ? `Hasta ${reverseString(endDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }))
    }`
    : null;
  if (startDate && endDate) {
    return `${startDate} - ${endDate}`;
  }
  if (startDate) {
    return startDate;
  }
  if (endDate) {
    return endDate;
  }
  return null;
};

/**
 * @function getUrlBeginingDateFiltersToStore
 *
 * @description gets url for beginning date filter
 *
 * @param {ObjectType} filters
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getUrlBeginingDateFiltersToStore = (filters: ObjectType, filterType: string) => {
  let { startDate, endDate } = filters;
  startDate = startDate
    ? URL_FILTERS_PARAMS_KEYS[filterType].min + startDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    : null;
  endDate = endDate
    ? URL_FILTERS_PARAMS_KEYS[filterType].max + endDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    : null;

  sendFilterEventAnalytics(filterType, event.ADD);
  if (startDate && endDate) {
    return startDate + endDate;
  }
  if (startDate) {
    return startDate;
  }
  if (endDate) {
    return endDate;
  }
  return null;
};

/**
 * @function checkComplexFilter
 *
 * @description check Complex Filter
 *
 * @param {ObjectType[]} filtersArray
 *
 * @param {any} complexFilterType
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
const checkComplexFilter = (filtersArray: ObjectType[], complexFilterType: any) => {
  const filterToStore: ObjectType = {};
  const complexFilterTypeKeys = Object.keys(complexFilterType)
    .map((key: string) => complexFilterType[key].name);
  complexFilterTypeKeys.forEach((key: string) => {
    const filterByName = filtersArray.filter(
      (filter) => (
        filter.filterConfig?.filterName
        && filter.filterConfig.filterName === key
      ) && { ...filter },
    );
    filterToStore[key] = filterByName.map((filter: ObjectType, index: number) => {
      const { filterConfig } = filter;
      let { filterValue, filterQueryParams } = filterConfig;
      filterValue = Array.isArray(filterValue)
        ? filterValue
        : [];
      filterQueryParams = filterQueryParams.includes('|')
        ? filterQueryParams.split('|')
        : [];
      if (index === 0) {
        return filterQueryParams.map((f: string, i: number) => `${f}${filterValue[i] || ''}`).join('|');
      }
      const multipleQueryParam = filterQueryParams.map((f: string, i: number) => `${f}${filterValue[i] || ''}`).join('|');
      return `&${URL_KEY_PARAMS[key]}${multipleQueryParam}`;
    }).join('');
  });
  return filterToStore;
};

/**
 * @function checkComplexFilter
 *
 * @description check Complex Filter
 *
 * @param {ObjectType[]} filtersArray
 *
 * @param {any} complexFilterType
 *
 * @author Alejandro Ugarte Ruiz | alexaur97
 *
 */
const checkCombinedFilter = (combinedFilter: CombinedFilter, filterConfig: FilterConfig) => {
  const queryParams = combinedFilter.queryParams.split('|');
  return filterConfig.filters.map((filter: ObjectType) => ({
    name: `${queryParams[0]}${filter.name}|${queryParams[1]}${filterConfig.combinedFilterValue}`,
  }));
};

/**
 * @function getFiltersParsed
 *
 * @description get Autocomplete Url
 *
 * @param {any[]} filters
 *
 * @param {any} filterParam
 *
 * @author Alejandro Ugarte | alexaur97
 *
 */
const getFiltersParsed = (filters: any[], filterParam: any) => {
  const filtersParsed = filters.map((filter: ObjectType, index: number) => {
    let paramToEncode = filter.selectValue || filter.name;
    paramToEncode = encodeURIComponent(paramToEncode);
    if (index === 0) {
      return paramToEncode;
    }
    return `&${filterParam + paramToEncode}`;
  }).join('');
  return filtersParsed;
};

/**
 * @function getUrlFilterParamsToStore
 *
 * @description get Autocomplete Url
 *
 * @param {ObjectType} filtersArray
 *
 * @param {string} filterType
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getUrlFilterParamsToStore = (
  filtersArray: ObjectType[],
  filterType: string,
  eventLabel: string,
) => {
  const eventValue = filtersArray.map((filter) => filter.name).join(', ');
  if (filtersArray?.length > 0 || eventLabel !== event.ADD) {
    sendFilterEventAnalytics(filterType, eventLabel, eventValue);
  }

  return COMPLEX_FILTER_TYPES[filterType]
    ? checkComplexFilter(filtersArray, COMPLEX_FILTER_TYPES[filterType])
    : getFiltersParsed(filtersArray, URL_KEY_PARAMS[filterType]);
};

/**
 * @function getUrlCombinedFilterParamsToStore
 *
 * @description get combined filters params Url
 *
 * @param {FilterConfig} filterConfig
 *
 * @param {string} filterType
 *
 * @author Alejandro Ugarte Ruiz | alexaur97
 *
 */
export const getUrlCombinedFilterParamsToStore = (
  filterConfig: FilterConfig,
  filterType: string,
  eventLabel: string,
) => {
  const eventValue = filterConfig.filters.map((filter) => filter.name).join(', ');
  if (filterConfig?.filters?.length > 0
    || eventLabel !== event.ADD) sendFilterEventAnalytics(filterType, eventLabel, eventValue);
  const combinedFilter = COMBINED_FILTERS[filterType];
  let filtersToStore;
  if (combinedFilter && filterConfig.combinedFilterValue) {
    filtersToStore = checkCombinedFilter(combinedFilter, filterConfig);
  } else {
    filtersToStore = filterConfig.filters;
  }
  return getFiltersParsed(filtersToStore, URL_KEY_PARAMS[filterType]);
};
