import { ModalHeader } from '../../ModalComponent/ModalHeader/ModalHeader';
import { HeaderInfo } from '../../ModalComponent/ModalHeader/HeaderInfo/HeaderInfo';
import { ModalBody } from '../../ModalComponent/ModalBody/ModalBody';

import { CivilWorksDetails } from './CivilWorksDetails/CivilWorksDetails';
import { CivilWorksBasicEnumeration } from './CivilWorksBasicEnumeration/CivilWorkBasicEnumeation';
import { CivilWorksGroups } from './CivilWorksGroups/CivilWorksGroups';

import { DETAILED_INFO_MODAL_VARIABLES } from '../../../../../../../../config';

import { ObjectType } from '../../../../../../../../types/objectType';
import { CivilWorksCertificates } from './CivilWorksCertificates/CivilWorksCertificates';

export const CivilWorksDetailedInfo = (
  {
    listType,
    modalDetailedInfo,
    handleClose,
  }: ObjectType,
) => (<>
  <ModalHeader
    photo={true}
  >
    <HeaderInfo
      listType={listType}
      modalDetailedInfo={modalDetailedInfo}
      handleClose={handleClose}
    />
  </ModalHeader>
  <ModalBody>
    <CivilWorksDetails
      modalDetailedInfo={modalDetailedInfo}
    />
    <CivilWorksGroups
      modalDetailedInfo={modalDetailedInfo}
      groupType={DETAILED_INFO_MODAL_VARIABLES.classification.title}
    />
    <CivilWorksCertificates
      modalDetailedInfo={modalDetailedInfo}
    />
    <CivilWorksGroups
      modalDetailedInfo={modalDetailedInfo}
      groupType={DETAILED_INFO_MODAL_VARIABLES.types.title}
    />
    <CivilWorksBasicEnumeration
      modalDetailedInfo={modalDetailedInfo}
      enumerationType={DETAILED_INFO_MODAL_VARIABLES.process}
      descriptionWithVolumen={false}
    />
    <CivilWorksBasicEnumeration
      modalDetailedInfo={modalDetailedInfo}
      enumerationType={DETAILED_INFO_MODAL_VARIABLES.units}
      descriptionWithVolumen={true}
    />
    <CivilWorksBasicEnumeration
      modalDetailedInfo={modalDetailedInfo}
      enumerationType={DETAILED_INFO_MODAL_VARIABLES.magnitudes}
      descriptionWithVolumen={true}
    />
  </ ModalBody>
</>);
