import TextField from '@mui/material/TextField';
import { ObjectType } from '../../../../../../../types/objectType';

import customTextFieldStyle from './CustomTextFieldStyle';

export const CustomTextField = (
  {
    params,
    handleChange,
    handleKeyPress,
    errorMessage,
    modalOpen,
  }: ObjectType,
) => {
  const classes = customTextFieldStyle();
  return (
    <TextField
      {...params}
      onChange={handleChange}
      style={{ width: '49%' }}
      onKeyPress={handleKeyPress}
      error={!!errorMessage}
      helperText={errorMessage}
      className={
        modalOpen
          ? classes.root
          : classes.noStyle
      }
      autoComplete='off'
    />
  );
};
