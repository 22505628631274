import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import PaymentsIcon from '@mui/icons-material/Payments';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ConstructionIcon from '@mui/icons-material/Construction';
import HandymanIcon from '@mui/icons-material/Handyman';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RoomIcon from '@mui/icons-material/Room';

import { FILTER_TYPES } from '../../../../../../../config';
import { StringsObjectType } from '../../../../../../../types/stringObjectType';

import filterWrapperTitleIconStyle from './filterWrapperTitleIconStyle';

export const FilterWrapperTitleIcon = ({ filterType }: StringsObjectType) => {
  const classes = filterWrapperTitleIconStyle();
  return (<>
    {
      (filterType === FILTER_TYPES.workersFullName)
      && <PersonIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.workersDiploma
        || filterType === FILTER_TYPES.workersDegreeYear
      )
      && <SchoolIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.workersCertificate)
      && <WorkIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.workersCostCenter)
      && <PaymentsIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.workersSupervisory)
      && <GroupIcon className={classes.titleIcon} />
    }
    {
      (
        filterType === FILTER_TYPES.workersPositions
        || filterType === FILTER_TYPES.workersCurrentPosition
        || filterType === FILTER_TYPES.workersExperience
      )
      && <WorkIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.workersExperienceDescription)
      && <DescriptionIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.workersZone)
      && <RoomIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksBeginningDate)
      && <TodayIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksEndDate)
      && <EventIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksByName)
      && <CorporateFareIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksClient)
      && <BusinessCenterIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksType)
      && <ConstructionIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksSubtype)
      && <HandymanIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksMinimunAmount)
      && <MonetizationOnIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksClassification)
      && <AssignmentIcon className={classes.titleIcon} />
    }
    {
      (filterType === FILTER_TYPES.civilWorksZone)
      && <RoomIcon className={classes.titleIcon} />
    }
  </>);
};
