import {
  Box,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { DetailedInfoContainer } from '../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyInfo } from '../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';
import { ModalBodyTitle } from '../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { DetailBox } from '../../../ModalComponent/ModalBody/DetailBoxes/DetailBox/DetailBox';
import { DetailBoxCustomizable } from '../../../ModalComponent/ModalBody/DetailBoxCustomizable/DetailBoxCustomizable';
import { DetailBoxShowMore } from '../../../ModalComponent/ModalBody/DetailBoxShowMore/DetailBoxShowMore';

import { getFormattedDate } from '../../../../../../../../../helpers/stringHelpers';
import { namespaces } from '../../../../../../../../../i18n/i18n.constants';
import { DETAILED_INFO_MODAL_VARIABLES } from '../../../../../../../../../config/index';
import { ObjectType } from '../../../../../../../../../types/objectType';

import { civilWorksDetailedInfoStyle } from './civilWorksDetailsStyle';

export const CivilWorksDetails = (
  {
    modalDetailedInfo,
  }: ObjectType,
) => {
  const civilWorksDetails = DETAILED_INFO_MODAL_VARIABLES.civilWorksDetails.bbddKey;
  const {
    startDate,
    endDate,
    address,
    addressCountry,
    addressProvince,
    addressStreetType,
    adressStreetName,
    adressStreetNumber,
    zone,
    zoneCode,
    zoneCenter,
    description,
  } = civilWorksDetails;
  const { sx } = civilWorksDetailedInfoStyle;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const TRADUCTION_BASE_PATH = 'detailedInfoModal.civilWorksDetailedInfo.civilWorksDetails.';

  const getAdressDetail = (
    streetType: string,
    streetName: string,
    streetNumber: string,
  ) => {
    if (streetName && streetNumber) {
      return `${streetType} ${streetName}, ${streetNumber}`;
    }
    if (streetName) {
      return `${streetType} ${streetName}`;
    }
    return null;
  };

  const getCompleteParsedString = (stringArray: string[]) => {
    const parsedStringArray = stringArray.map((string, index) => {
      if (index > 0) {
        return ` ${string}`;
      }
      return string;
    });
    return parsedStringArray.join(' ');
  };

  return (<DetailedInfoContainer>
    <ModalBodyTitle value={t(`${TRADUCTION_BASE_PATH}title`)} />
    <ModalBodyInfo >
      <Box sx={sx.date} >
        <DetailBoxCustomizable
          title={t(`${TRADUCTION_BASE_PATH}startDate`)}
          description={getFormattedDate(modalDetailedInfo[startDate])}
          customStyle={sx.basicDateContainer}
        />
        <DetailBoxCustomizable
          title={t(`${TRADUCTION_BASE_PATH}endDate`)}
          description={getFormattedDate(modalDetailedInfo[endDate])}
          customStyle={sx.basicDateContainer}
        />
      </ Box>
      <Box sx={sx.adresColumn1}>
        <DetailBox
          title={t(`${TRADUCTION_BASE_PATH}country`)}
          value={modalDetailedInfo[address][addressCountry]}
        />
        <DetailBox
          title={t(`${TRADUCTION_BASE_PATH}zone`)}
          value={
            getCompleteParsedString(
              [
                modalDetailedInfo[zone][zoneCenter],
                modalDetailedInfo[zone][zoneCode],
              ],
            )
          }
        />
        <DetailBox
          title={t(`${TRADUCTION_BASE_PATH}province`)}
          value={modalDetailedInfo[address][addressProvince]}
        />
      </ Box>
      <Box sx={sx.adressColumn2} >
        <DetailBox
          title={t(`${TRADUCTION_BASE_PATH}city`)}
          value={modalDetailedInfo[address][addressProvince]}
        />
        <DetailBox
          title={t(`${TRADUCTION_BASE_PATH}adress`)}
          value={
            getAdressDetail(
              modalDetailedInfo[address][addressStreetType],
              modalDetailedInfo[address][adressStreetName],
              modalDetailedInfo[address][adressStreetNumber],
            )
          }
        />
      </Box>
      <Box sx={sx.description}>
        <DetailBoxShowMore
          title={t(`${TRADUCTION_BASE_PATH}description`)}
          value={modalDetailedInfo[description]}
          maxLengthTextShowed={140}
        />
      </Box>
    </ModalBodyInfo>
  </DetailedInfoContainer >);
};
