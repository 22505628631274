import { INIT_FILTERS } from '../config';
import INIT_TAGS from '../config/initTags';
import { ObjectType } from '../types/objectType';

/**
 * @function getListsPayloadHelper
 *
 * @description gets state civilWorkWorkerList object with initial info from api
 *
 * @param {ObjectType} initialList - list object from reduxt civilWorkWorkerlist state
 * @param {ObjectType} data - data from api response
 *
 * @author Manu Fernandez | mfernandezfreire
 */
export const getListsPayloadHelper = (
  initialList: ObjectType,
  data: ObjectType,
  updatedFilters?: ObjectType,
  tagsToUpdate?: ObjectType,
) => {
  const { personas, obras } = data.result;
  let {
    civilWorks,
    workers,
    filters,
    tags,
  } = initialList;
  civilWorks = { ...civilWorks, ...obras };
  workers = { ...workers, ...personas };
  filters = { ...filters, ...updatedFilters };
  tags = { ...tags, ...tagsToUpdate };
  return {
    ...initialList,
    isLoading: false,
    civilWorks,
    workers,
    filters,
    tags,
  };
};

/**
 * @function updatePageListingHelper
 *
 * @description updates civilWorkWoker filter page by pageType
 *
 * @param {ObjectType} initialList - initial list from redux storage
 * @param {string} pageType - pageType to update
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const updatePageListingHelper = (initialList: ObjectType, pageType: string) => {
  const { filters: finalFilters } = initialList;
  let page;
  if (pageType === 'civilWorks') {
    page = finalFilters.pageCivilWorks + 1;
    finalFilters.pageCivilWorks = page;
    return { ...finalFilters };
  }
  page = finalFilters.pageWorkers + 1;
  finalFilters.pageWorkers = page;
  return { ...finalFilters };
};

/**
 * @function getUpdatedListPayloadHelper
 *
 * @description gets state civilWorkWorkerList object with updated info by listToUpdate
 *
 * @param {ObjectType} initialList - initial list data from redux storage
 * @param {ObjectType} data - data from api response
 * @param {string} listToUpdate - list to update
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getUpdatedListPayloadHelper = (
  initialList: ObjectType,
  data: ObjectType,
  listToUpdate: string,
) => {
  const { personas, obras } = data.result;
  let { civilWorks, workers } = initialList;
  if (listToUpdate === 'civilWorks') {
    civilWorks = {
      ...civilWorks,
      ...obras,
      isLoading: false,
      results: [
        ...civilWorks.results,
        ...obras.results,
      ],
    };
    return { ...initialList, civilWorks };
  }
  workers = {
    ...workers,
    ...personas,
    isLoading: false,
    results:
      [
        ...workers.results,
        ...personas.results,
      ],
  };
  return { ...initialList, workers };
};

/**
 * @function saveLocalStoragePayloadHelper
 *
 * @description saves civilWorkWorkerList info to Localstorage
 *
 * @param {ObjectType} listsPayload - civilWorkWorkerList object from redux storage
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const saveLocalStoragePayloadHelper = (listsPayload: ObjectType) => {
  const { civilWorks, workers, filters } = listsPayload;
  return {
    civilWorks: {
      results: civilWorks.results,
      hasMore: civilWorks.hasMore,
    },
    workers: {
      results: workers.results,
      hasMore: workers.hasMore,
    },
    filters,
  };
};

/**
 * @function getLocalStoragePayloadHelper
 *
 * @description get redux civilWorkWorkerList state info from Localstorage
 *
 * @param {ObjectType} listsPayload - initial civilWorkWorkerList from redux storage
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getLocalStoragePayloadHelper = (
  defaultObject: ObjectType,
  payloadFromMemory: ObjectType,
) => {
  const { civilWorks, workers, filters } = payloadFromMemory;
  return {
    ...defaultObject,
    isLoading: false,
    civilWorks: {
      ...defaultObject.civilWorks,
      moreResults: civilWorks.moreResults,
      results: civilWorks.results,
    },
    workers: {
      ...defaultObject.workers,
      moreResults: workers.moreResults,
      results: workers.results,
    },
    filters: {
      ...defaultObject.filters,
      filters,
    },
  };
};

/**
 * @function areFilterAndTagsEquals
 *
 * @description checks if filters and tags are equals
 *
 * @param {ObjectType} localStorageObject
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const areFilterAndTagsEquals = (
  localStorageObject: ObjectType,
): boolean => {
  const { tags, filters } = localStorageObject;

  // Take All active tags keys
  const tagsKeys = tags ? Object.keys(tags) : [];
  if (!tagsKeys.includes(INIT_TAGS.workersActive)) return false;
  const activeTags = tagsKeys
    .filter(
      (key) => (
        key !== 'workersActive'
        && Array.isArray(tags[key])
        && tags[key].length > 0
      ),
    );

  // Take All active filter keys
  const defaultFilters = Object.keys(INIT_FILTERS);
  const allFiltersApplied = filters ? Object.keys(filters) : [];
  const filtersActive = allFiltersApplied
    .filter(
      (f) => !defaultFilters.includes(f) && filters[f] && filters[f].length > 0,
    );

  // Compare both
  return filtersActive.length === activeTags.length;
};
