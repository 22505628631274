import { makeStyles } from '@material-ui/styles';

const common = {
  padding: '5px 20px 5px 20px',
  marginBottom: '20px',
};

export default makeStyles({
  tabsContainer: {
    display: 'flex',
    flexflow: 'row',
    marginTop: '20px',
  },
  tab: {
    ...common,
    cursor: 'pointer',
  },
  selected: {
    ...common,
    borderBottom: '2px solid #FF0000',
    fontWeight: 700,
  },
});
