import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import debounce from 'lodash.debounce';

import {
  FormGroup,
  Box,
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { useSelector, useDispatch } from 'react-redux';
import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';
import {
  getUrlBeginingDateFiltersToStore,
  getBegginingDateTagParsed,
} from '../../../../../../helpers/urlHelpers';
import {
  DATE_PICKER_RANGE_TYPE,
  LIST_TYPES,
} from '../../../../../../config';
import { ObjectType } from '../../../../../../types/objectType';
import { StringsObjectType } from '../../../../../../types/stringObjectType';

import { FilterCollapsableWrapper } from '../../Wrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { CustomDatePicker } from './CustomDatePicker/CustomDatePicker';

export const DatePickerRange = ({ filterType }: StringsObjectType) => {
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const dispatch = useDispatch();
  const [tags, setTags] = useState<Array<ObjectType>>([]);

  useEffect(() => {
    if (
      Array.isArray(initialTags[filterType])
    ) {
      setTags(initialTags[filterType]);
    }
  }, [initialTags]);

  const handleChangeDate = (
    newDate: Date | null,
    datePickerRangeType: string,
    initialValue: ObjectType,
  ) => {
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    const newDateObject = {
      ...initialValue,
      [datePickerRangeType]: newDate,
    };
    const newTag = { ...newDateObject, name: getBegginingDateTagParsed(newDateObject) };
    const filtersParsed = getUrlBeginingDateFiltersToStore(newDateObject, filterType);
    const filterToUpdate = {
      [filterType]: filtersParsed,
    };
    dispatch(
      updateListsByFilter(
        filterToUpdate,
        { [filterType]: [newTag] },
      ),
    );
  };

  const debounceChangeDate = useCallback(
    debounce(handleChangeDate, 100),
    [],
  );

  const debounceText = useCallback(
    debounce((e, textFieldValue, validInput, initialValue, datePickerRangeType) => {
      if (e.key === 'Enter' && validInput) {
        const validDate = textFieldValue.split('/').reverse();
        handleChangeDate(new Date(validDate), datePickerRangeType, initialValue);
      }
    }, 100),
    [],
  );

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        tags={tags}
        setTags={setTags}
      >
        <FormGroup
          row
          style={
            {
              padding: '0.7rem',
            }
          }
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CustomDatePicker
              debounceChangeDate={debounceChangeDate}
              datePickerRangeType={DATE_PICKER_RANGE_TYPE.startDate}
              debounceText={debounceText}
              value={tags[0]}
            />
            <Box style={{ width: '2%' }}></Box>
            <CustomDatePicker
              debounceChangeDate={debounceChangeDate}
              datePickerRangeType={DATE_PICKER_RANGE_TYPE.endDate}
              debounceText={debounceText}
              value={tags[0]}
            />
          </ LocalizationProvider>
        </FormGroup>
      </FilterCollapsableWrapper>
    </>
  );
};
