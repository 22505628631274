import { makeStyles } from '@material-ui/core';

const font = {
  color: '#666666',
  fontfamily: 'Roboto',
};

export default makeStyles({
  container: {
    width: '100%',
    marginLeft: '10%',
  },
  title: {
    ...font,
    fontSize: '14px',
  },
  textArea: {
    width: '100%',
    border: '1px solid #D0D0D0',
    margin: '5px 0',
  },
  maxLength: {
    ...font,
    fontSize: '12px',
  },
  span: {
    color: 'red',
  },
});
