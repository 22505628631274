import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import { ObjectType } from '../../../../../../../../../../types/objectType';

import { DetailedInfoContainer } from '../../../../ModalComponent/ModalBody/DetailedInfoContainer/DetailedInfoContainer';
import { ModalBodyTitle } from '../../../../ModalComponent/ModalBody/ModalBodyTitle/ModalBodyTitle';
import { ModalBodyInfo } from '../../../../ModalComponent/ModalBody/ModalBodyInfo/ModalBodyInfo';
import { LanguageDescriptionLevel } from './LanguageDescriptionLevel/LanguageDescriptionLevel';
import { NoInfoBox } from '../../../../ModalComponent/ModalBody/NoInfoBox/NoInfoBox';

import { namespaces } from '../../../../../../../../../../i18n/i18n.constants';

export const LanguagesComponent = (
  {
    modalDetailedInfo,
    type,
  }: ObjectType,
) => {
  const { translation, bbddKey } = type;
  const { language, level } = bbddKey;
  const languagesInfo = modalDetailedInfo[bbddKey.main];
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const TRADUCTION_BASE_PATH = `detailedInfoModal.workerDetailedInfo.${translation}.`;

  return (
    <DetailedInfoContainer>
      <ModalBodyTitle value={t(`${TRADUCTION_BASE_PATH}title`)} />
      <ModalBodyInfo>
        {
          (
            (languagesInfo.length > 0)
              ? languagesInfo.map((languageInfo: ObjectType) => (
                <LanguageDescriptionLevel
                  key={uuidv4()}
                  languageInfo={languageInfo}
                  translation={translation}
                  language={language}
                  level={level}
                />
              ))
              : <NoInfoBox
                value={t(`${TRADUCTION_BASE_PATH}noInfoText.main`)}
              />

          )
        }
      </ModalBodyInfo>
    </ DetailedInfoContainer>
  );
};
