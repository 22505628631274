import { TFunction } from 'react-i18next';
import { ObjectType } from '../types/objectType';

/**
 * @function truncateString
 *
 * @description returns a truncate dotted string i case exceed maxLength
 *
 * @param {string} stringToTruncate
 * @param {number} maxLength
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const truncateString = (stringToTruncate: string, maxLength: number) => {
  let nameFromString = stringToTruncate;
  if (
    stringToTruncate
    && typeof nameFromString === 'string'
  ) {
    // eslint-disable-next-line prefer-destructuring
    nameFromString = stringToTruncate.includes('-')
      ? stringToTruncate.split('-')[1]
      : stringToTruncate;
    nameFromString = nameFromString.length > maxLength
      ? nameFromString.slice(0, maxLength - 5).concat('...')
      : nameFromString;
  }
  return nameFromString;
};

/**
 * @function reverseString
 *
 * @description reverse an string
 *
 * @param {string} stringToReverse
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const reverseString = (stringToReverse: string, separatorType = '-') => {
  if (stringToReverse
    && typeof stringToReverse === 'string'
  ) {
    return stringToReverse.split('-').reverse().join(separatorType);
  }
  return null;
};

/**
 * @function getSanitizedDateFromString
 *
 * @description gets a dd/mm/yyyy format from Datapicker Textfield
 *
 * @param {string} stringDate
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getSanitizedDateFromString = (stringDate: string) => {
  let baseString = stringDate;
  if (stringDate.length > 10) {
    return baseString.slice(0, -1);
  }
  if (stringDate.length >= 9) {
    baseString = stringDate.trim().split('/').join('');
    const sanitizedDate = baseString
      .split('')
      .map((digit, index) => {
        if (index === 1 || index === 3) return `${digit}/`;
        return digit;
      })
      .join('');
    return sanitizedDate;
  }
  return baseString;
};

/**
 * @function getNumbersFromString
 *
 * @description gets Number from stringValue
 *
 * @param {string} stringValue
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getNumbersFromString = (stringValue: string) => stringValue.replace(/[^\d]/g, '');

/**
 * @function getEuropeanNumberFormat
 *
 * @description gets European Number Format from TextField String
 *
 * @param {string} textFieldString
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getEuropeanNumberFormat = (textFieldString: string | number, currency = '€') => {
  if (textFieldString === 0) return `${textFieldString} ${currency}`;
  if (textFieldString) {
    const sanitizedString = textFieldString.toString().replace(/[^\d]/g, '');
    return `${sanitizedString.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ${currency}`;
  }
  return null;
};

/**
 * @function getFormattedDate
 *
 * @description gets Formatted date according UX visual
 *
 * @param {string} textFieldString
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getFormattedDate = (date: string) => {
  if (!date) return null;
  return reverseString(date, ' / ');
};

/**
 * @function formatDateFromTimestamp
 *
 * @description gets Formatted date from a timestamp
 *
 * @param {number} timestamp
 *
 * @author Alejandro Ugarte Ruiz | alexaur97
 *
 */
export const formatDateFromTimestamp = (timestamp: number | string) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} - ${hours}:${minutes} h`;
};

/**
 * @function capitalizeString
 *
 * @description capitalizes string
 *
 * @param {string} stringToCapitalize
 *
 * @param {string[]}  wordExceptions
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const capitalizeString = (
  stringToCapitalize: string,
  wordExceptions: string[] = [],
  wordExceptionsUppercase: string[] = [],
): string => stringToCapitalize
  .toLocaleLowerCase()
  .split(' ')
  .map((word) => {
    if (wordExceptions.includes(word)) {
      return word;
    }
    if (wordExceptionsUppercase.includes(word)) {
      return word.toLocaleUpperCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');

/**
 * @function getTranslatedOptions
 *
 * @description translates options
 *
 * @param {string[]} optionToTranslate
 * @param {string} filterType
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getTranslatedOptions = (
  optionToTranslate: Array<ObjectType>,
  t: TFunction<string, undefined>,
  filterType: string,
) => optionToTranslate.map((selectOption: ObjectType) => {
  const name = selectOption.translateValue
    ? t(`select.${filterType}.${selectOption.translateValue}`)
    : selectOption.name;
  const value = selectOption.value || name;
  const { color } = selectOption;
  return {
    ...selectOption, name, value, color,
  };
});
