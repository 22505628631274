import {
  Grid,
} from '@mui/material';

import { ObjectType } from '../../../../../../../../../types/objectType';

const style = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0.25rem',
  paddingLeft: '12px',
  paddingRight: '24px',
  marginBottom: '1.5rem',
  // border: '1px solid black',
};

export const ModalBodyInfo = ({ children }: ObjectType) => (
  <Grid
    container
    sx={style}
  >
    {children}
  </ Grid>
);
