import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../../../../../../../i18n/i18n.constants';

interface ModalBodyTitleProps {
  value: string;
}

export const ModalBodyTitle = (props: ModalBodyTitleProps) => {
  const { value } = props;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const getMarginTop = (val: string) => {
    if ([
      t('detailedInfoModal.workerDetailedInfo.statusChange.title'),
      t('detailedInfoModal.workerDetailedInfo.personalDetails.title'),
      t('detailedInfoModal.workerDetailedInfo.workExperience.title'),
      t('detailedInfoModal.workerDetailedInfo.certificates.title'),
    ].includes(val)) return '5px';
    if (val === t('detailedInfoModal.workerDetailedInfo.education.title')) return '90px';
    return '20px';
  };

  const getMarginBottom = (val: string) => {
    if ([
      t('detailedInfoModal.workerDetailedInfo.statusChange.title'),
      t('detailedInfoModal.workerDetailedInfo.changeHistory.title'),
    ].includes(val)) return '15px';
    return '5px';
  };

  return (<Typography
    variant="h6"
    mt={1}
    mb={2}
    style={
      {
        width: '100%',
        fontWeight: 600,
        marginTop: getMarginTop(value),
        marginBottom: getMarginBottom(value),
      }
    }
  >
    {value}
  </Typography>);
};
