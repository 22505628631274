export const civilWorksCertificatesStyle = {
  sx: {
    detailContainer: {
      display: 'flex',
      width: '100%',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
    detailContainerLastItem: {
      display: 'flex',
      width: '100%',
      marginTop: '0.5rem',
    },
    code: {
      width: '5%',
      padding: 0,
    },
    type: {
      width: '15%',
      padding: 0,
    },
    description: {
      width: '20%',
      padding: 0,
    },
    language: {
      width: '30%',
      padding: 0,
    },
  },
};
