import React,
{
  useState,
  KeyboardEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { ObjectType } from '../../../../../../../types/objectType';
import {
  DATE_PICKER_RANGE_TYPE,
  REGEX,
} from '../../../../../../../config';
import { getSanitizedDateFromString } from '../../../../../../../helpers/stringHelpers';

import { CustomTextField } from '../CustomTextField/CustomTextField';

import { namespaces } from '../../../../../../../i18n/i18n.constants';

export const CustomDatePicker = (
  {
    value,
    debounceChangeDate,
    datePickerRangeType,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const [debouncedDate, setDebouncedDate] = useState<Date | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [textFieldValue, setTextFieldValue] = useState('');
  const [validInput, setValidInput] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOnAccept = (date: Date | null) => {
    const newDate = date && date.getTime();
    const debounced = debouncedDate && debouncedDate.getTime();
    if (newDate !== debounced) {
      setDebouncedDate(date);
      debounceChangeDate(
        date,
        datePickerRangeType,
        value,
      );
    }
  };

  const validateDate = () => {
    const validDate: number[] = textFieldValue.split('/').reverse().map((number) => Number(number));
    const dateToValidate = new Date(validDate[0], validDate[1] - 1, validDate[2]);
    if (!value) {
      return debounceChangeDate();
    }
    if (
      (
        datePickerRangeType === DATE_PICKER_RANGE_TYPE.startDate
        && value.endDate
        && (dateToValidate.getTime() > value.endDate.getTime())
      )
      || (
        datePickerRangeType === DATE_PICKER_RANGE_TYPE.endDate
        && value.startDate
        && (dateToValidate.getTime() < value.startDate.getTime())
      )
    ) {
      return setErrorMessage(t('input.invalidDate'));
    }
    return debounceChangeDate(dateToValidate, datePickerRangeType, value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter' && validInput) {
      return validateDate();
    }
    return null;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedTextFromEvent = getSanitizedDateFromString(e.target.value);
    const validDateRegex = new RegExp(REGEX.date);
    setValidInput(false);
    if (parsedTextFromEvent.length === 0) {
      debounceChangeDate(
        null,
        datePickerRangeType,
        value,
      );
    }
    if (parsedTextFromEvent.length < 10) {
      return setErrorMessage('');
    }
    if (
      parsedTextFromEvent.length === 10
      && !validDateRegex.test(parsedTextFromEvent)
    ) {
      return setErrorMessage(t('input.errorMessage'));
    }
    setTextFieldValue(parsedTextFromEvent);
    setValidInput(true);
    setErrorMessage('');
    return null;
  };

  const getMinDate = () => {
    if (
      datePickerRangeType === 'endDate'
      && value
      && value.startDate
    ) {
      return value.startDate;
    }
    return null;
  };

  const minDate = getMinDate() || undefined;

  const getMaxDate = () => {
    if (
      datePickerRangeType === 'startDate'
      && value
      && value.endDate
    ) {
      return value.endDate;
    }
    return null;
  };

  const maxDate = getMaxDate() || undefined;

  return (<>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        openTo='year'
        inputFormat='dd/MM/yyyy'
        views={['year', 'month', 'day']}
        allowSameDateSelection={true}
        onAccept={handleOnAccept}
        value={(value && value[datePickerRangeType]
          ? value[datePickerRangeType]
          : null
        )}
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        onChange={() => null}
        minDate={minDate}
        maxDate={maxDate}
        PopperProps={{
          placement: 'bottom',
        }}
        renderInput={(params) => (
          <CustomTextField
            params={params}
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            errorMessage={errorMessage}
            modalOpen={modalOpen}
          />
        )
        }
      />
    </LocalizationProvider>
  </>);
};
